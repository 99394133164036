import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} Flag
 * @property {string} contentType     // like "image/png"
 * @property {{type: "Buffer", data: number[]}} data   // image data buffer
 */

/**
 * @typedef {object} Language
 * @property {string} id            // DB id
 * @property {string} language      // display name like "English"
 * @property {string} lang          // localization id, like "en"
 * @property {Flag} flag            // flag's image
 * @property {Record<string, string>} dictionary    // real key-value table
 */

/**
 * @typedef {object} LanguageData
 * @property {string} language
 * @property {string} lang
 * @property {Blob} imgFlagData
 */

/**
 * @typedef {LanguageData & {userId: string}} LanguageDataForAdd
 */

/**
 * @typedef {LanguageData & {id: string; dictionary: Record<string, string>}} LanguageDataForEdit
 */

/**
 * Query for the Language list.
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Language[]>}
 */
export function useLanguages(enabled = true) {
    return useQuery({
        queryKey: ["admin", "Language"],
        queryFn: getLanguages,
        enabled,
    });
}

/**
 * Query for a Language.
 * @param {string} languageId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Language>}
 */
export function useLanguage(languageId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "Language", languageId],
        queryFn: () => getLanguage(languageId),
        enabled: enabled && !!languageId,
    });
}

/**
 * Delete a Language
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, string, unknown>}
 */
export function useLanguageDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteLanguage,
        onSuccess: (_data, languageId) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "Language"], (oldLanguages) => {
                if (!oldLanguages) return oldLanguages;

                return oldLanguages.filter((language) => language.id !== languageId);
            });
        },
        meta: { entity: "Language", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Add a Language
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, LanguageDataForAdd, unknown>}
 */
export function useLanguageAdd() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addLanguage,
        onSuccess: (language) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "Language"], (oldLanguages) => {
                if (!oldLanguages) oldLanguages = [];
                return [...oldLanguages, language];
            });
        },
        meta: { entity: "Language", action: "create" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a Language
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, LanguageDataForEdit, unknown>}
 */
export function useLanguageEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editLanguage,
        onSuccess: (language) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "Language"], (oldLanguages) => {
                if (!oldLanguages) return oldLanguages;

                let found = false;
                const newLanguages = oldLanguages.map((oldLanguage) => {
                    if (oldLanguage.id === oldLanguages.id) {
                        found = true;
                        // merge - let the new overwrite the existing
                        return { ...oldLanguage, ...language };
                    }
                    return oldLanguage;
                });
                // just in case a Language that is not present is edited then add it
                if (!found) newLanguages.push(language);
                return newLanguages;
            });
        },
        meta: { entity: "Language", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get Languages function to be used by useQuery
 * @return {Promise<Language[]>}
 */
const getLanguages = async () => {
    const res = [];

    const { data: languagesData } = await adminApi.get(`/languages`);

    for (const item of languagesData) {
        res.push(fixId(item));
    }

    return res;
};

/**
 * Get Language function to be used by useQuery
 * @param {string} languageId
 * @return {Promise<Language>}
 */
const getLanguage = async (languageId) => {
    const { data: languageData } = await adminApi.get(`/languages/${languageId}`);

    return fixId(languageData);
};

/**
 * Delete a Language
 * @param {string} languageId
 */
const deleteLanguage = async (languageId) => {
    await adminApi.delete(`/languages/${languageId}`);
};

/**
 * Add/Create a Language function to be used by useQuery
 * @param {LanguageDataForAdd} inLanguageData
 */
const addLanguage = async (inLanguageData) => {
    const languageFormData = new FormData();
    languageFormData.append("language", inLanguageData.language);
    languageFormData.append("lang", inLanguageData.lang);
    if (inLanguageData.imgFlagData) languageFormData.append("flag", inLanguageData.imgFlagData);
    languageFormData.append("userId", inLanguageData.userId);

    const { data: language } = await adminApi.post("/languages", languageFormData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return language;
};

/**
 * Edit a Language function to be used by useQuery
 * @param {LanguageDataForEdit} inLanguageData
 */
const editLanguage = async (inLanguageData) => {
    const languageFormData = new FormData();
    languageFormData.append("language", inLanguageData.language);
    languageFormData.append("lang", inLanguageData.lang);
    if (inLanguageData.imgFlagData) languageFormData.append("flag", inLanguageData.imgFlagData);
    languageFormData.append("dictionary", JSON.stringify(inLanguageData.dictionary));

    const { data: language } = await adminApi.put(
        `/languages/${inLanguageData.id}`,
        languageFormData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        },
    );

    return language;
};

import { isString } from "@/utils/utils";

export default function CustomButton(props) {
    const {
        isSubmit = false,
        isSecondary = false,
        isFull = false,
        onClick,
        disabled,
        label,
        img,
    } = props;

    return (
        <button
            type={isSubmit ? "submit" : "button"}
            className={`playerButton ${disabled ? "inactive" : "active"} ${
                isSecondary ? "secondary" : ""
            } ${isFull ? "full" : ""}`}
            disabled={disabled ? "disabled" : undefined}
            onClick={onClick}
        >
            {img && (isString(img) ? <img src={img} alt={label} /> : img)}

            <span className="label">{label}</span>
        </button>
    );
}

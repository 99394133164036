import { useToggle, useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";

import { setImageToken } from "@/utils/utils";

import LayoutExt from "@/layouts/player/components/LayoutExt";
import BuilderUploadPhoto from "@/layouts/player/components/BuilderUploadPhoto";
import PhotoSubmitted from "@/layouts/player/components/PhotoSubmitted";

export default function BuilderUploadPhotoExt() {
    const [submitted, setSubmitted] = useToggle(false);

    let { data } = useParams();
    data = JSON.parse(atob(data));
    const { imageToken, ...builderUploadPhotoProps } = data;

    // store so the API call to be authorized
    useEffectOnce(() => setImageToken(imageToken));

    const uploadPhotoProps = {
        ...builderUploadPhotoProps,

        onSubmitted: setSubmitted,
    };

    return (
        <LayoutExt
            subtitle={builderUploadPhotoProps}
            className="bonus-task-action"
            titleKey="submit_photo_title"
        >
            {submitted ? <PhotoSubmitted /> : <BuilderUploadPhoto {...uploadPhotoProps} />}
        </LayoutExt>
    );
}

import { useTranslation } from "react-i18next";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import take_photo from "@/assets/img/player/take_photo.svg";

import { page_player_builder_upload_photo } from "@/utils/constants";

import CustomButton from "@/layouts/player/components/Button/CustomButton";
import { useNavigate } from "@/layouts/player/realtime";

export default function Builder2() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const submitHandler = () => {
        // go to the next page
        navigate(page_player_builder_upload_photo);
    };

    return (
        <>
            <div className="task-content">
                <img src={take_photo} alt="take_photo" />
                <p className="role-description-text">{t("builder_text_1")}</p>
                <p className="role-description-text">{t("builder_text_2")}</p>
            </div>

            <CustomButton
                onClick={submitHandler}
                label={t("builder_submit_button")}
                img={<PhotoCameraIcon />}
            />
        </>
    );
}

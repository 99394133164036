import QRCode from "react-qr-code";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";

import background from "@/assets/img/player/background.svg";

import "./styles.css";

export default function ScanQnCodeDrawer(props) {
    const { url, open, onClose } = props;

    return (
        <Drawer
            anchor="bottom"
            open={open}
            onClose={onClose}
            sx={{
                "& .MuiDrawer-paperAnchorBottom": {
                    width: "100%",
                    height: "100%",
                    background: `url(${background})`,
                    backgroundSize: "cover",
                },
            }}
            transitionDuration={100}
        >
            <div className="fullScreen">
                <div className="drawer-container">
                    <div className="drawer-title">
                        <div />
                        <p className="title">Scan QR Code with a camera device</p>
                        <button onClick={onClose} className="drawer-close" type="button">
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="drawer-content ">
                        <div className="drawer-content-details qrcode-drawer-qrcode">
                            <QRCode fgColor="#F36F2B" size={256} value={url} />
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

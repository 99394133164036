import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} Game
 * @property {string} id
 * @property {string} name
 * @property {string} url
 * @property {string} user      (the User ID)
 * @property {string} model      (the GameModel ID)
 * @property {string} [client]
 * @property {string[]} [bonusTasks]   (list with BonusTask IDs)
 * @property {string[]} [languages]   (list with Language IDs)
 * @property {string} userFullName (the User full name)
 * @property {string} gameModelName (the GameModel name)
 * @property {number} dateCreated
 * @property {number} [dateStarted]
 * @property {number} [dateEnded]
 */

/**
 * @typedef {object} GameData
 * @property {string} name
 * @property {string} model
 * @property {string} [client]
 * @property {string[]} [bonusTasks]
 * @property {string[]} [languages]
 */

/**
 * @typedef {GameData & {userId: string; numTeams: number}} GameDataForAdd
 */

/**
 * @typedef {GameData & {id: string}} GameDataForEdit
 */

/**
 * Query for the Games list.
 * @param {string} userId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Game[]>}
 */
export function useGames(userId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "Game"],
        queryFn: () => getGames(userId),
        enabled,
    });
}

/**
 * Query for a Game.
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Game>}
 */
export function useGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "Game", gameId],
        queryFn: () => getGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Delete a Game
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, string, unknown>}
 */
export function useGameDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteGame,
        onSuccess: (_data, gameId) => {
            // update the ["admin", "Game"] key (e.g. the list with Games)
            queryClient.setQueryData(["admin", "Game"], (oldGames) => {
                if (!oldGames) return oldGames;
                return oldGames.filter((game) => game.id !== gameId);
            });

            // update the ["admin", "Game", gameId] key (e.g. the concrete Game)
            queryClient.removeQueries({ queryKey: ["admin", "Game", gameId] });
        },
        meta: { entity: "Game", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a Game
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameDataForEdit, unknown>}
 */
export function useGameEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editGame,
        onSuccess: (game) => {
            // update the ["admin", "Game"] key (e.g. the list with Games)
            queryClient.setQueryData(["admin", "Game"], (oldGames) => {
                let found = false;
                const newGames =
                    oldGames?.map((oldGame) => {
                        if (oldGame.id === game.id) {
                            found = true;
                            // merge - let the new overwrite the existing
                            return { ...oldGame, ...game };
                        }
                        return oldGame;
                    }) ?? [];

                // just in case a BonusTask that is not present is edited then add it
                if (!found) newGames.push(game);

                return newGames;
            });

            // update the ["admin", "Game", gameId] key (e.g. the concrete Game)
            queryClient.setQueryData(["admin", "Game", game.id], (oldGame) => {
                // merge - let the new overwrite the existing
                return { ...oldGame, ...game };
            });
        },
        meta: { entity: "Game", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Add/create a new Game
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameDataForAdd, unknown>}
 */
export function useGameAdd() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addGame,
        onSuccess: (game) => {
            // update the ["admin", "Game"] key (e.g. the list with Games)
            queryClient.setQueryData(["admin", "Game"], (oldGames) => {
                if (!oldGames) return [game];
                return [game, ...oldGames];
            });

            // set the ["admin", "Game", gameId] key (e.g. the concrete Game)
            queryClient.setQueryData(["admin", "Game", game.id], game);
        },
        meta: { entity: "Game", action: "create" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get Games function to be used by useQuery
 * @param {string} userId
 * @return {Promise<Game[]>}
 */
const getGames = async (userId) => {
    const { data: gamesData } = await adminApi.get(`/games/byUser/${userId}`);

    if (!gamesData.length) return [];

    // get only once as it's the same user for all
    const { data: userData } = await adminApi.get(`/users/${userId}`);

    const games = [];
    for (const gameData of gamesData) {
        games.push(await parseGame(gameData, userData));
    }
    return games;
};

/**
 * Get Game function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Game>}
 */
const getGame = async (gameId) => {
    const { data: gameData } = await adminApi.get(`/games/${gameId}`);
    const { data: userData } = await adminApi.get(`/users/${gameData.user}`);
    return parseGame(gameData, userData);
};

/**
 * Parse server data
 * @param {any} gameData
 * @param {any} userData
 * @return {Promise<Game>}
 */
const parseGame = async (gameData, userData) => {
    gameData = fixId(gameData);

    const { data: gameModelData } = await adminApi.get(`/game_models/${gameData.model}`);
    gameData.gameModelName = gameModelData.name;
    gameData.userFullName = `${userData.firstName} ${userData.lastName}`;

    // just some renames
    gameData.dateCreated = gameData.date_created;
    delete gameData.date_created;
    gameData.dateStarted = gameData.date_started;
    delete gameData.date_started;
    gameData.dateEnded = gameData.date_ended;
    delete gameData.date_ended;

    return gameData;
};

/**
 * Delete a Game.
 * It will delete also all accompanying data like teams, players, round-times, photos, etc...
 * @param {string} gameId
 */
const deleteGame = async (gameId) => {
    await adminApi.delete(`/games/${gameId}`);
};

/**
 * Edit a Game.
 * @param {GameDataForEdit} inGameData
 */
const editGame = async (inGameData) => {
    const { data: gameData } = await adminApi.put(`/games/${inGameData.id}`, {
        name: inGameData.name,
        model: inGameData.model,
        client: inGameData.client,
        bonusTasks: inGameData.bonusTasks,
        languages: inGameData.languages,
    });

    const { data: userData } = await adminApi.get(`/users/${gameData.user}`);

    return parseGame(gameData, userData);
};

/**
 * Add/create a new Game.
 * @param {GameDataForAdd} inGameData
 */
const addGame = async (inGameData) => {
    const { data: gameData } = await adminApi.post(`/games`, {
        name: inGameData.name,
        model: inGameData.model,
        client: inGameData.client,
        bonusTasks: inGameData.bonusTasks,
        languages: inGameData.languages,

        numTeams: inGameData.numTeams,
        user: inGameData.userId,
    });

    const { data: userData } = await adminApi.get(`/users/${inGameData.userId}`);

    return parseGame(gameData, userData);
};

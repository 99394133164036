import { log } from "./logger";

const environment = import.meta.env.MODE;
const version = import.meta.env.VITE_VERSION;

let apiUrlTemp = import.meta.env.VITE_API_URL;
let webUrlTemp = import.meta.env.VITE_WEB_URL;

log(`Environment: ${environment}`);
log(`Version: ${version}`);

switch (environment) {
    case "staging":
        apiUrlTemp = webUrlTemp = "https://staging.ibuild.team";
        break;
    case "production":
        apiUrlTemp = webUrlTemp = "https://ibuild.team";
        break;
    default:
        // backend and frontend differ in local development
        apiUrlTemp = apiUrlTemp || "http://localhost:5000";
        webUrlTemp = webUrlTemp || "http://localhost:5173";

        break;
}

export const apiUrl = apiUrlTemp;
export const webUrl = webUrlTemp;

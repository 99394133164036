import { useState, useCallback } from "react";
// import useUpdatableState from "@landisdesign/use-updatable-state";

// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@mui/styles";
import Stack from "@mui/material/Stack";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../../../../components/Button/Button";

import { EMPTY_ARRAY, getImageForRole } from "@/utils/utils";
import * as Constants from "@/utils/constants";
import { useGame } from "@/layouts/admin/cache/games";
import { useRounds } from "@/layouts/admin/cache/gameRounds";
import {
    useTeamsRoundsRoles,
    useTeamEdit,
    useTeamDelete,
} from "@/layouts/admin/cache/gameTeamsRoundsRoles";
import { useRunningGame, changePlayerRole } from "@/layouts/admin/realtime";

/**
 * @param {{gameId: string}} props
 */
export default function Teams({ gameId }) {
    const { data: game } = useGame(gameId);
    const { data: rounds = EMPTY_ARRAY } = useRounds(gameId);
    const { data: teams = EMPTY_ARRAY } = useTeamsRoundsRoles(gameId);
    const { data: runningGame } = useRunningGame(gameId);

    if (!game) throw new Error("Game should be valid in the Teams component");
    // if (!game) return <>No Game yet</>;

    return (
        <div className="splash">
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{/* the expand/collapse icon is here */}</TableCell>
                            <TableCell>Team №</TableCell>
                            <TableCell>Team Name</TableCell>
                            <TableCell align="right">Players</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team, index) => (
                            <Team
                                key={team.id}
                                game={game}
                                rounds={rounds}
                                runningGame={runningGame}
                                team={team}
                                teamIndex={index}
                                disableDelete={teams.length <= 2}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

const useStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});

const allRoles = Object.values(Constants.PlayerRole);

/**
 * Get the image url for given role
 * @param {string} [role]
 * @param {boolean} [isActive]
 * @return {import("react").ReactNode}
 */
const getPlayerRoleImage = (role, isActive) => {
    const imgSrc = getImageForRole(role, isActive);
    return imgSrc ? <img src={imgSrc} alt="player_role" /> : "-";
};

/**
 * 1. player's role is same as this one
 * 2. role is "architect" or "pyro" and it's taken by another player
 * @param {string} role role to check
 * @param {string|undefined} playerRole current player's role
 * @param {import("@/layouts/admin/realtime").Player[]} players
 * @return {boolean}
 */
const isPlayerRoleInRoundAllowed = (role, playerRole, players) => {
    if (role === playerRole) return false;

    for (const player of players) {
        // if another player has checked "architect"/"pyro" role then it's not allowed for this one
        if (
            (role === Constants.PlayerRole.architect || role === Constants.PlayerRole.pyro) &&
            role === player.role
        )
            return false;
    }

    return true;
};

/**
 * @param {{
 * game: import("@/layouts/admin/cache/games").Game,
 * rounds: import("@/layouts/admin/cache/gameRounds").Round[],
 * teamIndex: number
 * team: import("@/layouts/admin/cache/gameTeamsRoundsRoles").Team,
 * runningGame: import("@/layouts/admin/realtime").RunningGame | undefined,
 * disableDelete: boolean| undefined
 * }} props
 */
function Team({ game, rounds, runningGame, team, teamIndex, disableDelete = false }) {
    const classes = useStyles();

    // note the 'useUpdatableState' will ensure that
    // if the 'passed' value changes it will update the output value
    // const [teamCode, setTeamCode] = useUpdatableState(team.code);
    const [teamCode, setTeamCode] = useState(team.code);

    // the UI expanded state of the players's row
    const [expanded, setExpanded] = useState(false);

    const deleteTeam = useTeamDelete();
    const editTeam = useTeamEdit();

    const handleEditTeam = useCallback(
        () => editTeam({ id: team.id, code: teamCode }),
        [team.id, teamCode],
    );

    const handleDeleteTeam = useCallback(
        () => deleteTeam({ id: team.id, gameId: game.id }),
        [team.id, game.id],
    );

    const currentRound = runningGame?.round;
    const currentPlayers = runningGame?.players;

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    {team.players.length > 0 && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setExpanded(!expanded)}
                        >
                            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>{teamIndex}</TableCell>
                <TableCell component="th" scope="row">
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <TextValidator
                            onChange={(e) => setTeamCode(e.target.value.toUpperCase())}
                            name="teamCode"
                            value={teamCode}
                            // fullWidth
                            validators={["required", "maxStringLength:16"]}
                            errorMessages={[
                                "This field is required",
                                "Maximum 16 characters allowed",
                            ]}
                            disabled={!!game.dateStarted}
                        />
                        <Button
                            color="primary"
                            size="sm"
                            startIcon={<SaveIcon />}
                            onClick={handleEditTeam}
                            disabled={!!game.dateStarted || teamCode === team.code}
                        >
                            Save Name
                        </Button>
                        <Button
                            color="primary"
                            size="sm"
                            startIcon={<DeleteIcon />}
                            onClick={handleDeleteTeam}
                            disabled={!!game.dateStarted || disableDelete}
                        >
                            Delete Team
                        </Button>
                    </Stack>
                </TableCell>
                <TableCell align="right">{team.players.length}</TableCell>
            </TableRow>

            {/* the Expandable "sub-table" */}
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={expanded}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>Nickname</TableCell>
                                    <TableCell>State</TableCell>
                                    <TableCell>
                                        <div className="player-round-roles">
                                            {rounds.map((round) => {
                                                let additionalClassName = "";
                                                if (runningGame)
                                                    additionalClassName =
                                                        round.order === runningGame.roundOrder
                                                            ? "current-item"
                                                            : round.order < runningGame.roundOrder
                                                            ? "past-item"
                                                            : "";

                                                return (
                                                    <span
                                                        key={round.id}
                                                        className={`round-cell-item ${additionalClassName}`}
                                                    >
                                                        {round.order}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </TableCell>
                                    <TableCell>Roles Changes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {team.players.map(
                                    ({ player, nickname, avatar, rounds: playerRounds }) => {
                                        /**
                                         * @type {string|undefined}
                                         */
                                        let currentStatus;
                                        /**
                                         * @type {string|undefined}
                                         */
                                        let currentRole;
                                        if (currentPlayers) {
                                            const currentPlayer = currentPlayers.find(
                                                ({ id }) => id === player,
                                            );
                                            if (currentPlayer) {
                                                currentStatus = currentPlayer.status;
                                                currentRole = currentPlayer.role;
                                            }
                                        }

                                        return (
                                            <TableRow key={player}>
                                                <TableCell>
                                                    {avatar && <img src={avatar} alt="avatar" />}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {nickname}
                                                </TableCell>
                                                <TableCell>
                                                    {currentStatus ===
                                                        Constants.PlayerStatus.Finished && (
                                                        <p className="player-lobby-indicator">L</p>
                                                    )}
                                                    {currentStatus ===
                                                        Constants.PlayerStatus.Playing && (
                                                        <p className="player-playing-indicator">
                                                            P
                                                        </p>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <div className="player-round-roles">
                                                        {rounds.map(({ id }) => {
                                                            const playerRound = playerRounds.find(
                                                                ({ round }) => round === id,
                                                            );
                                                            return (
                                                                <span
                                                                    key={id}
                                                                    className="round-cell-item"
                                                                >
                                                                    {getPlayerRoleImage(
                                                                        currentRound === id
                                                                            ? currentRole
                                                                            : playerRound?.role,
                                                                        currentRound === id,
                                                                    )}
                                                                </span>
                                                            );
                                                        })}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {allRoles.map((role) => {
                                                        let disabled = true;
                                                        if (runningGame) {
                                                            // disable if:
                                                            // 1. player is already playing (e.g. using his chosen 'role')
                                                            // 2. player's role is same as this one
                                                            // 3. role is "architect" or "pyro" and it's taken by another player
                                                            disabled =
                                                                currentStatus ===
                                                                    Constants.PlayerStatus
                                                                        .Playing ||
                                                                !isPlayerRoleInRoundAllowed(
                                                                    role,
                                                                    currentRole,
                                                                    runningGame.players,
                                                                );
                                                        }
                                                        return (
                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                key={`${player}-${role}-button`}
                                                                size="sm"
                                                                title={`Set role to be "${role}"`}
                                                                onClick={() => {
                                                                    changePlayerRole(
                                                                        game.id,
                                                                        // @ts-ignore runningGame is not undefined when disabled is false
                                                                        runningGame.round,
                                                                        player,
                                                                        role,
                                                                    );
                                                                }}
                                                                className="edit-role-btn"
                                                                disabled={disabled}
                                                            >
                                                                <img
                                                                    src={getImageForRole(role)}
                                                                    alt="img role"
                                                                />
                                                            </Button>
                                                        );
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    },
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

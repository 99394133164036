import { useRef, useEffect, useMemo } from "react";
import { useSetState, useEffectOnce, useToggle } from "react-use";

// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import Timer from "react-compound-timerv2";
// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@mui/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import Button from "../../../../components/Button/Button";

import { useGame } from "@/layouts/admin/cache/games";
import { useRounds, useRoundEdit } from "@/layouts/admin/cache/gameRounds";
import { changeRoundTime, useRunningGame } from "@/layouts/admin/realtime";
import { EMPTY_ARRAY } from "@/utils/utils";

const ONE_MINUTE = 60 * 1000; // 1 minute
const MIN_TIME_AFTER_CHANGE = 30 * 1000; // 30 seconds

/**
 * List of Rounds
 * @param {{gameId: string}} props
 */
export default function Rounds({ gameId }) {
    const { data: game } = useGame(gameId);
    const { data: rounds = EMPTY_ARRAY } = useRounds(gameId);
    const { data: runningGame } = useRunningGame(gameId);

    if (!game) throw new Error("Game should be valid in the Rounds component");
    // if (!game) return <>No Game yet</>;

    return (
        <ul className="rounds-list-times splash">
            {rounds.map((round) => (
                <Round key={round.id} round={round} game={game} runningGame={runningGame} />
            ))}
        </ul>
    );
}

const useStyles = makeStyles({
    timeField: {
        maxWidth: 100,
    },
});

/**
 * Single Round
 * @param {{
 * round: import("@/layouts/admin/cache/gameRounds").Round,
 * game: import("@/layouts/admin/cache/games").Game,
 * runningGame: import("@/layouts/admin/realtime").RunningGame | undefined,
 * }} props
 */
function Round({ round, game, runningGame }) {
    const { dateEnded } = game;

    const classes = useStyles();

    // used to force rerender  - call on some timer checkpoints
    const [_, forceRerender] = useToggle(true);

    /**
     * @type {import("react").MutableRefObject<undefined | import("@/forked/react-compound-timer/build").TimerControls>}
     */
    const timerRef = useRef();
    const checkpoints = useMemo(
        () => [
            {
                time: ONE_MINUTE + MIN_TIME_AFTER_CHANGE,
                callback: forceRerender,
            },
            {
                time: ONE_MINUTE,
                callback: forceRerender,
            },
        ],
        [forceRerender],
    );

    const editRound = useRoundEdit();

    const [localState, setLocalState] = useSetState({
        time: round.time / 60 / 1000, // time is seconds , round.time is in milliseconds
        additionalTime: 0, //  additionalTime is in minutes
    });

    useEffectOnce(() => {
        if (!timerRef.current) return;

        // set the timer (in milliseconds)
        timerRef.current.setTime(round.time);
        forceRerender();
    });

    useEffect(() => {
        if (!timerRef.current) return;

        // start the timer when 'current round' is updated
        if (!dateEnded && runningGame && round.id === runningGame.round) {
            timerRef.current.setTime(runningGame.roundTime);
            timerRef.current.start();
        } else {
            // set the "normal" round time
            timerRef.current.setTime(round.time);
            timerRef.current.stop();
        }
        forceRerender();
    }, [round.id, runningGame, dateEnded]);

    const handleDecreaseMinutes = () => {
        setLocalState(({ additionalTime }) => ({ additionalTime: additionalTime - 1 }));
    };

    const handleIncreaseMinutes = () => {
        setLocalState(({ additionalTime }) => ({ additionalTime: additionalTime + 1 }));
    };

    const handleChangeAdditionalTime = () => {
        if (!timerRef.current) return;
        // localState.additionalTime is in minutes
        const additionalTime = localState.additionalTime * ONE_MINUTE;
        const currentTime = timerRef.current.getTime();
        const newTime = currentTime + additionalTime;

        // apply 'additionalTime' only if after such change there's be at least 30 seconds
        if (newTime >= MIN_TIME_AFTER_CHANGE) {
            changeAdditionalTime(additionalTime);
        }
    };
    const handleChangeAdditionalTimeOneMinute = () => {
        if (!timerRef.current) return;
        const currentTime = timerRef.current.getTime();

        // only if more than a minute is remaining - change the time to END after exactly a minute
        if (currentTime > ONE_MINUTE) {
            const additionalTime = -(currentTime - ONE_MINUTE);
            changeAdditionalTime(additionalTime);
        }
    };
    const handleChangeAdditionalTimeNow = () => {
        if (!timerRef.current) return;
        const currentTime = timerRef.current.getTime();

        const endAfter = 10000; // 10 seconds  (note the server allows min 5 seconds)
        if (currentTime > endAfter) {
            const additionalTime = -(currentTime - endAfter);
            changeAdditionalTime(additionalTime);
        }
    };
    /**
     * @param {number} additionalTime
     */
    const changeAdditionalTime = (additionalTime) => {
        setLocalState({ additionalTime: 0 });

        changeRoundTime(game.id, round.id, additionalTime);
    };

    const handleSave = async () => {
        // localState.time is in seconds, round.time is in milliseconds
        await editRound({ id: round.id, time: localState.time * 60 * 1000 });
    };

    const className = (() => {
        if (dateEnded) return "past";

        if (!runningGame) return "";

        if (round.order === runningGame.roundOrder) return "current";
        else if (round.order < runningGame.roundOrder) return "past";
        else if (round.order > runningGame.roundOrder) return "future";
    })();

    // cannot change Round's time if Game is ended or
    // if it's running and round is current or earlier
    const cannotChangeRoundTime =
        !!dateEnded || (runningGame && round.order <= runningGame.roundOrder);

    // can change additional time only for the running current round
    const canChangeAdditionalTime = !dateEnded && round.id === runningGame?.round;

    // disable the "Minus additional time" button
    // disable the "End time in 1 minute" button
    const canHandleChangeAdditionalTime =
        !!timerRef.current &&
        timerRef.current.getTime() + localState.additionalTime * ONE_MINUTE > MIN_TIME_AFTER_CHANGE;
    const canHandleEndInOneMinuteTime =
        !!timerRef.current && timerRef.current.getTime() > ONE_MINUTE;

    return (
        <li className={className}>
            <div>
                <div className="manage-round">
                    <h5>Round {round.order}</h5>
                </div>
                <div className="round-round-time">
                    <TextValidator
                        className={classes.timeField}
                        type="number"
                        label="Round Time *"
                        onChange={(e) => setLocalState({ time: e.target.value })}
                        name="time"
                        value={localState.time}
                        validators={["required", "minNumber:1", "maxNumber:59"]}
                        errorMessages={[
                            "This field is required",
                            "Minimum 1 minute",
                            "Maximum 59 minutes",
                        ]}
                        disabled={cannotChangeRoundTime}
                    />
                    <Timer
                        initialTime={round.time}
                        direction="backward"
                        startImmediately={false}
                        ref={timerRef}
                        checkpoints={checkpoints}
                    >
                        {() => (
                            <div className="timer">
                                <AccessTimeIcon />
                                <div className="time">
                                    <Timer.Minutes
                                        formatValue={(value) => `${String(value).padStart(2, "0")}`}
                                    />
                                    :
                                    <Timer.Seconds
                                        formatValue={(value) => `${String(value).padStart(2, "0")}`}
                                    />
                                </div>
                            </div>
                        )}
                    </Timer>
                </div>

                <Button
                    type="button"
                    color="primary"
                    size="sm"
                    startIcon={<SaveIcon />}
                    onClick={handleSave}
                    disabled={cannotChangeRoundTime}
                >
                    Save Time
                </Button>
            </div>

            {/* Allow changing of the additional time ONLY in the current round */}
            {canChangeAdditionalTime && (
                <div className="round-time-add-time">
                    <div className="counter">
                        <Button
                            color="transparent"
                            justIcon
                            startIcon={<RemoveIcon htmlColor="red" />}
                            onClick={handleDecreaseMinutes}
                            disabled={!canHandleChangeAdditionalTime}
                        />
                        <p className="counter-value">{localState.additionalTime}</p>
                        <Button
                            color="transparent"
                            justIcon
                            startIcon={<AddIcon htmlColor="green" />}
                            onClick={handleIncreaseMinutes}
                        />
                    </div>

                    <Button
                        color="primary"
                        style={{ width: "100%" }}
                        size="sm"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleChangeAdditionalTime}
                        disabled={!localState.additionalTime || !canHandleChangeAdditionalTime}
                    >
                        Add Time
                    </Button>

                    <Button
                        color="danger"
                        style={{ width: "100%", color: "#fff" }}
                        size="sm"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleChangeAdditionalTimeOneMinute}
                        disabled={!canHandleEndInOneMinuteTime}
                    >
                        End time in 1 minute
                    </Button>
                    <Button
                        color="danger"
                        style={{ width: "100%", color: "#fff" }}
                        size="sm"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleChangeAdditionalTimeNow}
                        // disabled={!canHandleEndInOneMinuteTime}
                    >
                        End time now
                    </Button>
                </div>
            )}
        </li>
    );
}

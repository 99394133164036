import { useQuery } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 *  @typedef {import('./bonusTasks').BonusTask} BonusTask
 */

/**
 * @typedef {object} Player
 * @property {string} id
 * @property {string} nickname
 */

/**
 * @typedef {object} Team
 * @property {string} id
 * @property {string} code
 */

/**
 * @typedef {object} Round
 * @property {string} id
 * @property {string} round
 */

/**
 * @typedef {object} Photo
 * @property {string} contentType
 * @property {string} name
 * @property {{data: ArrayBuffer}} data // the image's real data
 */

/**
 * @typedef {object} ImageBonusTask
 * @property {string} id  // player_bonus_task ID
 * @property {Photo} photo
 * @property {BonusTask} bonusTask
 */

/**
 * @typedef {object} ImageBonusTasks
 * @property {string} id // player's nickname
 * @property {string} nickname  // player's nickname
 * ... no need for the other player's fields, but if needed can put them
 * @property {ImageBonusTask[]} bonusTasks
 */

/**
 * @typedef {{round: number,photos: Photo[]}} RoundWithPhotos
 */

/**
 * @typedef {object} ImageTeams
 * @property {Team} team
 * @property {RoundWithPhotos[]} rounds
 */

/**
 * Get BonusTasks for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|BonusTask[]>}
 */
export function useBonusTasksForGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "History", gameId, "BonusTasks"],
        queryFn: () => getBonusTasksForGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Get Players for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Player[]>}
 */
export function usePlayersForGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "History", gameId, "Players"],
        queryFn: () => getPlayersForGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Get Teams for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Team[]>}
 */
export function useTeamsForGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "History", gameId, "Teams"],
        queryFn: () => getTeamsForGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Get Rounds for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Round[]>}
 */
export function useRoundsForGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "History", gameId, "Rounds"],
        queryFn: () => getRoundsForGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Get ImageTeams for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|ImageTeams[]>}
 */
export function useImageTeamsForGame(gameId, enabled = true) {
    const { data: teams = [] } = useTeamsForGame(gameId);
    return useQuery({
        queryKey: ["admin", "History", gameId, "ImageTeams", teams.map(({ id }) => id).join()],
        queryFn: () => getImageTeamsForGame(gameId, teams),
        enabled: enabled && !!gameId,
    });
}
/**
 * Get ImageBonusTasks for a game
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|ImageBonusTasks[]>}
 */
export function useImageBonusTasksForGame(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "History", gameId, "ImageBonusTasks"],
        queryFn: () => getImageBonusTasksForGame(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Get BonusTask[] function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<BonusTask[]>}
 */
const getBonusTasksForGame = async (gameId) => {
    const bonusTasks = [];
    const { data: bonusTasksData } = await adminApi.get(`/games/${gameId}/bonusTasks`);

    for (const bonusTaskId of bonusTasksData) {
        const { data: bonusTask } = await adminApi.get(`/bonus_tasks/${bonusTaskId}`);
        bonusTasks.push(fixId(bonusTask));
    }

    return bonusTasks;
};

/**
 * Get Player[] function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Player[]>}
 */
const getPlayersForGame = async (gameId) => {
    const players = [];
    const { data: playersData } = await adminApi.get(`/players/byGame/${gameId}`);

    for (const player of playersData) {
        players.push(fixId(player));
    }

    return players;
};

/**
 * Get Team[] function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Team[]>}
 */
const getTeamsForGame = async (gameId) => {
    const teams = [];
    const { data: teamsData } = await adminApi.get(`/teams/byGame/${gameId}`);

    for (const team of teamsData) {
        teams.push(fixId(team));
    }

    return teams;
};

/**
 * Get Round[] function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Round[]>}
 */
const getRoundsForGame = async (gameId) => {
    const rounds = [];
    const { data: roundsData } = await adminApi.get(`/game_round_times/byGame/${gameId}`);

    for (const round of roundsData) {
        rounds.push(fixId(round));
    }

    return rounds;
};

/**
 * Get ImageTeams[] function to be used by useQuery
 * @param {string} gameId
 * @param {Team[]} teams
 * @return {Promise<ImageTeams[]>}
 */
const getImageTeamsForGame = async (gameId, teams) => {
    /**
     * All photos by split by teams
     * @type {ImageTeams[]}
     */
    const imageTeams = [];

    for (const team of teams) {
        const { data: teamPhotosData } = await adminApi.get(
            `/team_photos/byGameAndTeam/${gameId}/${team.id}`,
        );
        const rounds = [];

        // map round to all photos for this round
        const roundsMap = new Map();
        for (const teamPhoto of teamPhotosData) {
            const { round, photo } = teamPhoto;

            let photos = roundsMap.get(round);
            if (!photos) {
                photos = [];
                rounds.push({
                    round,
                    photos,
                });
                roundsMap.set(round, photos);
            }
            photos.push(photo.img);
        }

        // put only teams with at least one photo
        if (!rounds.length) continue;

        imageTeams.push({
            team,
            rounds,
        });
    }

    return imageTeams;
};

/**
 * Get ImageBonusTasks[] function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<ImageBonusTasks[]>}
 */
const getImageBonusTasksForGame = async (gameId) => {
    /**
     * All photos for players
     * @type {ImageBonusTasks[]}
     */
    const imageBonusTasks = [];
    // all players
    const players = new Map();

    const { data: playerBonusTasksData } = await adminApi.get(
        `/player_bonus_tasks/byGame/${gameId}`,
    );

    for (const playerBonusTask of playerBonusTasksData) {
        const { _id, player, bonus_task } = playerBonusTask;

        // for backwards compatibility check the 'task_result' key
        const photo = playerBonusTask.photo || playerBonusTask.task_result;

        // skip if this is a no-image task
        if (!photo) continue;

        // skip if image not found
        if (!photo.img) continue;

        // single player's ImageBonusTasks
        let playerImageBonusTasks = players.get(player._id);
        if (!playerImageBonusTasks) {
            playerImageBonusTasks = {
                id: player._id,
                nickname: player.nickname,
                bonusTasks: [],
            };
            players.set(player._id, playerImageBonusTasks);
            imageBonusTasks.push(playerImageBonusTasks);
        }

        playerImageBonusTasks.bonusTasks.push({
            id: _id,
            bonusTask: fixId(bonus_task),
            photo: photo.img,
        });
    }

    return imageBonusTasks;
};

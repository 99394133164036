import { useMutation } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import { dataURLtoFile } from "@/utils/utils";

/**
 * @typedef {object} UploadTeamPhotoData
 * @property {string} gameId     (Game ID)
 * @property {string} teamId     (Team ID)
 * @property {string} teamCode     (Team code)
 * @property {string} playerId     (Player ID)
 * @property {string} nickname     (Player nickname)
 * @property {string} roundId  (Round ID)
 * @property {string} roundOrder  (Round order)
 * @property {string} imageDataUri base64 image data
 */

/**
 * Mutate a Bonus-Task to be "done"
 * @return {import("@tanstack/react-query").UseMutateFunction<unknown, unknown, UploadTeamPhotoData, unknown>}
 */
export function useUploadTeamPhoto() {
    const mutation = useMutation({
        /**
         * @param {UploadTeamPhotoData} data
         */
        mutationFn: uploadTeamPhoto,
        meta: { entity: "Photo", action: "upload" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutate;
}

/**
 * Upload a TeamPhoto.
 * Note it will create a internal Image DB reference and delete any optional old TeamPhoto.
 * @param {UploadTeamPhotoData} data
 */
const uploadTeamPhoto = async ({
    gameId,
    teamId,
    teamCode,
    playerId,
    nickname,
    // roundId,
    roundOrder,
    imageDataUri,
}) => {
    const file = dataURLtoFile(
        imageDataUri,
        `Game${gameId}_Team${teamCode}_${nickname.replace(" ", "-")}_${roundOrder}.jpg`,
    );

    // save to team_photos (it will create an Image internally)
    const formData = new FormData();
    formData.append("file", file);
    formData.append("game", gameId);
    formData.append("team", teamId);
    formData.append("player", playerId);
    formData.append("round", roundOrder);

    await playerApi.post("/team_photos", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

import { createTheme } from "@mui/material/styles";

import { red } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: "#F36F2B",
        },
        secondary: {
            main: "#19857b",
        },
        error: {
            main: red.A400,
        },
        background: {
            default: "#fff",
        },
    },
});

export default theme;

import { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Table from "../../../../components/Table/Table";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/Button/Button";
import DeletePopup from "../../../../components/Modal/DeletePopup";

import { rootPathAdmin, page_admin_bonus_tasks_add } from "@/utils/constants";
import { useBonusTasks, useBonusTaskDelete } from "../../cache/bonusTasks";
import { EMPTY_ARRAY } from "@/utils/utils";

export default function BonusTasks() {
    const { data: bonusTasks = EMPTY_ARRAY } = useBonusTasks();
    const deleteBonusTask = useBonusTaskDelete();

    const [deleteDialogFunction, setDeleteDialogFunction] = useState();

    const openDelete = useCallback((bonusTaskId) => {
        setDeleteDialogFunction(() => async () => {
            await deleteBonusTask(bonusTaskId);
            closeDelete();
        });
    }, []);
    const closeDelete = useCallback(() => setDeleteDialogFunction(undefined), []);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <Link to={page_admin_bonus_tasks_add}>
                                <Button color="primary">Create Bonus Task</Button>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Has Action", "createdBy", ""]}
                                tableData={bonusTasks.map(({ id, name, action, userFullName }) => [
                                    <Link to={`${rootPathAdmin}/bonus-tasks/${id}/edit`}>
                                        {name}
                                    </Link>,
                                    action ? "true" : "false",
                                    userFullName,

                                    <Button color="danger" onClick={() => openDelete(id)}>
                                        Delete
                                    </Button>,
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <DeletePopup
                handleSubmit={deleteDialogFunction}
                handleClose={closeDelete}
                title="Delete Bonus task"
                message="Are you sure you want to delete this Bonus task?"
                open={!!deleteDialogFunction}
            />
        </div>
    );
}

import { makeStyles } from "@mui/styles";
import { Fab } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import GameModelsIcon from "@mui/icons-material/BubbleChart";
import GamesIcon from "@mui/icons-material/SportsEsports";
import GameControlsIcon from "@mui/icons-material/KeyboardCommandKey";
import PlayersIcon from "@mui/icons-material/Accessibility";

import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
// import CardFooter from "../../../components/Card/CardFooter";

import styles from "@/assets/jss/ibuild-admin-react/views/dashboardStyle";
import {
    useStatsGameModels,
    useStatsGames,
    useStatsPlayers,
    useStatsRunningGames,
    refetchStats,
} from "../cache/stats";

const useStyles = makeStyles(styles);

/**
 * Add some statistics and add some way to observe and control the GameControls in the server,
 * so that there to be a possibility to "fix/cleanup" if something breaks.
 */
export default function Dashboard() {
    const classes = useStyles();

    const { data: runningGames } = useStatsRunningGames();
    const { data: gameModels = 0 } = useStatsGameModels();
    const {
        data: { games, gamesStarted, gamesEnded } = {
            games: 0,
            gamesStarted: 0,
            gamesEnded: 0,
        },
    } = useStatsGames();
    const { data: players = 0 } = useStatsPlayers();

    return (
        <>
            <div className="version">Version: {import.meta.env.VITE_VERSION}</div>
            <GridContainer>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="warning" stats icon>
                            <CardIcon color="warning">
                                <GameModelsIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Game Models</p>
                            <h3 className={classes.cardTitle}>{gameModels}</h3>
                        </CardHeader>
                        {/* <CardFooter stats>
                        <div className={classes.stats}>
                            &nbsp;
                            <Danger>
                                <Warning />
                            </Danger>
                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                Get more space
                            </a>
                        </div>
                    </CardFooter> */}
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                                <GamesIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Games</p>
                            <h3 className={classes.cardTitle}>All - {games}</h3>
                            <h3 className={classes.cardTitle}>Started - {gamesStarted}</h3>
                            <h3 className={classes.cardTitle}>Ended - {gamesEnded}</h3>
                        </CardHeader>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <PlayersIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Players</p>
                            <h3 className={classes.cardTitle}>{players}</h3>
                        </CardHeader>
                    </Card>
                </GridItem>

                <GridItem xs={12} sm={6} md={3}>
                    <Card>
                        <CardHeader color="info" stats icon>
                            <CardIcon color="info">
                                <GameControlsIcon />
                            </CardIcon>
                            <p className={classes.cardCategory}>Game controls</p>
                            <h3 className={classes.cardTitle}>{runningGames?.length}</h3>
                        </CardHeader>
                    </Card>
                </GridItem>
            </GridContainer>

            <Fab
                color="error"
                sx={{
                    position: "fixed",
                    bottom: 40,
                    right: 40,
                }}
                onClick={refetchStats}
            >
                <SyncIcon />
            </Fab>
        </>
    );
}

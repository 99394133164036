import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import { dataURLtoFile, getImageForImageData, fixId, noop } from "@/utils/utils";

import { useGame } from "./game";

/**
 * @typedef {object} BonusTask
 * @property {string} id
 * @property {string} name
 * @property {boolean} action
 * @property {string} [description]
 * @property {object} [image]
 * @property {boolean} [done]
 */

/**
 * @typedef {object} BonusTaskDoneData
 * @property {string} gameId
 * @property {string} playerId
 * @property {string} nickname
 * @property {string} bonusTaskId
 * @property {string} [imageDataUri] base64 image data
 */

/**
 * Query for the Bonus-Tasks list.
 * @param {string} playerId
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|BonusTask[]>}
 */
export function useBonusTasks(playerId) {
    const { data: game } = useGame();

    const isAllowed = !!game;
    const result = useQuery({
        queryKey: ["player", "BonusTasks"],
        queryFn: () => getBonusTasks(game.bonusTasks, playerId),
        enabled: isAllowed,
    });
    return {
        ...result,
        refetch: isAllowed ? result.refetch : noop,
    };
}

/**
 * Mutate a Bonus-Task to be "done"
 * @return {import("@tanstack/react-query").UseMutateFunction<unknown, unknown, BonusTaskDoneData, unknown>}
 */
export function useBonusTaskDone() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        /**
         * @param {BonusTaskDoneData} data
         */
        mutationFn: setBonusTaskDone,
        onSuccess: () => {
            // on successful mutation invalidate the query so it will be refetched.
            // Note: other possibility is to update it internally https://tanstack.com/query/latest/docs/react/guides/updates-from-mutation-responses
            queryClient.invalidateQueries({ queryKey: ["player", "BonusTasks"] });
        },
        meta: { entity: "BonusTask", action: "do" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutate;
}

/**
 * Get Bonus-Tasks function to be used by useQuery
 * @param {string[]|undefined} bonusTasks
 * @param {string} playerId
 * @return {Promise<BonusTask[]>}
 */
const getBonusTasks = async (bonusTasks, playerId) => {
    const res = [];

    if (bonusTasks) {
        for (const item of bonusTasks) {
            let taskDone = false;
            let { data: bonusTaskData } = await playerApi.get(`/bonus_tasks/${item}`);
            bonusTaskData = fixId(bonusTaskData);

            if (bonusTaskData.image) {
                const { data: imageData } = await playerApi.get(`/images/${bonusTaskData.image}`);
                bonusTaskData.image = getImageForImageData(imageData.img);
            }

            // check if the current task is done by current player
            if (playerId) {
                const { data: bonusTaskPlayerData } = await playerApi.get(
                    `/player_bonus_tasks/byPlayerAndBonusTask/${playerId}/${item}`,
                );

                if (bonusTaskPlayerData) taskDone = true;
            }
            bonusTaskData.done = taskDone;

            res.push(bonusTaskData);
        }
    }

    return res;
};

/**
 * Set Bonus-Task to be done by the a player.
 * Note it will create a internal Image DB reference and delete any optional old TeamPhoto.
 * @param {BonusTaskDoneData} data
 */
const setBonusTaskDone = async (data) => {
    const { gameId, playerId, nickname, bonusTaskId, imageDataUri } = data;

    const formData = new FormData();
    if (imageDataUri) {
        const file = dataURLtoFile(
            imageDataUri,
            `BonusTask${bonusTaskId}_${nickname.replace(" ", "-")}.jpg`,
        );
        formData.append("file", file);
    }
    formData.append("game", gameId);
    formData.append("player", playerId);
    formData.append("bonus_task", bonusTaskId);

    await playerApi.post("/player_bonus_tasks", formData, {
        headers: { "Content-Type": "multipart/form-data" },
    });
};

import JoinPlayer from "@/layouts/player/screens/JoinPlayer";
import JoinTeam from "@/layouts/player/screens/JoinTeam";
import ChooseRole from "@/layouts/player/screens/ChooseRole";
import Architect1 from "@/layouts/player/screens/Architect1";
import Architect2 from "@/layouts/player/screens/Architect2";
import Pyro1 from "@/layouts/player/screens/Pyro1";
import Pyro2 from "@/layouts/player/screens/Pyro2";
import Builder1 from "@/layouts/player/screens/Builder1";
import Builder2 from "@/layouts/player/screens/Builder2";
import Builder3 from "@/layouts/player/screens/Builder3";
import Check from "@/layouts/player/screens/Check";
import Congrats from "@/layouts/player/screens/Congrats";
import TaskFinished from "@/layouts/player/screens/TaskFinished";
import BuilderUploadPhotoExt from "@/layouts/player/screens/BuilderUploadPhotoExt";
import BonusTaskUploadPhotoExt from "@/layouts/player/screens/BonusTaskUploadPhotoExt";

import {
    page_player_join,
    page_player_join_team,
    page_player_choose_role,
    page_player_architect_start,
    page_player_architect_task,
    page_player_pyro_start,
    page_player_pyro_task,
    page_player_builder_start,
    page_player_builder_take_photo,
    page_player_builder_upload_photo,
    page_player_check,
    page_player_task_finished,
    page_player_congrats,
    page_player_ext_builder_upload_photo,
    page_player_ext_bonus_task_upload_photo,
    rootPathPlayer,
} from "@/utils/constants";

/**
 * @typedef {object} PlayRoute
 * @property {string} slug
 * @property {string} className
 * @property {string} headerTitle
 * @property {import("react").ComponentType} component
 * @property {boolean} [isStart]
 * @property {boolean} [isEnd]
 * @property {boolean} [hideLogoTop]
 * @property {boolean} [hideLogo]
 */
/**
 * Routes that use Game/RunningGame and etc...
 * @type {PlayRoute[]}
 */
export const routes = [
    /* normal game flow screens */
    {
        component: JoinPlayer,
        slug: createRoutePathForGame(page_player_join),
        isStart: true,
        className: "enter-nickname",
        headerTitle: "welcome",
    },
    {
        component: JoinTeam,
        slug: createRoutePathForGame(page_player_join_team),
        className: "enter-teamcode",
        headerTitle: "teamcode_screen_title",
    },
    {
        component: ChooseRole,
        slug: createRoutePathForGame(page_player_choose_role),
        className: "choose-a-role",
        headerTitle: "choose_a_role_screen_title",
    },
    {
        component: Architect1,
        slug: createRoutePathForGame(page_player_architect_start),
        className: "screen-role-arch screen-1",
        headerTitle: "arch_title",
    },
    {
        component: Architect2,
        slug: createRoutePathForGame(page_player_architect_task),
        className: "screen-role-arch screen-2",
        headerTitle: "role_task_title",
        hideLogoTop: true,
    },
    {
        component: Pyro1,
        slug: createRoutePathForGame(page_player_pyro_start),
        className: "screen-role-pyro screen-1",
        headerTitle: "pyro_title",
    },
    {
        component: Pyro2,
        slug: createRoutePathForGame(page_player_pyro_task),
        className: "screen-role-pyro screen-2",
        headerTitle: "role_task_title",
    },
    {
        component: Builder1,
        slug: createRoutePathForGame(page_player_builder_start),
        className: "screen-role-builder screen-1",
        headerTitle: "builder_title",
    },
    {
        component: Builder2,
        slug: createRoutePathForGame(page_player_builder_take_photo),
        className: "screen-role-builder screen-2",
        headerTitle: "role_task_title",
    },
    {
        component: Builder3,
        slug: createRoutePathForGame(page_player_builder_upload_photo),
        className: "bonus-task-action",
        headerTitle: "submit_photo_title",
    },
    {
        component: TaskFinished,
        slug: createRoutePathForGame(page_player_task_finished),
        className: "waiting-room",
        headerTitle: "waiting_room_title",
    },
    {
        component: Check,
        slug: createRoutePathForGame(page_player_check),
        className: "screen-check",
        headerTitle: "builder_check_title",
    },
    {
        component: Congrats,
        slug: createRoutePathForGame(page_player_congrats),
        className: "congrats",
        headerTitle: "congrats_title",
        isEnd: true,
    },
];

/**
 * Routes that are "external" - they don't need to fetch anything, all the need they have it from params
 * @type {PlayRoute[]}
 */
export const routesExt = [
    /* the upload image pages as standalone/external opened when scanned a QR code */
    {
        component: BuilderUploadPhotoExt,
        slug: createRoutePathForGame(`${page_player_ext_builder_upload_photo}/:data`),
        className: "bonus-task-action",
        headerTitle: "submit_photo_title",
    },
    {
        component: BonusTaskUploadPhotoExt,
        slug: createRoutePathForGame(`${page_player_ext_bonus_task_upload_photo}/:data`),
        className: "bonus-task-action",
        headerTitle: "submit_photo_title",
    },
];

/**
 * Create a route path for a game
 * @param {string} path path without the ".../:gameId/..." param
 * @param {string} [gameId] the game ID if passed or the ":gameId" placeholder
 */
export function createRoutePathForGame(path, gameId) {
    gameId = gameId || ":gameId";
    return path.replace(rootPathPlayer, `${rootPathPlayer}/${gameId}`);
}

import { useQuery } from "@tanstack/react-query";

import { log } from "@/logger";
import { queryClient } from "@/cache";
import { socketClient } from "@/service/realtimeApi";
// import avatars from "@/utils/avatars";

/*
 * ADMIN - all these Game control methods apply to when the running Game
 */

/**
 * @typedef {typeof import("../../utils/constants").PlayerRole} PlayerRole
 */
/**
 * @typedef {PlayerRole[keyof PlayerRole]} PlayerRoleValue
 */

/**
 * @typedef {typeof import("../../utils/constants").PlayerStatus} PlayerStatus
 */
/**
 * @typedef {PlayerStatus[keyof PlayerStatus]} PlayerStatusValue
 */

/**
 * @typedef {object} Player
 * @property {string} id     (Player ID)
 * @property {string} nickname
 * @property {string} [avatar]
 * @property {string} [team] (joined Team ID)
 * @property {string} [teamCode] (joined Team code)
 * @property {string} [quote] (Quote ID)
 * @property {PlayerRoleValue} [role] (current role)
 * @property {PlayerStatusValue} [status] (current status)
 */

/**
 * @typedef {object} RunningGame
 * @property {string} game     (Game ID)
 * @property {string} round  (Round ID)
 * @property {number} roundOrder    (Round order 1,2,3...)
 * @property {number} roundTime  (remaining time in the Round, could be real round's time when normal play)
 * @property {Player[]} players
 */

/**
 * Realtime event listener
 * @param {string} ev
 * @param {string} gameId
 * @param {RunningGame| undefined} runningGame
 */
const onRealtimeEvent = (ev, gameId, runningGame) => {
    switch (ev) {
        case "game:startGame":
        case "game:endGame":
            // refetch the concrete Game if necessary
            queryClient.refetchQueries({
                queryKey: ["admin", "Game", gameId],
                type: "active",
                exact: true,
            });
            // refetch the Games if necessary
            queryClient.refetchQueries({
                queryKey: ["admin", "Game"],
                type: "active",
                exact: true,
            });
            break;
        case "game:connectTeam":
            // refetch the Teams with roles if necessary
            queryClient.refetchQueries({
                queryKey: ["admin", "Teams", gameId],
                type: "active",
                exact: true,
            });
            break;
        default:
        // nothing
    }

    log(`Received RunningGame: ${JSON.stringify(runningGame)}`);

    if (runningGame) {
        // could fix here the player.avatar if only player.avatarOrder is passed, but it's not used
        // from here but from the useTeamsRoundsRoles()
        // runningGame.players.forEach((player) => {
        //     if (!player.avatar && player.avatarOrder !== undefined)
        //         player.avatar = avatars[playerData.avatarOrder];
        // });
        // update the RunningGame
        queryClient.setQueryData(["admin", "RunningGame", gameId], runningGame);
    } else {
        // if runningGame is undefined then remove the cached data
        queryClient.removeQueries({ queryKey: ["admin", "RunningGame", gameId], exact: true });
    }
};

/**
 * The realtime events for which will listen in a running game
 * @type {readonly string[]}
 */
const realtimeEvents = [
    "game:statusGame",
    "game:startGame",
    "game:endGame",
    "game:connectPlayer",
    "game:connectTeam",
    "game:changePlayerStatus",
    "game:changePlayerRole",
    "game:changeRound",
    "game:changeRoundTime",
    "game:disconnectPlayer",
];

/**
 * Query for a RunningGame data.
 * @param {string} gameId
 * @param {boolean} [refetchOnWindowFocus]
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|RunningGame>}
 */
export function useRunningGame(gameId, refetchOnWindowFocus = false) {
    const getStatus = () => {
        // refetch only for the observer that needs this - should be one per page
        if (refetchOnWindowFocus) {
            const runningGame = queryClient.getQueryData(["admin", "RunningGame", gameId]);
            if (runningGame) {
                socketClient.emit("admin:statusGame", gameId);
                log(`Request latest status for the RunningGame of ${gameId}`);
            }
        }
        // always return false, so that the 'queryFn' to NOT be re-executed
        return false;
    };
    return useQuery({
        queryKey: ["admin", "RunningGame", gameId],
        enabled: !!gameId,
        queryFn: () => null,
        staleTime: Infinity,
        refetchOnReconnect: getStatus,
        refetchOnWindowFocus: getStatus,
    });
}

/**
 * Subscribe (start listening) to the realtime game events
 * @param {string} gameId
 * @return {() => void} a disconnect/cleanup function (like meant to be used in useEffect() hook)
 */
export function subscribeGame(gameId) {
    const listeners = realtimeEvents.map((event) => {
        const listener = onRealtimeEvent.bind(null, event, gameId);
        socketClient.on(event, listener);
        return listener;
    });

    // "admin:connectGame" should provoke returning the current game's status (e.g."game:statusGame")
    socketClient.emit("admin:connectGame", gameId);

    return () => {
        socketClient.emit("admin:disconnectGame", gameId);
        realtimeEvents.forEach((event, index) => socketClient.off(event, listeners[index]));
    };
}

/**
 * Subscribe while on the Games list,
 * so that games-realtime events like when a game is started or ended to refetch the ["admin", "Game"] cache.
 * @param {string[]} gameIds
 */
export function subscribeGames(gameIds) {
    const gamesRealtimeEvents = ["games:startGame", "games:endGame"];
    const listeners = gamesRealtimeEvents.map((event) => {
        const listener = (/** @type {string} */ gameId) => {
            if (gameIds.includes(gameId)) {
                // refetch the Games if necessary
                queryClient.refetchQueries({
                    queryKey: ["admin", "Game"],
                    type: "active",
                    exact: true,
                });
            }
        };
        socketClient.on(event, listener);
        return listener;
    });
    return () => {
        gamesRealtimeEvents.forEach((event, index) => socketClient.off(event, listeners[index]));
    };
}

/**
 * Start a game - e.g. make it running
 * @param {string} gameId the game ID
 */
export function startGame(gameId) {
    // request to start the game, the response will be the whole started game
    socketClient.emit("admin:startGame", gameId);
}

/**
 * Add some extra additional time when changing it because of the network/server/latency.
 * As admin may want a boost of 30secs
 * but until this request goes to server and back some time will be already passed.
 * Set it to 0 if not needed.
 */
const LATENCY_ADDITIONAL_TIME = 1000;
/**
 * Change the time of running/current round in a running game - e.g. add/remove additional time.
 *  @param {string} gameId the running game ID
 *  @param {string} roundId the current running round ID in the running game
 *  @param {number} additionalTime the added (or removed if negative number) time
 */
export function changeRoundTime(gameId, roundId, additionalTime) {
    socketClient.emit(
        "admin:changeRoundTime",
        gameId,
        roundId,
        additionalTime + LATENCY_ADDITIONAL_TIME,
    );
}

/**
 * Change the player's role in running/current round in a running game.
 * @param {string} gameId the running game ID
 * @param {string} roundId the current running round ID in the running game
 * @param {string} playerId the player whose role to change
 * @param {string} role the new role (Constants.Role.architect, Constants.Role.pyro, Constants.Role.builder)
 */
export function changePlayerRole(gameId, roundId, playerId, role) {
    socketClient.emit("admin:changePlayerRole", gameId, roundId, playerId, role);
}

//  ViewGamePhotos

/**
 * Subscribe (start listening) to the realtime game HISTORY/PHOTOS events
 * @param {string} gameId
 * @returns {() => void} a disconnect/cleanup function (like meant to be used in useEffect() hook)
 */
export function subscribeGameHistory(gameId) {
    // just a single "history" event for now
    const event = "game:uploadPhoto";
    socketClient.on(event, (id) => {
        if (gameId === id) {
            // refetch these queries
            // ["admin", "History", gameId, "Teams"]
            // ["admin", "History", gameId, "ImageBonusTasks"]
            // ["admin", "History", gameId, "ImageTeams", ...]

            // this should refetch all the active queries for ["admin", "History", gameId, ....]
            queryClient.refetchQueries({
                queryKey: ["admin", "History", gameId],
                type: "active",
                exact: false,
            });
        }
    });
    return () => socketClient.off(event);
}

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Table from "../../../../components/Table/Table";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/Button/Button";
import DeletePopup from "../../../../components/Modal/DeletePopup";

import { rootPathAdmin, page_admin_game_models_add } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";
import { useGameModels, useGameModelDelete, useGameModelDuplicate } from "../../cache/gameModels";
import { EMPTY_ARRAY } from "@/utils/utils";

export default function GameModels() {
    const userId = useAuthUserId();

    const { data: gameModels = EMPTY_ARRAY } = useGameModels();
    const deleteGameModel = useGameModelDelete();
    const duplicateGameModel = useGameModelDuplicate();

    const [deleteDialogFunction, setDeleteDialogFunction] = useState();

    const openDelete = useCallback((gameModelId) => {
        setDeleteDialogFunction(() => async () => {
            await deleteGameModel(gameModelId);
            closeDelete();
        });
    }, []);
    const closeDelete = useCallback(() => setDeleteDialogFunction(undefined), []);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <Link to={page_admin_game_models_add}>
                                <Button color="primary">Create Game Model</Button>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "createdBy", "", ""]}
                                tableData={gameModels.map(({ id, name, user, userFullName }) => [
                                    <Link to={`${rootPathAdmin}/game-models/${id}/edit`}>
                                        {name}
                                    </Link>,

                                    userFullName,

                                    <Button
                                        color="primary"
                                        onClick={() => duplicateGameModel({ id, userId })}
                                    >
                                        Duplicate
                                    </Button>,

                                    // optional Delete button (only if the auth user is the creator)
                                    userId && userId === user ? (
                                        <Button color="danger" onClick={() => openDelete(id)}>
                                            Delete
                                        </Button>
                                    ) : (
                                        <></>
                                    ),
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <DeletePopup
                title="Delete Model"
                message="Are you sure you want to delete this Game Model?"
                open={!!deleteDialogFunction}
                handleSubmit={deleteDialogFunction}
                handleClose={closeDelete}
            />
        </div>
    );
}

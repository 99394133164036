import { useState } from "react";
import { useTranslation } from "react-i18next";

import "@/layouts/player/components/styles.css";
import Sidebar from "@/layouts/player/components/Sidebar";
import Header from "@/layouts/player/components/Header";
import MessagePopup from "@/layouts/player/components/Popup/MessagePopup";
import RoundTimer from "@/layouts/player/components/RoundTimer";
import Logo from "@/layouts/player/components/Logo";
import LogoHanger from "@/layouts/player/components/LogoHanger";
import { GameState, useGame } from "@/layouts/player/cache/game";
import { usePlayer, useRunningGame } from "@/layouts/player/realtime";

// when the next-round popup will be shown before the timer is completed
const roundEndingPopupTimeout = 3 * 1000; // 3 seconds before completed

/**
 *
 * @param {{
 * children: import("react").ReactChild;
 * className: string;
 * headerTitle: string;
 * hideLogoTop?: boolean;
 * hideLogo?: boolean;
 * }} props
 */
export default function Play({
    children,
    className,
    headerTitle,
    hideLogoTop = false,
    hideLogo = false,
}) {
    const { t } = useTranslation();

    const [isRoundEndingPopupOpen, setRoundEndingPopupOpen] = useState(false);

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    const { data: player } = usePlayer();
    const { data: runningGame } = useRunningGame();

    // common checkpoints,
    // move to next round when the time finishes, only when the player is finally ready,
    // e.g has chosen `teamId` and etc...
    const checkpoints =
        player?.id && player?.team
            ? [
                  {
                      time: roundEndingPopupTimeout,
                      callback: () => {
                          // show a MessagePopup that next round is started
                          setRoundEndingPopupOpen(true);
                      },
                  },
              ]
            : undefined;

    return (
        <>
            <div
                className={`page-player-wrap ${isRoundEndingPopupOpen ? "blur" : ""} ${
                    className ?? ""
                }`}
            >
                <Header
                    title={headerTitle}
                    subtitle={{
                        gameName: game.name,
                        teamCode: player?.teamCode,
                        nickname: player?.nickname,
                        roundOrder: runningGame?.roundOrder,
                    }}
                    timer={
                        game.state === GameState.Started && <RoundTimer checkpoints={checkpoints} />
                    }
                />
                <Sidebar />
                <div className="page-player-body">
                    {!hideLogoTop && <LogoHanger />}
                    {children}
                    {!hideLogo && <Logo />}
                </div>
            </div>

            <MessagePopup
                open={isRoundEndingPopupOpen}
                initialTime={roundEndingPopupTimeout}
                text={t("popup_message_check_model_redirect")}
                checkpoints={[
                    {
                        time: 0,
                        callback: () => setRoundEndingPopupOpen(false),
                    },
                ]}
            />
        </>
    );
}

/**
 * @param {import("react").ComponentType} Component
 * @param {string} className
 * @param {string} headerTitleKey
 */
export function withPlayer(Component, className, headerTitleKey) {
    return () => {
        const { t } = useTranslation();
        <Play className={className} headerTitle={t(headerTitleKey)}>
            <Component />
        </Play>;
    };
}

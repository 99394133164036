import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";


const CustomTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#F36F2B",
            borderBottomColor: "#F36F2B",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#F36F2B",
        },
        "& .Mui-focused": {
            // color: "#F36F2B",
            borderBottomColor: "#F36F2B",
        },

        "& .MuiInput-underline:before": {
            //borderBottomColor: "#F36F2B",
            borderBottomWidth: "2px",
            borderBottomStyle: "solid",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomColor: "#F36F2B",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#F36F2B",
            },
            "&:hover fieldset": {
                borderColor: "#sF36F2B",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#F36F2B",
                borderWidth: "0px",
                borderRadius: "0px",
                border: "none",
            },
        },
    },
})(TextField);

export default CustomTextField;

import { Rings } from "react-loader-spinner";

const Loading = ({ size = 100 }) => (
    <Rings
        // in SVG currentColor means to use the color of the parent HTML element's CSS color
        color="currentColor"
        height={size}
        width={size}
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible
        ariaLabel="rings-loading"
    />
);

export default Loading;

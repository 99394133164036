import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

import NavbarProfileLinks from "./NavbarProfileLinks";

import styles from "@/assets/jss/ibuild-admin-react/components/sidebarStyle";

import { rootPathAdmin } from "@/utils/constants";
import { useSidebarOpen } from "@/utils/hooks";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const { rtlActive, color, logo, image, logoText, routes } = props;

    const location = useLocation();
    const classes = useStyles();

    const [sidebarOpen, toggleSidebarOpen] = useSidebarOpen();

    // check if routeName is the active one
    const activeRoute = (to) => location.pathname === to;

    const links = (
        <List className={classes.list}>
            {routes.map(({ slug, name, icon: IconComp }) => {
                const to = slug;
                const listItemClasses = classNames({
                    [` ${classes[color]}`]: activeRoute(to),
                });
                const whiteFontClasses = classNames({
                    [` ${classes.whiteFont}`]: activeRoute(to),
                });
                return (
                    <NavLink to={to} className={classes.item} key={slug}>
                        <ListItem button className={classes.itemLink + listItemClasses}>
                            {typeof IconComp === "string" ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: rtlActive,
                                    })}
                                >
                                    {IconComp}
                                </Icon>
                            ) : (
                                <IconComp
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: rtlActive,
                                    })}
                                />
                            )}
                            <ListItemText
                                primary={name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: rtlActive,
                                })}
                                disableTypography
                            />
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    const brand = (
        <div className={classes.logo}>
            <a
                href={rootPathAdmin}
                className={classNames(classes.logoLink, {
                    [classes.logoLinkRTL]: rtlActive,
                })}
            >
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
                {logoText}
            </a>
        </div>
    );

    return (
        <div>
            <Hidden mdUp implementation="css">
                <Drawer
                    variant="temporary"
                    anchor={rtlActive ? "left" : "right"}
                    open={sidebarOpen}
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: rtlActive,
                        }),
                    }}
                    onClose={() => toggleSidebarOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {brand}(
                    <div className={classes.sidebarWrapper}>
                        <NavbarProfileLinks />
                        {links}
                    </div>
                    )
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    anchor={rtlActive ? "right" : "left"}
                    variant="permanent"
                    open
                    classes={{
                        paper: classNames(classes.drawerPaper, {
                            [classes.drawerPaperRTL]: rtlActive,
                        }),
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{ backgroundImage: `url(${image})` }}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
};

import Dashboard from "@mui/icons-material/Dashboard";
import Store from "@mui/icons-material/Store";
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import BubbleChart from "@mui/icons-material/BubbleChart";
// import Settings from "@mui/icons-material/Settings";

import Signin from "./screens/auth/Signin";
import Register from "./screens/auth/Register";

import DashboardPage from "./screens/Dashboard";

import GameModels from "./screens/game-models/GameModels";
import AddEditGameModel from "./screens/game-models/AddEditGameModel";

import BonusTasks from "./screens/bonus-tasks/BonusTasks";
import AddEditBonusTask from "./screens/bonus-tasks/AddEditBonusTask";

import Games from "./screens/games/Games";
import AddEditGame from "./screens/games/AddEditGame";

import Languages from "./screens/languages/Languages";
import AddEditLanguage from "./screens/languages/AddEditLanguage";

import GamesPhotos from "./screens/photos/GamesPhotos";
import ViewGamePhotos from "./screens/photos/ViewGamePhotos";

import {
    page_admin_signin,
    page_admin_register,
    page_admin_dashboard,
    page_admin_game_models,
    page_admin_game_models_add,
    page_admin_game_models_edit,
    page_admin_games,
    page_admin_games_add,
    page_admin_games_edit,
    page_admin_bonus_tasks,
    page_admin_bonus_tasks_add,
    page_admin_bonus_tasks_edit,
    page_admin_languages,
    page_admin_languages_add,
    page_admin_languages_edit,
    page_admin_photo_management,
    page_admin_photo_management_view,
} from "@/utils/constants";

const routes = [
    {
        slug: page_admin_dashboard,
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        isSidebar: true,
    },

    {
        slug: page_admin_game_models_add,
        name: "Add Game Models",
        icon: BubbleChart,
        component: AddEditGameModel,
    },
    {
        slug: page_admin_game_models_edit,
        name: "Edit Game Models",
        icon: BubbleChart,
        component: AddEditGameModel,
    },
    {
        slug: page_admin_game_models,
        name: "Game Models",
        icon: BubbleChart,
        component: GameModels,
        isSidebar: true,
    },
    {
        slug: page_admin_bonus_tasks_add,
        name: "Add Game Models",
        icon: LibraryBooks,
        component: AddEditBonusTask,
    },
    {
        slug: page_admin_bonus_tasks_edit,
        name: "Edit Game Models",
        icon: LibraryBooks,
        component: AddEditBonusTask,
    },
    {
        slug: page_admin_bonus_tasks,
        name: "Bonus Tasks",
        icon: LibraryBooks,
        component: BonusTasks,
        isSidebar: true,
    },

    {
        slug: page_admin_games_add,
        name: "Add Games",
        icon: Store,
        component: AddEditGame,
    },
    {
        slug: page_admin_games_edit,
        name: "Edit Games",
        icon: Store,
        component: AddEditGame,
    },
    {
        slug: page_admin_games,
        name: "Games",
        icon: Store,
        component: Games,
        isSidebar: true,
    },

    {
        slug: page_admin_photo_management_view,
        name: "View Game Photos",
        icon: Store,
        component: ViewGamePhotos,
    },
    {
        slug: page_admin_photo_management,
        name: "Photo Management",
        icon: Store,
        component: GamesPhotos,
        isSidebar: true,
    },

    {
        slug: page_admin_languages_add,
        name: "Add Language",
        icon: LibraryBooks,
        component: AddEditLanguage,
    },
    {
        slug: page_admin_languages_edit,
        name: "Edit Language",
        icon: LibraryBooks,
        component: AddEditLanguage,
    },
    {
        slug: page_admin_languages,
        name: "Languages",
        icon: LibraryBooks,
        component: Languages,
        isSidebar: true,
    },
    // TODO: temporary disable "Create/register admins"
    // {
    //     slug: page_admin_register,
    //     name: "Register",
    //     icon: LibraryBooks,
    //     component: Register,
    //     isNotAuth: true,
    // },
    {
        slug: page_admin_signin,
        name: "Signin",
        icon: LibraryBooks,
        component: Signin,
        isNotAuth: true,
    },
];

export default routes;

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} Round
 * @property {string} id
 * @property {number} order  (the round order - 1, 2, ...)
 * @property {number} time  (the round duration)
 * @property {string} game   (Game ID)
 */

/**
 * @typedef {object} RoundDataForEdit
 * @property {string} id
 * @property {number} time
 */

/**
 * Query for a Rounds in a game.
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Round[]>}
 */
export function useRounds(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "Rounds", gameId],
        queryFn: () => getRounds(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Edit a Round (actually just change its duration time)
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, RoundDataForEdit, unknown>}
 */
export function useRoundEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editRound,
        onSuccess: (round) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "Rounds", round.game], (oldRounds) => {
                if (!oldRounds) return [];

                return oldRounds.map((oldRound) => {
                    if (oldRound.id === round.id) return round;
                    return oldRound;
                });
            });
        },
        meta: { entity: "Round", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get Rounds function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Round[]>}
 */
const getRounds = async (gameId) => {
    const { data: roundsData } = await adminApi.get(`/game_round_times/byGame/${gameId}`);

    return roundsData.map(parseRound);
};

/**
 * Parse server data
 * @param {any} roundData
 * @return {Round}
 */
const parseRound = (roundData) => {
    roundData = fixId(roundData);

    // just some renames
    roundData.time = roundData.roundTime;
    delete roundData.roundTime;
    roundData.order = roundData.round;
    delete roundData.round;

    return roundData;
};

/**
 * Edit/Update a Round function to be used by useQuery
 * @param {RoundDataForEdit} inRoundData
 * @return {Promise<Round>}
 */
const editRound = async (inRoundData) => {
    // update the round time
    const { data: roundData } = await adminApi.put(`/game_round_times/${inRoundData.id}`, {
        roundTime: inRoundData.time,
    });
    return parseRound(roundData);
};

import { Trans, useTranslation } from "react-i18next";

import { useQuote } from "@/layouts/player/cache/quotes";
import { usePlayer } from "@/layouts/player/realtime";

export default function Congrats() {
    const { t } = useTranslation();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const { data: quote } = useQuote(player.quote);

    const nameAsText = `<label className="name-orange">${player.nickname}</label>`;

    return (
        <div className="task-content">
            <div>
                <p className="congrats-title">{t("congrats")}</p>
                <p className="role-description-text">
                    <Trans
                        i18nKey="congrats_well_done"
                        values={{ name: nameAsText }}
                        components={{ label: <label /> }}
                    />
                </p>
                <p className="role-description-text">{t("congrats_text")}</p>
                <p className="quote">"{quote?.quote || ""}"</p>
                <p className="role-words">{t("congrats_player_action")}</p>
            </div>
        </div>
    );
}

import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { usePlayAudio } from "@/utils/hooks";

import BonusTasks from "@/layouts/player/components/BonusTasks";
import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import { useGame, GameState } from "@/layouts/player/cache/game";
import { joinPlayer, usePlayer } from "@/layouts/player/realtime";

export default function JoinPlayer() {
    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    const { data: player } = usePlayer();

    const { state } = game;

    return (
        <div className="task-content">
            {/* eslint-disable-next-line no-nested-ternary */}
            {state === GameState.Ended ? (
                // if game is ended show just the message
                <Status state={GameState.Ended} />
            ) : player ? (
                // use playerId is selected show a "Lobby" until the game is started,
                // where he can do the bonus tasks
                <WaitingLobby nickname={player.nickname} />
            ) : (
                <EnterNickName game={game} />
            )}
        </div>
    );
}

/**
 * @param {{state: import("../cache/game").GameStateValue; children?: import("react").ReactNode}} props
 */
function Status({ children, state }) {
    const { t } = useTranslation();
    return (
        <div className="screenInfo">
            <p className="lobby-text">{children}</p>
            <p className="lobby-sub-text">{t(`game_status_${state}`)}</p>
        </div>
    );
}

/**
 * @param {{game: import("../cache/game").Game}} props
 */
function EnterNickName({ game }) {
    const { t } = useTranslation();
    const { playAudio } = usePlayAudio();
    const [nickname, setNickname] = useState("");

    const submitHandler = (e) => {
        e.preventDefault();
        playAudio();
        joinPlayer(game.id, nickname);
    };
    return (
        <>
            <div className="screenInfo">
                <p className="welcome-text">{t("welcome_text_1")}</p>
                <p className="welcome-sub-text">{t("welcome_text_2")}</p>
            </div>
            <form onSubmit={submitHandler}>
                <input
                    autoFocus
                    type="text"
                    placeholder={t("enter_nickname") || undefined}
                    value={nickname}
                    onChange={(event) => setNickname(event.target.value)}
                    className="input-field"
                />

                <IgniteButton disabled={!nickname} />
            </form>
        </>
    );
}

/**
 * @param {{nickname: string}} props
 */
function WaitingLobby({ nickname }) {
    return (
        <>
            <Status state={GameState.Pending}>
                <Trans
                    i18nKey="game_rules_welcome_text"
                    values={{ name: nickname }}
                    components={{ label: <label /> }}
                />
            </Status>

            <BonusTasks />
        </>
    );
}

import { useTranslation } from "react-i18next";
import MenuIcon from "@mui/icons-material/Menu";

import { useSidebarOpen } from "@/utils/hooks";

/**
 * @param {{
 * title: string,
 * subtitle: Partial<{
 * gameName: string,
 * teamCode: string,
 * nickname: string,
 * roundOrder: number,
 * }>,
 * timer?: import("react").ReactNode,
 * noSidebar?: boolean
 * }} props
 */
export default function Header({ title, timer, subtitle = {}, noSidebar = false }) {
    const { t } = useTranslation();

    const [_, toggleSidebarOpen] = useSidebarOpen();

    const subtitleInfo = [];
    if (subtitle.gameName) subtitleInfo.push(t("game", { name: subtitle.gameName }));
    if (subtitle.teamCode) subtitleInfo.push(t("team", { code: subtitle.teamCode }));
    if (subtitle.nickname) subtitleInfo.push(t("player", { name: subtitle.nickname }));
    if (subtitle.roundOrder) subtitleInfo.push(t("round", { round: subtitle.roundOrder }));

    return (
        <div className="header">
            <div className="left">
                {!noSidebar && (
                    <button onClick={() => toggleSidebarOpen()} type="button">
                        <MenuIcon />
                    </button>
                )}
            </div>

            <div className="center">
                <p className="title">{title}</p>
                <p className="subtitle">{subtitleInfo.join(" - ")}</p>
            </div>

            <div className="timer">{timer}</div>
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import { fixId, noop } from "@/utils/utils";

export const GameState = /** @type {const} */ ({
    Pending: "pending",
    Started: "started",
    Ended: "ended",
});

/**
 * @typedef {typeof GameState[keyof GameState]} GameStateValue
 */
/**
 * @typedef {object} Game
 * @property {string} id
 * @property {string} name
 * @property {string} model
 * @property {GameStateValue} state
 * @property {string} [client]
 * @property {number} [dateStarted]
 * @property {number} [dateEnded]
 * @property {[]} [bonusTasks]
 * @property {[]} [languages]
 */

/**
 * Query for the Game.
 * @param {string} [gameId]
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Game>}
 */
export function useGame(gameId) {
    const isAllowed = !!gameId;
    const result = useQuery({
        // NOTE: cache with a static "game" key,
        // if not passed gameId it be be disabled, but will be possible to get the cached data
        queryKey: ["player", "Game" /* , gameId */],
        queryFn: () => getGame(gameId),
        enabled: isAllowed,
    });
    return {
        ...result,
        refetch: isAllowed ? result.refetch : noop,
    };
}

/**
 * Get Game function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Game>}
 */
const getGame = async (gameId) => {
    let { data: gameData } = await playerApi.get(`/games/${gameId}`);
    if (gameData) {
        gameData = fixId(gameData);

        gameData.dateStarted = gameData.date_started;
        delete gameData.date_started;

        gameData.dateEnded = gameData.date_ended;
        delete gameData.date_ended;

        gameData.state = parseGameState(gameData.dateStarted, gameData.dateEnded);
    }
    return gameData;
};

/**
 * @param {number} [dateStarted]
 * @param {number} [dateEnded]
 * @return {GameStateValue}
 */
const parseGameState = (dateStarted, dateEnded) => {
    if (dateStarted) {
        if (dateEnded) return GameState.Ended;
        return GameState.Started;
    }
    return GameState.Pending;
};

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const IOSSwitch = (props) => (
    <Switch
        disableRipple
        sx={(theme) => ({
            width: "42px",
            height: "26px",
            padding: "0",
            margin: theme.spacing(1),
            "& .MuiSwitch-track": {
                borderRadius: `${26 / 2}px`,
                border: `1px solid ${theme.palette.grey[400]}`,
                backgroundColor: "#D6D6D6", // theme.palette.grey[50],
                opacity: 1,
                transition: theme.transitions.create(["background-color", "border"]),
            },
            "& .MuiSwitch-switchBase": {
                padding: "1px",
                "&.Mui-checked": {
                    transform: "translateX(16px)",
                    color: theme.palette.common.white,
                    "& + .MuiSwitch-track": {
                        backgroundColor: "#F36F2B",
                        opacity: 1,
                        border: "none",
                    },
                },
                "&.Mui-focusVisible .MuiSwitch-thumb": {
                    color: "#F36F2B",
                    border: "6px solid #fff",
                },
            },
            "& .MuiSwitch-thumb": {
                width: "24px",
                height: "24px",
            },
        })}
        {...props}
    />
);

function ToggleSwitch({ value, onChange }) {
    const handleChange = (event) => {
        if (value !== event.target.checked) onChange(event.target.checked);
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={<IOSSwitch checked={value} onChange={handleChange} name="checkedB" />}
                label=""
            />
        </FormGroup>
    );
}

export default ToggleSwitch;

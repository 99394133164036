import { useEffect, useState } from "react";
import { useEffectOnce, useSetState } from "react-use";

// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import Button from "../../../../../components/Button/Button";

import "./styles.css";

import { useGameModelRoundDelete, useGameModelRoundEdit } from "../../../cache/gameModelRounds";
import { useGameModelRoundImage } from "../../../cache/gameModelRoundImages";
import { isString } from "@/utils/utils";

/**
 *
 * @param { {
 * roundId: string;
 * id: string;
 * label:string;
 * imageId?: string;
 * disableEditing: boolean;
 * onChange: (id: string, file: File) => void }} props
 */
function RoundImage({ roundId, label, id, disableEditing, imageId, onChange }) {
    const [imageName, setImageName] = useState("");
    const [imageSrc, setImageSrc] = useState("");

    // this is current server's image data
    const { data: imageDataSrc } = useGameModelRoundImage(imageId);

    const handleInputImage = (event) => {
        const /* File */ file = event.target.files?.[0];

        if (!file) return;

        onChange(id, file);

        // set file's name
        setImageName(file.name);

        // display image (convert to base64 string)
        const reader = new FileReader();
        reader.onload = (e) => setImageSrc(e.target.result);
        reader.readAsDataURL(file);
    };

    const inputId = `${roundId}_${id}`;
    return (
        <>
            <div className="image-upload-label">
                <label htmlFor="imageArch">{label}</label>
            </div>

            <div className="image-upload-preview">
                <img src={imageSrc || imageDataSrc} alt="" width="200px" />
            </div>

            <div className={`image-upload-button ${disableEditing && "hidden"}`}>
                <label htmlFor={inputId} className="custom-file-upload">
                    <i className="fa fa-cloud-upload" /> Upload
                </label>
                <br />
                <label>{imageName}</label>
                <input
                    type="file"
                    id={inputId}
                    onChange={handleInputImage}
                    accept=".jpg,.png,.jpeg"
                />
            </div>
        </>
    );
}

export default function Round({
    round: gameModelRound,
    model: modelId,
    disableEditing,
    isLatestRound,
}) {
    const gameModelRoundId = gameModelRound.id;

    const deleteGameModelRound = useGameModelRoundDelete();
    const editGameModelRound = useGameModelRoundEdit();

    // this is the local dynamic state - just specify the data types
    const [gameModelRoundLocalState, setGameModelRoundLocalState] = useSetState({
        roundTime: 0,
        numFormulas: 0,
        //  for instance 2 images but named 'imgFormula1' and and 'imgFormula3'.
        //  e.g. try not to use any "names"
        /**
         * contains all current formulas images as well any newly/local uploaded File - for formula images and for imgArch, imgPyro, imgFinal
         * @type {Record<string, string|File>}
         */
        images: {},
    });

    // update local data according to the prop
    useEffect(() => {
        if (gameModelRound) {
            // the role images (imgArch, imgPyro, imgFinal) are optional when sent to server
            // and thus merged with current in DB
            // BUT the formulas images (imgFormulaXXX) are replaced so the current ones should be retained

            const images = {};
            gameModelRound.formulas?.forEach((formula) => {
                // keep the current formula-image
                images[formula.id] = formula.image;
            });
            setGameModelRoundLocalState({
                roundTime: gameModelRound.roundTime,
                numFormulas: gameModelRound.formulas?.length || 0,
                images,
            });
        }
    }, [gameModelRound]);

    useEffectOnce(() => {});

    const handleSubmit = async (e) => {
        e.preventDefault();

        // maybe the formula images are to be trimmed
        const images = {};
        for (const [name, blobOrImageId] of Object.entries(gameModelRoundLocalState.images)) {
            if (name.startsWith("imgFormula")) {
                const num = Number.parseInt(name.replace("imgFormula", ""));
                if (num > gameModelRoundLocalState.numFormulas) continue;
            }
            images[name] = blobOrImageId;
        }
        // edit game-model-round
        await editGameModelRound({
            id: gameModelRoundId,
            model: modelId,
            roundTime: gameModelRoundLocalState.roundTime,
            images,
        });
    };

    /**
     *
     * @param {string} imageId
     * @param {File} imageData
     */
    const handleImageChange = (imageId, imageData) => {
        // store selected image data
        setGameModelRoundLocalState({
            images: {
                ...gameModelRoundLocalState.images,
                [imageId]: imageData,
            },
        });
    };

    return (
        <div className="round">
            <ValidatorForm onSubmit={handleSubmit}>
                <div>
                    <ul className="rounds-list">
                        <li>
                            <RoundImage
                                roundId={gameModelRoundId}
                                id="imgArch"
                                label="Architect image"
                                disableEditing={disableEditing}
                                imageId={gameModelRound.imgArch}
                                onChange={handleImageChange}
                            />
                        </li>
                        <li>
                            <RoundImage
                                roundId={gameModelRoundId}
                                id="imgPyro"
                                label="Pyrotechnician image"
                                disableEditing={disableEditing}
                                imageId={gameModelRound.imgPyro}
                                onChange={handleImageChange}
                            />
                        </li>
                        <li>
                            <RoundImage
                                roundId={gameModelRoundId}
                                id="imgFinal"
                                label="Final image"
                                disableEditing={disableEditing}
                                imageId={gameModelRound.imgFinal}
                                onChange={handleImageChange}
                            />
                        </li>
                    </ul>
                </div>
                <div>
                    <TextValidator
                        type="number"
                        id={`numFormulas${gameModelRoundId}`}
                        label="Number of formulas *"
                        onChange={(event) =>
                            setGameModelRoundLocalState({ numFormulas: event.target.valueAsNumber })
                        }
                        name="numFormulas"
                        value={gameModelRoundLocalState.numFormulas}
                        validators={["required", "minNumber:1", "maxNumber:10"]}
                        errorMessages={[
                            "This field is required",
                            "Minimum 1 formula allowed",
                            "Maximum 10 formulas allowed",
                        ]}
                        disabled={disableEditing}
                    />
                </div>

                <div className="formula-upload">
                    {Array.from({ length: gameModelRoundLocalState.numFormulas }).map((_, i) => {
                        const formulaId = `imgFormula${i + 1}`;
                        const formulaImageId = gameModelRoundLocalState.images[formulaId];
                        return (
                            <div key={i}>
                                <RoundImage
                                    roundId={gameModelRoundId}
                                    id={formulaId}
                                    label={`Formula ${i + 1}`}
                                    disableEditing={disableEditing}
                                    imageId={isString(formulaImageId) ? formulaImageId : undefined}
                                    onChange={handleImageChange}
                                />
                            </div>
                        );
                    })}
                </div>

                <TextValidator
                    type="number"
                    label="Round Time *"
                    onChange={(event) =>
                        setGameModelRoundLocalState({
                            roundTime: event.target.valueAsNumber * 60 * 1000,
                        })
                    }
                    name="roundTime"
                    value={gameModelRoundLocalState.roundTime / 60 / 1000 || ""}
                    validators={["required", "minNumber:1", "maxNumber:59"]}
                    errorMessages={[
                        "This field is required",
                        "Minimum 1 minute",
                        "Maximum 59 minutes",
                    ]}
                    disabled={disableEditing}
                />
                <br />
                {!disableEditing && (
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                )}
                {isLatestRound && !disableEditing && (
                    <Button
                        type="button"
                        color="primary"
                        onClick={() =>
                            deleteGameModelRound({ id: gameModelRoundId, model: modelId })
                        }
                    >
                        Delete Round
                    </Button>
                )}
            </ValidatorForm>
        </div>
    );
}

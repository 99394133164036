import UploadPhoto from "@/layouts/player/components/UploadPhoto";
import { useUploadTeamPhoto } from "../cache/teamPhoto";

export default function BuilderUploadPhoto(props) {
    const {
        gameId,
        teamId,
        teamCode,
        playerId,
        nickname,
        roundId,
        roundOrder,
        onSubmitted,
        renderExtra,
    } = props;

    const uploadTeamPhoto = useUploadTeamPhoto();

    /**
     * @param {string} imageDataUri
     */
    const onSubmit = (imageDataUri) => {
        // upload the image and add record to team_photos
        uploadTeamPhoto({
            gameId,
            teamId,
            teamCode,
            playerId,
            nickname,
            roundId,
            roundOrder,
            imageDataUri,
        });

        // notify parent (in normal flow Builder3 go to the next page, otherwise could just show a message)
        onSubmitted?.();
    };

    return <UploadPhoto onSubmit={onSubmit} renderExtra={renderExtra} />;
}

import { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";
// import { useRerender } from "use-is-rerender";

import background from "@/assets/img/player/background.svg";
import { page_player_join, rootPathPlayer } from "@/utils/constants";
import { log } from "@/logger";

import { subscribeGame, useGameRouting, usePlayerValidating } from "@/layouts/player/realtime";
import { routes, routesExt, createRoutePathForGame } from "@/layouts/player/routes";
import { useGame } from "@/layouts/player/cache/game";
import Loading from "@/layouts/player/components/Loading";
import Play from "@/layouts/player/components/Play";

export { rootPathPlayer };

const rootPathPlayerForGame = createRoutePathForGame(rootPathPlayer);

export default function PlayerWrapper() {
    return (
        <Switch>
            {/* first route "external" routes */}
            {routesExt.map(({ slug, component: Component }) => (
                <Route
                    key={slug}
                    path={slug}
                    render={(routerProps) => <Component {...routerProps} />}
                />
            ))}

            {/* next route a generic/combine one */}
            <Route path={rootPathPlayerForGame} component={PlayerForGame} />

            <Route path="*" render={() => <>No Game to play</>} />
        </Switch>
    );
}

/**
 * @param {{location: import("history").Location; match: import("react-router-dom").match}} props
 */
function PlayerForGame({ location, match }) {
    // useful for debugging
    // useRerender();

    // dummy CSS animation - not what I want, but good enough
    // even simpler version of https://dev.to/anxiny/react-router-animated-transitions-diy-4opd
    const [transitionStage, setTransitionStage] = useState("fadeIn");
    useEffect(() => {
        log(`Location: ${location.pathname}`);

        setTransitionStage("fadeOut");
    }, [location.pathname]);

    /**
     * @type {string}
     */
    const gameId = match.params.gameId;

    // get the game from server
    const { data: game, error } = useGame(gameId);

    return (
        <div
            className={`playerAnimationWrap ${transitionStage}`}
            onAnimationEnd={() => {
                if (transitionStage === "fadeOut") setTransitionStage("fadeIn");
            }}
        >
            <div
                className="page-player background-with-color"
                style={{
                    backgroundImage: `url(${background})`,
                }}
            >
                {game ? <RouterGame /> : error ? <ErrorGame gameId={gameId} /> : <LoadingGame />}
            </div>
        </div>
    );
}

function LoadingGame() {
    return (
        <div className="page-player-loading">
            <Loading size={200} />
        </div>
    );
}

/**
 * @param {{gameId: string}} props
 */
function ErrorGame({ gameId }) {
    return <div className="page-player-loading">{`Invalid game ${gameId}`}</div>;
}

function RouterGame() {
    const { t } = useTranslation();

    /**
     * @type {{data: import("./cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    // subscribe to realtime events event
    useEffectOnce(() => subscribeGame(game.id));

    // hook that is checking if there's currently logged user and if yes it tries validate it
    const playerValidating = usePlayerValidating();
    const gameLoading = useGameRouting();
    if (playerValidating || gameLoading) return <LoadingGame />;

    return (
        <Switch>
            {routes.map(
                ({ slug, component: Component, className, headerTitle, hideLogoTop, hideLogo }) => (
                    <Route
                        key={slug}
                        path={slug}
                        render={() => (
                            <Play
                                className={className}
                                headerTitle={t(headerTitle)}
                                hideLogoTop={hideLogoTop}
                                hideLogo={hideLogo}
                            >
                                <Component />
                            </Play>
                        )}
                    />
                ),
            )}

            {/* Fallback - go to start player page */}
            <Route
                path="*"
                render={() => <Redirect to={createRoutePathForGame(page_player_join, game.id)} />}
            />
        </Switch>
    );
}

// NOTE: Could merge the Game and RunningGame into a single cache-query
// NOTE: On each change of the page component (the location.pathname),
//       the Play is a different component. So in order the RoundTimer to show always the correct
//       remaining time and not always reset to latest runningGame.roundTime, request the latest status (e.g. runningGame)
//       This is most safe way and works as a "sync" poll from time to time.
//          Implemented in useGameRouting() that calls refetchRunningGame() after location.pathname changes.
//       Other possible solution is to introduce a new local field runningGame.roundTimeChangeAt
//       so that when pages are changed to show calculated remaining time based on
//       now, runningGame.roundTimeChangeAt and runningGame.roundTime. But the other is more "safe", even though
//       it's less "server-loading".
//       Actually best is to implement both solutions, and only refetch is used, there's a period of time
//       in which the latest runningGame.roundTime will be used again on a page changed, and it will
//       be visible until "fixed" after the gameStatus event comes which is async, not immediately

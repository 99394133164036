const language = {
    arch_player_action: "Please switch off your video.",
    arch_task_player_action: "Describe the Model to your Builders.",
    arch_text_1:
        "As an Architect you are responsible to give precise information about the model to your Builders.",
    arch_text_2: "Only verbal communication is allowed.",
    arch_title: "Switch off camera",
    arch_warning_text_1: "Remember to recreate the Model yourself.",
    arch_warning_text_2: "Wait for the Flashmaster to return with information about the igniters.",
    arch_warning_text_3: "When you TEAM is ready press ignite to continue.",
    bonus_tasks_info: "Connect with your teammates by sharing something personal.",
    bonus_tasks_player_action: "Select the tasks when done and submit.",
    bonus_tasks_submit_button: "Submit",
    bonus_tasks_done: "Well Done!",
    bonus_tasks_title: "Bonus tasks",
    builder_check_text_1:
        "Check the original model bellow and make changes to your replica (if needed).",
    builder_check_text_2:
        "Architect, Flashmaster and Builders all need to have the figure before proceeding to the next stage.",
    builder_check_title: "Check",
    builder_check_warning: "Decide how to rotate roles in your team for the next round.",
    builder_submit_button: "Take photo",
    builder_task_text_1: "The Architect will explain you the position of the matchsticks.",
    builder_task_text_2: "Wait for the Flashmaster to tell you the position of the igniters.",
    builder_task_text_3: "When you complete both steps press ignite to continue.",
    builder_text_1: "Take a CLOSE-UP photo of the matchstick model you have built.",
    builder_text_2: "Make sure you know the position of the igniters, too.",
    builder_title: "Take photo",
    choose_a_role_screen_player_action: "Select your role for the {{current_round}} round.",
    choose_a_role_screen_title: "Choose a role",
    congrats: "Congrats",
    congrats_player_action: "Join the main meeting room.",
    congrats_text: "You and your team have successfully built the design.",
    congrats_title: "Congratulations",
    congrats_well_done: "Well done, <label>{{name}}</label>!",
    enjoy: "Enjoy:",
    game: "Game: {{name}}",
    game_status_started: "Game is started now. You can get in!",
    game_status_pending: "You will be redirected when admin starts the game!",
    game_status_ended: "Game was finished!",
    enter_nickname: "Enter your Nick name to join the project",
    enter_teamcode: "Enter Team Code",
    game_rules_how: "You and your teammates will take turns in 3 roles for each new Round.",
    game_rules_project: "Your Team goal is to recreate a Model by using simple matchsticks.",
    game_rules_welcome_text:
        "Congratulations, <label>{{name}}</label> ! You are an important part of a magnificent building team.",
    game_rules_what: "The Model reveals from top to bottom in several Rounds.",
    how: "How:",
    lobby1_waiting_room_back_button: "Change role",
    lobby1_waiting_room_info:
        "Use the opportunity to get to know your teammates and check out the Bonus tasks.",
    lobby1_waiting_room_player_action: "Please be patient until all players join.",
    lobby1_waiting_room_you_choose:
        "You choose <label>{{current_role}}</label> role for this round",
    lobby2_waiting_room_back_button: "Go back",
    lobby2_waiting_room_info: "OR Keep igniting And do Bonus tasks!",
    lobby2_waiting_room_player_action: "Let's be patient until all players complete the Model.",
    lobby2_waiting_room_you_choose: "You can still go back and improve your work.",
    ok: "OK",
    player: "Player: {{name}}",
    popup_message_20_seconds_to_choose_a_role: "You have only 20 seconds left to choose a role.",
    popup_message_automatically_choose_a_role:
        "The system is choosing a role for you and will redirect you to the task.",
    popup_message_check_model_redirect: "You are being redirected to the right model.",
    popup_message_role_removed_by_admin: "Your role has been removed by the admin.",
    popup_message_role_set_by_admin: "Your role was set to {{role}} by the admin.",
    popup_message_sorry_invalid_team: "Sorry, but the team code you enter is not valid.",
    popup_message_sorry_team_full:
        "Sorry, but this team has reached its maximum number of players.",
    popup_message_time_is_up:
        "Times up. Please go back to your team room and pass the information of your Architect and Builders.",
    popup_button_got_it: "Got it",
    project: "Project:",
    pyro_player_action: "Please go back to the Main meeting room to meet them.",
    pyro_task_player_action_1: "All Flashmasters access the same Model",
    pyro_task_player_action_2: " ...but only part of the code.",
    pyro_task_text:
        "When all Flashmasters are ready head back to your team room and pass the information to your Architect and Builders.",
    pyro_text_1: "As a Flashmaster you are responsible to discover the position of the igniters.",
    pyro_text_2:
        "Collaborate with your Flashmaster colleagues from other teams to solve the challenge.",
    pyro_title: "Go to the main room",
    role_architect: "Architect",
    role_architect_description: "1 team member Describes the model",
    role_builder: "Builder",
    role_builder_description:
        "Combine info from Architect and Flashmaster and Recreate the original Model",
    role_pyro: "Flashmaster",
    role_pyro_description: "1 team member Discovers the igniters",
    role_task_title: "Task",
    round: "Round {{round}}",
    sidebar_language: "Language",
    sidebar_sounds: "Sounds",
    sidebar_team_members: "Team members/Current role",
    submit_photo_title: "Submit photo",
    submit_photo_switch_camera_button: "Switch Camera",
    submit_photo_take_photo_button: "Take Photo",
    submit_photo_take_new_photo_button: "Take New Photo",
    submit_photo_submit_button: "Submit",
    submit_photo_scan_qrcode_button: "Use mobile",
    submitted_photo_message: "Photo is submitted",
    team: "Team: {{code}}",
    teamcode: "Team code",
    teamcode_screen_title: "Let's start",
    waiting_room_submit_button: "Check the Bonus tasks",
    waiting_room_title: "Please wait",
    welcome: "Welcome",
    welcome_text_1: "Welcome to ibuild Remote Collaborative team challenge.",
    welcome_text_2:
        "Use your imagination, creativity and team power to build solid communication bridges.",
    what: "What:",
};

export default language;

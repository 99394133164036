import { useQuery } from "@tanstack/react-query";

import { error } from "@/logger";
import playerApi from "@/service/playerApi";
import { getImageForImageData, noop } from "@/utils/utils";

import { useGame } from "./game";

/**
 * @typedef {object} RoundImages
 * @property {number} roundOrder
 * @property {string} imgArchSrc
 * @property {string} imgPyroSrc
 * @property {string} imgFinalSrc
 * @property {string} imgPyroFormulaSrc
 */

/**
 * Query for the RoundImages for a team and round.
 * @param {string} teamId
 * @param {number} roundOrder
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|RoundImages>}
 */
export function useRoundImages(teamId, roundOrder) {
    const { data: game } = useGame();

    const isAllowed = !!game;
    return useQuery({
        queryKey: ["player", "RoundImages", roundOrder],
        queryFn: () => getRoundImages(game.model, teamId, roundOrder),
        enabled: isAllowed,
    });
}

/**
 * Get RoundImages function to be used by useQuery
 * @param {string} gameModelId
 * @param {string} teamId
 * @param {number} roundOrder
 * @return {Promise<RoundImages>}
 */
const getRoundImages = async (gameModelId, teamId, roundOrder) => {
    /**
     * @type {RoundImages}
     */
    const roundImages = { roundOrder };

    const { data: gameModelRoundData } = await playerApi.get(
        `/game_model_rounds/byGameModelAndRound/${gameModelId}/${roundOrder}`,
    );

    const { data: teamRoundFormulaData } = await playerApi.get(
        `/team_round_formulas/byTeamAndRound/${teamId}/${roundOrder}`,
    );

    await Promise.all([
        attachImage(roundImages, "imgArchSrc", gameModelRoundData.imgArch),
        attachImage(roundImages, "imgPyroSrc", gameModelRoundData.imgPyro),
        attachImage(roundImages, "imgFinalSrc", gameModelRoundData.imgFinal),
        attachImage(roundImages, "imgPyroFormulaSrc", teamRoundFormulaData.formula),
    ]).then();

    return roundImages;
};

/**
 * Attach fetched image to the round images object-dictionary
 * @param {RoundImages} roundImages
 * @param {Exclude<keyof RoundImages, "roundOrder">} imageKey
 * @param {string} imageId
 * @return {Promise<void>}
 */
const attachImage = async (roundImages, imageKey, imageId) => {
    if (!imageId) {
        error(`No round image for round ${roundImages.roundOrder} and key ${imageKey}`);
        return;
    }

    try {
        const { data: imageData } = await playerApi.get(`/images/${imageId}`);

        const imageSrc = getImageForImageData(imageData?.img);

        roundImages[imageKey] = imageSrc;
    } catch {
        error(`Missing round image for round ${roundImages.roundOrder} and key ${imageKey}`);
    }
};

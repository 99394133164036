import { useEffect } from "react";
import { useSetState } from "react-use";

import { makeStyles } from "@mui/styles";
import SaveIcon from "@mui/icons-material/Save";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { getImageForImageData } from "@/utils/utils";
import { page_admin_languages } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import CustomTextField from "../../../../components/CustomMaterialUIControls/CustomTextField";

import { useLanguage, useLanguageAdd, useLanguageEdit } from "../../cache/languages";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#F36F2B",
        fontWeight: "bold",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    imageUploadContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    imageUploadPreview: {
        width: "50px",
        height: "50px",
        margin: "20px",
        border: "1px solid #ccc",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    imageUploadLabel: {
        fontSize: "14px",
        color: "#aaaaaa",
    },
    imageUploadButton: {},
    customFileUpload: {
        display: "inline-block",
        padding: "6px 12px",
        cursor: "pointer",
    },
});

export default function AddEditLanguage(props) {
    // note this is real current shown/"IN" BonusTask's ID
    const languageId = props.match.params.id;

    const classes = useStyles();

    // these are "static"
    const userId = useAuthUserId();

    const { data: language } = useLanguage(languageId);
    const addLanguage = useLanguageAdd();
    const editLanguage = useLanguageEdit();

    // this is the local dynamic state for when creating game (or editing some of its props)
    const [languageLocalState, setLanguageLocalState] = useSetState({
        language: "",
        lang: "",
        imgFlagData: undefined,
        imgFlagDataSrc: "",
        imgFlagName: "",
        dictionary: {},
    });

    useEffect(() => {
        // set game's data into the local game's state (the editable one)
        if (language) {
            setLanguageLocalState({
                language: language.language,
                lang: language.lang,
                dictionary: language.dictionary || {},
                imgFlagDataSrc: getImageForImageData(language.flag),
            });
        }
    }, [language]);

    const handleSubmit = async (e) => {
        // edit or create game
        e.preventDefault();

        if (languageId) {
            // edit
            await editLanguage({ ...languageLocalState, id: languageId });
        } else {
            // create
            await addLanguage({ ...languageLocalState, userId });
            props.history.push(page_admin_languages);
        }
    };

    const handleInputImage = (event) => {
        const /* File */ file = event.target.files?.[0];
        if (!file) return;

        // display image (convert to base64 string)
        const reader = new FileReader();
        reader.onload = (e) => {
            setLanguageLocalState({
                imgFlagData: file,
                imgFlagName: file.name,
                imgFlagDataSrc: e.target.result,
            });
        };
        reader.readAsDataURL(file);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <ValidatorForm onSubmit={handleSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>
                                    {languageId ? "Edit" : "Create"} Language
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} md={4}>
                                        <TextValidator
                                            label="Language *"
                                            onChange={(event) =>
                                                setLanguageLocalState({
                                                    language: event.target.value,
                                                })
                                            }
                                            value={languageLocalState.language}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} md={4}>
                                        <TextValidator
                                            label="Lang *"
                                            onChange={(event) =>
                                                setLanguageLocalState({
                                                    lang: event.target.value,
                                                })
                                            }
                                            value={languageLocalState.lang}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} md={4}>
                                        <div className={classes.imageUploadContent}>
                                            <div className={classes.imageUploadLabel}>
                                                <label htmlFor="imgFlag">Flag</label>
                                            </div>

                                            <div className={classes.imageUploadPreview}>
                                                <img
                                                    id="imgFlag"
                                                    src={languageLocalState.imgFlagDataSrc}
                                                    alt=""
                                                    width="25px"
                                                />
                                            </div>

                                            <div className={classes.imageUploadButton}>
                                                <label
                                                    htmlFor="imgFlagFileUpload"
                                                    className={classes.customFileUpload}
                                                >
                                                    <i className="fa fa-cloud-upload" /> Upload
                                                </label>
                                                <input
                                                    type="file"
                                                    id="imgFlagFileUpload"
                                                    onChange={handleInputImage}
                                                    accept=".jpg,.png,.jpeg"
                                                />
                                            </div>
                                            {/* <div>
                                                <label>{languageLocalState.imgFlagName}</label>
                                            </div> */}
                                        </div>
                                    </GridItem>
                                </GridContainer>
                                {/* Object.keys(dictionary).map(function(key, index) { */}
                                {Object.keys(languageLocalState.dictionary).map((key) => {
                                    return (
                                        <GridContainer key={key}>
                                            <GridItem xs={12} md={4}>
                                                <CustomTextField value={key} disabled fullWidth />
                                            </GridItem>

                                            <GridItem xs={12} md={8}>
                                                <CustomTextField
                                                    value={languageLocalState.dictionary[key]}
                                                    onChange={(event) =>
                                                        setLanguageLocalState({
                                                            dictionary: {
                                                                ...languageLocalState.dictionary,
                                                                [key]: event.target.value,
                                                            },
                                                        })
                                                    }
                                                    fullWidth
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    );
                                })}
                            </CardBody>
                            <CardFooter>
                                <Button type="submit" color="primary" startIcon={<SaveIcon />}>
                                    {languageId ? "Save" : "Create"}
                                </Button>
                            </CardFooter>
                        </ValidatorForm>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

import { useEffect } from "react";
import { useSetState } from "react-use";

import { makeStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
// @ts-ignore
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import ImageUpload from "../../../../components/ImageUpload/ImageUpload";

import { page_admin_bonus_tasks } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";
import { useBonusTask, useBonusTaskAdd, useBonusTaskEdit } from "../../cache/bonusTasks";

const useStyles = makeStyles({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#F36F2B",
        fontWeight: "bold",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
});

export default function AddEditBonusTask(props) {
    // note this is real current shown/"IN" BonusTask's ID
    const bonusTaskId = props.match.params.id;

    const classes = useStyles();
    const userId = useAuthUserId();

    const { data: bonusTask } = useBonusTask(bonusTaskId);
    const addBonusTask = useBonusTaskAdd();
    const editBonusTask = useBonusTaskEdit();

    // this is the local dynamic state for when creating game (or editing some of its props)
    const [bonusTaskLocalState, setBonusTaskLocalState] = useSetState({
        name: "",
        description: "",
        action: false,
        imageData: "",
    });

    useEffect(() => {
        // set game's data into the local game's state (the editable one)
        if (bonusTask) {
            setBonusTaskLocalState({
                name: bonusTask.name,
                description: bonusTask.description,
                action: bonusTask.action,
                imageData: bonusTask.imageData,
            });
        }
    }, [bonusTask]);

    const handleSubmit = async (e) => {
        // edit or create game
        e.preventDefault();

        if (bonusTaskId) {
            // edit
            await editBonusTask({ ...bonusTaskLocalState, id: bonusTaskId });
        } else {
            // create
            await addBonusTask({ ...bonusTaskLocalState, userId });
        }
        props.history.push(page_admin_bonus_tasks);
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>
                                {bonusTaskId ? "Edit" : "Create"} Bonus Task
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <ValidatorForm onSubmit={handleSubmit}>
                                <GridContainer>
                                    <GridItem xs={12} md={6}>
                                        <TextValidator
                                            label="Name *"
                                            onChange={(e) =>
                                                setBonusTaskLocalState({ name: e.target.value })
                                            }
                                            name="name"
                                            value={bonusTaskLocalState.name}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </GridItem>

                                    <GridItem xs={12} md={6} container justifyContent="center">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={(e) =>
                                                        setBonusTaskLocalState({
                                                            action: e.target.checked,
                                                        })
                                                    }
                                                    name="action"
                                                    checked={!!bonusTaskLocalState.action}
                                                />
                                            }
                                            label="Action"
                                            labelPlacement="start"
                                        />
                                    </GridItem>

                                    <GridItem
                                        xs={12}
                                        md={6}
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <TextField
                                            label="Description"
                                            multiline
                                            minRows={8}
                                            value={bonusTaskLocalState.description}
                                            onChange={(e) =>
                                                setBonusTaskLocalState({
                                                    description: e.target.value,
                                                })
                                            }
                                            variant="standard"
                                            margin="dense"
                                            size="medium"
                                            fullWidth
                                        />
                                    </GridItem>

                                    <GridItem xs={12} md={6}>
                                        <ImageUpload
                                            initialImg={bonusTaskLocalState.imageData}
                                            id="bonus_task_image"
                                            onLoad={(imageData) =>
                                                setBonusTaskLocalState({ imageData })
                                            }
                                        />
                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} md={6}>
                                        <Button type="submit" color="primary">
                                            {bonusTaskId ? "Save" : "Create"}
                                        </Button>
                                    </GridItem>
                                </GridContainer>
                            </ValidatorForm>
                        </CardBody>
                        <CardFooter />
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

import { useTranslation } from "react-i18next";

import switch_off_camera from "@/assets/img/player/switch-off_camera.svg";

import { page_player_architect_task, page_player_choose_role } from "@/utils/constants";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import BackButton from "@/layouts/player/components/Button/BackButton";
import { useNavigate } from "@/layouts/player/realtime";

export default function Architect1() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        // go to the next page
        navigate(page_player_architect_task);
    };

    const backHandler = () => {
        navigate(page_player_choose_role);
    };

    return (
        <>
            <div className="task-content">
                <p className="role-description-text">{t("arch_text_1")}</p>
                <p className="role-description-text">{t("arch_text_2")}</p>
                <p className="role-description-title">{t("arch_player_action")}</p>
                <div className="switch-off-camera">
                    <img src={switch_off_camera} alt="switch_off_camera" />
                </div>
            </div>

            <BackButton onClick={backHandler} label={t("lobby1_waiting_room_back_button")} />

            <form onSubmit={submitHandler}>
                <IgniteButton />
            </form>
        </>
    );
}

import axios, { AxiosError } from "axios";
import { setupCache } from "axios-cache-interceptor";

import { apiUrl } from "@/config";
import { logout } from "@/service/authApi";
import { getAdminAuthUser } from "@/utils/utils";

let httpApi = axios.create({
    baseURL: `${apiUrl}/api`,
});
// setup some simple cache strategy, so that same "fast" requests to be skipped
// probably not needed anymore after migrating everything to react-query but still
httpApi = setupCache(httpApi, {
    ttl: 500, // 0.5 secs
});

// add the auth token to each request
httpApi.interceptors.request.use((config) => {
    // could get the token from the store/cache
    const { token } = getAdminAuthUser() ?? {};

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
});

// validate the response and if it's 403 error (Not Authorized) then clear the auth-token
httpApi.interceptors.response.use(undefined, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error instanceof AxiosError) {
        const {
            response: { status },
        } = error;

        if (status === 403) logout();
    }
    return Promise.reject(error);
});

export default httpApi;

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { isMobile } from "react-device-detect";

import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import background from "@/assets/img/player/background.svg";

import { webUrl } from "@/config";
import playerApi from "@/service/playerApi";
import { page_player_ext_bonus_task_upload_photo } from "@/utils/constants";

import ScanQnCodeDrawer from "@/layouts/player/components/ScanQnCodeDrawer";
import CustomButton from "@/layouts/player/components//Button/CustomButton";
import TakePhoto from "./TakePhoto";
import { useGame } from "@/layouts/player/cache/game";
import { usePlayer } from "@/layouts/player/realtime";
import { createRoutePathForGame } from "../../routes";

export default function BonusTaskDrawer({ bonusTask, onClose, onAction }) {
    const { t } = useTranslation();
    const [isTakePhoto, setTakePhoto] = useState(false);

    /**
     * @param {string} [imageDataUri]
     */
    const onBonusAction = (imageDataUri) => {
        if (!bonusTask.action || imageDataUri) {
            // no-action task or result from the taken photo
            onAction(imageDataUri);
            // reset for next task
            setTakePhoto(false);
        } else {
            // this task requires taking of a photo, so show the UI
            setTakePhoto(true);
        }
    };

    const onCloseAction = () => {
        setTakePhoto(false);
        onClose();
    };

    return (
        <Drawer
            anchor="bottom"
            open={!!bonusTask}
            onClose={onCloseAction}
            sx={{
                "& .MuiDrawer-paperAnchorBottom": {
                    width: "100%",
                    height: "100%",
                    background: `url(${background})`,
                    backgroundSize: "cover",
                },
            }}
            transitionDuration={100}
        >
            {!!bonusTask && (
                <div className="fullScreen">
                    <div className="drawer-container">
                        <div className="drawer-title">
                            <div />
                            <p className="title">{bonusTask.name}</p>
                            <button onClick={onCloseAction} className="drawer-close" type="button">
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="drawer-content ">
                            <div className="BT-drawer-content-details bonus-task-action">
                                {isTakePhoto && !bonusTask.done ? (
                                    <TakePhotoHere
                                        onSubmit={onBonusAction}
                                        bonusTaskId={bonusTask.id}
                                    />
                                ) : (
                                    <>
                                        <div className="BT-details-container">
                                            {bonusTask.image && (
                                                <img
                                                    className="BT-image"
                                                    src={bonusTask.image}
                                                    alt="bonus task"
                                                />
                                            )}
                                            <div className="BT-description">
                                                {bonusTask.description || "-----"}
                                            </div>
                                        </div>
                                        <div className="BT-submit">
                                            {bonusTask.done ? (
                                                <div className="BT-well-done green-text">
                                                    <BeenhereIcon fontSize="large" />
                                                    <p className="BT-description">
                                                        {t("bonus_tasks_done")}
                                                    </p>
                                                </div>
                                            ) : (
                                                <CustomButton
                                                    onClick={() => onBonusAction()}
                                                    label={t(
                                                        bonusTask.action
                                                            ? "submit_photo_take_photo_button"
                                                            : "bonus_tasks_submit_button",
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Drawer>
    );
}

/**
 * @param {{onSubmit: (imageDataUri: string) => void, bonusTaskId: string}} props
 */
function TakePhotoHere({ onSubmit, bonusTaskId }) {
    const { t } = useTranslation();

    /**
     * @type {{data: import("../../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const [showQRCode, setShowQRCode] = useState(false);
    const [imageToken, setImageToken] = useState();

    useEffectOnce(() => {
        const asyncAction = async () => {
            // not need such functionality for mobiles
            if (isMobile) return;

            const { data } = await playerApi.get("/images/token");
            const imageToken = data.imageToken;
            setImageToken(imageToken);
        };

        asyncAction();
    });

    const takePhotoProps = {
        gameId: game.id,
        gameName: game.name,
        playerId: player.id,
        nickname: player.nickname,
        teamCode: player.teamCode,
        bonusTaskId,
    };

    const uploadPhotoProps = {
        onSubmit,

        renderExtra() {
            // wait for the imageToken, e.g the JWT token which will authorize the requests from the UploadImageExt page
            if (!imageToken) return null;

            let data = JSON.stringify({
                imageToken,
                ...takePhotoProps,
            });
            const openUrl = `${webUrl}${createRoutePathForGame(
                page_player_ext_bonus_task_upload_photo,
                game.id,
            )}/${btoa(data)}`;

            return (
                <>
                    <CustomButton
                        onClick={() => setShowQRCode(true)}
                        label={t("submit_photo_scan_qrcode_button")}
                        img={<QrCode2Icon />}
                        isSecondary
                    />

                    {showQRCode && (
                        <ScanQnCodeDrawer
                            url={openUrl}
                            open={showQRCode}
                            onClose={() => setShowQRCode(false)}
                        />
                    )}
                </>
            );
        },
    };
    return <TakePhoto {...uploadPhotoProps} />;
}

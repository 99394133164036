/* eslint-disable no-console */

// Logging facade

let logFn = console.log;
let warnFn = console.warn;
let errorFn = console.error;

// in production no logging
if (import.meta.env.MODE === "production") {
    logFn = warnFn = errorFn = () => {};
}

export function log(...args) {
    logFn(...args);
}

export function warn(...args) {
    warnFn(...args);
}

export function error(...args) {
    errorFn(...args);
}

import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import { isMobile } from "react-device-detect";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import ImagePreview from "../ImagePreview";
import CustomButton from "../Button/CustomButton";

import { usePlayAudio } from "@/utils/hooks";

const imageDataUriSkip = "SKIP";

/**
 * @param {{onSubmit: (imageDataUri: string) => void, renderExtra?: import("react").ReactNode}} props
 */
export default function TakePhoto({ onSubmit, renderExtra }) {
    const { t } = useTranslation();
    const { playAudio } = usePlayAudio();

    const [imageDataUri, setImageDateUri] = useState("");
    const [isFrontCamera, setFrontCamera] = useState(isMobile);
    const webcamRef = useRef();

    const takePhotoHandler = () => {
        if (imageDataUri) {
            // reset
            setImageDateUri("");
        } else {
            playAudio(true);

            const snapshotDataUri = webcamRef.current.getScreenshot({ width: 250, height: 250 });
            setImageDateUri(snapshotDataUri);
        }
    };

    const submitPhotoHandler = () => {
        playAudio();
        onSubmit(imageDataUri);
    };

    return (
        <>
            <div className="task-content-wrap">
                <div className="task-content">
                    {imageDataUri ? (
                        imageDataUri !== imageDataUriSkip && <ImagePreview dataUri={imageDataUri} />
                    ) : (
                        <Webcam
                            width={250}
                            height={250}
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={{
                                width: 250,
                                height: 250,
                                facingMode: isFrontCamera ? "user" : "environment",
                            }}
                        />
                    )}
                </div>

                {isMobile && (
                    <CustomButton
                        onClick={() => setFrontCamera(!isFrontCamera)}
                        label={t("submit_photo_switch_camera_button")}
                        img={<FlipCameraIosIcon />}
                        isSecondary
                    />
                )}

                {renderExtra?.()}

                <CustomButton
                    onClick={takePhotoHandler}
                    label={t(
                        imageDataUri
                            ? "submit_photo_take_new_photo_button"
                            : "submit_photo_take_photo_button",
                    )}
                    img={<PhotoCameraIcon />}
                />
            </div>

            <CustomButton
                disabled={!imageDataUri || imageDataUri === imageDataUriSkip}
                onClick={submitPhotoHandler}
                label={t("submit_photo_submit_button")}
                img={<CheckCircleOutlineIcon />}
            />
        </>
    );
}

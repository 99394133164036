import { useCallback } from "react";
import { atom, useAtom } from "jotai";
import { useLocalStorage } from "usehooks-ts";
// NOTE: use the hook from "usehooks-ts"; and not "react-use",
// as this one is listening to changes in the localStorage also
// (e.g. when same key is updated from different useLocalStorage() hooks)

import i18n from "@/i18n";

import { playAudio as playAudio_ } from "./utils";

export function usePlayAudio() {
    const [isPlayAudio, setPlayAudio_] = useLocalStorage("sounds_on", true);

    const setPlayAudio = useCallback(
        /**
         * @param {boolean} isOn
         */
        (isOn) => {
            setPlayAudio_(isOn);
            if (isOn) playAudio_();
        },
        [setPlayAudio_],
    );

    const playAudio = useCallback(
        /**
         * @param {boolean} [forCamera]
         */
        (forCamera = false) => {
            if (isPlayAudio) playAudio_(forCamera);
        },
        [isPlayAudio],
    );

    return { isPlayAudio, setPlayAudio, playAudio };
}

/**
 *
 * @returns
 */
export function useLanguage() {
    const [language, setLanguage_] = useLocalStorage("language", i18n.enLanguage);

    const setLanguage = useCallback(
        /**
         * @param {string} lang
         */
        (lang) => {
            i18n.changeLanguage(lang);
            setLanguage_(lang);
        },
        [setLanguage_],
    );

    const validateLanguage = useCallback(() => {
        // check if there's current language set and it's among the "allowed" ones (already set in i18n)
        // and if not then set the english as current
        if (language && i18n.isValidLanguage(language)) setLanguage(language);
        else setLanguage(i18n.enLanguage);
    }, [language, setLanguage]);

    return { language, setLanguage, validateLanguage };
}

// Create a atomWithToggle - https://jotai.org/docs/recipes/atom-with-toggle

/**
 * Create a writable toggle-able atom
 * @param {boolean} [initialValue]
 * @return {import("jotai").WritableAtom<boolean, boolean | undefined, any>}
 */
function createAtomWithToggle(initialValue) {
    const anAtom = atom(initialValue, (get, set, nextValue) => {
        nextValue = nextValue ?? !get(anAtom);
        set(anAtom, nextValue);
    });
    return anAtom;
}

const sidebarOpen = createAtomWithToggle(false);
/**
 * Abstracts away the Jotai lib and just return a hook that give back the current sidebar state,
 * and how to change/toggle it.
 * @return {[boolean, (newValue?: boolean) => void]}
 */
export function useSidebarOpen() {
    return useAtom(sidebarOpen);
}

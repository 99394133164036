import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useEffectOnce } from "react-use";
import { isMobile } from "react-device-detect";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import { page_player_task_finished, page_player_ext_builder_upload_photo } from "@/utils/constants";
import { webUrl } from "@/config";
import playerApi from "@/service/playerApi";

import BuilderUploadPhoto from "@/layouts/player/components/BuilderUploadPhoto";
import ScanQnCodeDrawer from "@/layouts/player/components/ScanQnCodeDrawer";
import CustomButton from "@/layouts/player/components/Button/CustomButton";
import { useGame } from "@/layouts/player/cache/game";
import {
    usePlayer,
    useRunningGame,
    useOnUploadTeamPhoto,
    useNavigate,
} from "@/layouts/player/realtime";
import { createRoutePathForGame } from "../routes";

export default function Builder3() {
    const { t } = useTranslation();
    const [showQRCode, setShowQRCode] = useState(false);
    const [imageToken, setImageToken] = useState();

    const navigate = useNavigate();

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();
    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();

    const gameId = game.id;
    const gameName = game.name;
    const teamId = player.team;
    const teamCode = player.teamCode;
    const playerId = player.id;
    const nickname = player.nickname;
    const roundId = runningGame.round;
    const roundOrder = runningGame.roundOrder;

    const onImageUploaded = useCallback(() => {
        // go to the next page
        navigate(page_player_task_finished);
    }, []);

    // here we are interested only on the "listener"
    useOnUploadTeamPhoto(gameId, teamId, playerId, roundOrder, onImageUploaded);

    useEffectOnce(() => {
        (async () => {
            // not need such functionality for mobiles
            if (isMobile) return;

            const { data } = await playerApi.get("/images/token");
            const imageToken = data.imageToken;
            setImageToken(imageToken);
        })();
    });

    const builderUploadPhotoProps = {
        gameId,
        gameName,
        teamId,
        teamCode,
        playerId,
        nickname,
        roundId,
        roundOrder,
    };

    const uploadPhotoProps = {
        ...builderUploadPhotoProps,

        onSubmitted: onImageUploaded,

        renderExtra() {
            // wait for the imageToken, e.g the JWT token which will authorize the requests from the UploadImageExt page
            if (!imageToken) return null;

            let data = JSON.stringify({
                imageToken,
                ...builderUploadPhotoProps,
            });
            const openUrl = `${webUrl}${createRoutePathForGame(
                page_player_ext_builder_upload_photo,
                gameId,
            )}/${btoa(data)}`;

            return (
                <>
                    <CustomButton
                        onClick={() => setShowQRCode(true)}
                        label={t("submit_photo_scan_qrcode_button")}
                        img={<QrCode2Icon />}
                        isSecondary
                    />

                    {showQRCode && (
                        <ScanQnCodeDrawer
                            url={openUrl}
                            open={showQRCode}
                            onClose={() => setShowQRCode(false)}
                        />
                    )}
                </>
            );
        },
    };

    return <BuilderUploadPhoto {...uploadPhotoProps} />;
}

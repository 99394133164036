import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function BackButton(props) {
    const { isSubmit = false, onClick, disabled, label } = props;

    return (
        <button
            type={isSubmit ? "submit" : "button"}
            className={`back ${disabled ? "inactive" : "active"}`}
            disabled={disabled ? "disabled" : undefined}
            onClick={onClick}
        >
            <ChevronLeftIcon />

            <span className="label">{label}</span>
        </button>
    );
}

import { useCallback, useEffect, useMemo } from "react";
import { useSetState, useEffectOnce } from "react-use";

import QRCode from "react-qr-code";
// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import { Chip, TextField, Typography, Alert, ListItemText, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import LinkIcon from "@mui/icons-material/Link";
import PhotoIcon from "@mui/icons-material/PhotoCamera";

import "./AddEditGame.css";

import { webUrl } from "@/config";
import { page_player_join, page_admin_games } from "@/utils/constants";
import { createRoutePathForGame } from "@/layouts/player/routes";

import Teams from "./GameTeams";
import Rounds from "./GameRounds";
import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";

import { useGame, useGameEdit, useGameAdd } from "../../cache/games";
import { useBonusTasks } from "../../cache/bonusTasks";
import { useGameModels } from "../../cache/gameModels";
import { useLanguages } from "../../cache/languages";
import { startGame, subscribeGame, useRunningGame } from "@/layouts/admin/realtime";
import { useAuthUserId } from "@/service/authApi";
import { EMPTY_ARRAY } from "@/utils/utils";

const qrCodeSize = 128;

const useStyles = makeStyles({
    cardCategoryWhite: {
        color: "#000",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#F36F2B",
        fontWeight: "bold",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    marginTop: {
        marginTop: "16px",
    },
    formControl: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        width: "50%",
        height: 120,
        position: "relative",
        verticalAlign: "unset",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: 2,
    },
    formControlSelect: {
        minWidth: 180,
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset",
    },
    timeField: {
        maxWidth: 100,
    },
    select: {
        minWidth: 240,
        minHeight: 140,
    },
});

/**
 * Ensure the English is in the output languages (if present as possible languages)
 * @param {string[]} languages
 * @param {string[]} [all_languages]
 * @returns string[]
 */
const ensureEnglishLanguage = (languages, all_languages) => {
    const englishId = all_languages?.find((langObj) => langObj.lang === "EN")?.id;
    if (!englishId || languages.includes(englishId)) return languages;
    return [...languages, englishId];
};

export default function AddEditGame(props) {
    const gameId = props.match.params.id;

    const classes = useStyles();
    const userId = useAuthUserId();

    const { data: game } = useGame(gameId);
    const { data: game_models = EMPTY_ARRAY } = useGameModels();
    const { data: all_bonus_tasks = EMPTY_ARRAY } = useBonusTasks();
    const { data: all_languages = EMPTY_ARRAY } = useLanguages();

    const { data: runningGame } = useRunningGame(gameId, true);

    const editGame = useGameEdit();
    const addGame = useGameAdd();

    // this is the local dynamic state for when creating game (or editing some of its props)
    const [gameLocalState, setGameLocalState] = useSetState({
        name: "",
        model: "",
        client: "",
        numTeams: 2,
        /**
         * @type {string[]}
         */
        bonusTasks: [],
        /**
         * @type {string[]}
         */
        languages: [],
    });

    useEffectOnce(() => {
        if (gameId) return subscribeGame(gameId);
    });

    useEffect(() => {
        // set game's data into the local game's state (the editable one)
        if (game) {
            setGameLocalState({
                name: game.name,
                model: game.model,
                client: game.client,
                bonusTasks: game.bonusTasks,
                languages: game.languages,
            });
        }
    }, [game]);

    // when 'all_languages' are present then ensure english is added
    useEffect(() => {
        setGameLocalState(({ languages: curLanguages }) => {
            return { languages: ensureEnglishLanguage(curLanguages, all_languages) };
        });
    }, [all_languages]);

    const isGameStarted = !!game?.dateStarted;

    // it's "stable" function all the time
    const handleQRCodeOpen = useCallback(() => {
        const qrCode = document.querySelector("#qr-code");
        if (!qrCode) return;
        const qrTab = window.open();
        if (!qrTab) return;
        // make it centered
        qrTab.document.write(
            `<style> body {display: grid; justify-content: center; align-content: center;}</style>`,
        );
        qrTab.document.write(qrCode.outerHTML);
    }, []);

    // it's "stable" function all the time  (gameId is "stable")
    const handleQRCodeDownload = useCallback(() => {
        const qrCode = document.querySelector("#qr-code");
        if (!qrCode) return;
        const svg = qrCode.children[0];
        const svgData = new XMLSerializer().serializeToString(svg);

        const canvas = document.createElement("canvas");
        // there should be a so called 'quite zone' surrounding the qrcode image
        // https://qrworld.wordpress.com/2011/08/09/the-quiet-zone/
        const whiteMargin = 50;
        canvas.width = qrCodeSize + 2 * whiteMargin;
        canvas.height = qrCodeSize + 2 * whiteMargin;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const img = document.createElement("img");
        img.setAttribute("src", `data:image/svg+xml;base64,${btoa(svgData)}`);

        // wait for the image to be loaded and then draw it into the canvas
        img.onload = () => {
            // draw the image into the canvas
            ctx.drawImage(img, whiteMargin, whiteMargin);

            // trigger the download
            const dataUrl = canvas.toDataURL("image/png");
            const link = document.createElement("a");
            link.download = gameId;
            link.target = "_blank";
            link.href = dataUrl;
            link.click();
        };
    }, []);

    // it's "stable" function all the time (gameId is "stable")
    const handleStartGame = useCallback(() => {
        startGame(gameId);
    }, []);

    const handleSubmit = async () => {
        // edit or create game
        if (game) {
            await editGame({ ...game, ...gameLocalState });
        } else {
            await addGame({ ...gameLocalState, userId });
        }
        // go back to the list
        props.history.push(page_admin_games);
    };

    const playUrl = useMemo(
        () => `${webUrl}${createRoutePathForGame(page_player_join, gameId)}`,
        [],
    );

    const dateStartedLocale = useMemo(
        () => (game?.dateStarted ? new Date(game.dateStarted).toLocaleString() : ""),
        [game?.dateStarted],
    );

    const dateEndedLocale = useMemo(
        () => (game?.dateEnded ? new Date(game.dateEnded).toLocaleString() : ""),
        [game?.dateEnded],
    );

    const gameModelsSelect = useMemo(
        () =>
            game_models.length ? (
                <FormControl className={classes.formControlSelect}>
                    <SelectValidator
                        label="Game Model *"
                        name="model"
                        value={gameLocalState.model}
                        onChange={(e) => setGameLocalState({ model: e.target.value })}
                        disabled={!!game}
                        fullWidth
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                    >
                        {game_models.map(({ id, name }) => (
                            <MenuItem key={id} value={id}>
                                {name}
                            </MenuItem>
                        ))}
                    </SelectValidator>
                </FormControl>
            ) : (
                <></>
            ),
        [game_models, gameLocalState.model, game],
    );

    const bonusTasksSelect = useMemo(
        () => (
            <TextField
                label="Bonus Tasks"
                className={classes.select}
                variant="outlined"
                margin="dense"
                size="medium"
                select
                // required
                multiline
                minRows={12}
                value={gameLocalState.bonusTasks}
                onChange={(e) => setGameLocalState({ bonusTasks: e.target.value })}
                disabled={isGameStarted}
                SelectProps={{
                    classes: { select: classes.select },

                    MenuProps: {
                        PaperProps: {
                            style: {
                                width: 250,
                            },
                        },
                    },
                    displayEmpty: true,
                    multiple: true,
                    renderValue: (selected) => (
                        <div className={classes.chips}>
                            {all_bonus_tasks
                                .filter((item) => selected.includes(item.id))
                                .map((item) => (
                                    <Chip
                                        key={item.id}
                                        label={item.name}
                                        className={classes.chip}
                                        icon={item.action ? <PhotoIcon /> : undefined}
                                    />
                                ))}
                        </div>
                    ),
                }}
            >
                {all_bonus_tasks.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        <ListItemText>{item.name}</ListItemText>
                        {item.action && (
                            <ListItemIcon>
                                <PhotoIcon />
                            </ListItemIcon>
                        )}
                    </MenuItem>
                ))}
            </TextField>
        ),
        [all_bonus_tasks, gameLocalState.bonusTasks, isGameStarted],
    );

    const languagesSelect = useMemo(() => {
        const MenuProps = {
            PaperProps: {
                style: {
                    width: 250,
                },
            },
        };
        return (
            <TextField
                label="Languages"
                className={classes.select}
                variant="outlined"
                margin="dense"
                size="medium"
                select
                required
                multiline
                minRows={12}
                value={gameLocalState.languages}
                onChange={(e) =>
                    setGameLocalState({
                        languages: ensureEnglishLanguage(e.target.value, all_languages),
                    })
                }
                disabled={isGameStarted}
                SelectProps={{
                    classes: { select: classes.select },
                    MenuProps,
                    multiple: true,
                    renderValue: (selected) => (
                        <div className={classes.chips}>
                            {all_languages
                                .filter((item) => selected.includes(item.id))
                                .map((item) => (
                                    <Chip
                                        key={item.id}
                                        label={item.language}
                                        className={classes.chip}
                                    />
                                ))}
                        </div>
                    ),
                }}
            >
                {all_languages.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.language}
                    </MenuItem>
                ))}
            </TextField>
        );
    }, [all_languages, gameLocalState.languages, isGameStarted]);

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <ValidatorForm onSubmit={handleSubmit}>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>
                                {game ? "Edit" : "Create"} Game
                            </h4>
                            {/* Show both start and end time if available */}
                            {dateStartedLocale && (
                                <p className={classes.cardCategoryWhite}>
                                    {`- This game was started on ${dateStartedLocale}.${
                                        !dateEndedLocale
                                            ? ` Only "Round
                                    Times" area is available for editing.`
                                            : ""
                                    }`}
                                </p>
                            )}
                            {dateEndedLocale && (
                                <p className={classes.cardCategoryWhite}>
                                    {`- This game has ended on ${dateEndedLocale}.`}
                                </p>
                            )}
                        </CardHeader>
                        <CardBody>
                            {game && (
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <Button
                                            color="primary"
                                            size="lg"
                                            startIcon={<PlayCircleOutlineIcon />}
                                            onClick={handleStartGame}
                                            disabled={isGameStarted}
                                        >
                                            Start Game
                                        </Button>
                                    </GridItem>

                                    <GridItem xs={12}>
                                        <div className="game-url-section splash">
                                            <InputLabel>Url</InputLabel>
                                            <a
                                                href={playUrl}
                                                className="game-url"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {playUrl}
                                            </a>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                title="Copy link"
                                                onClick={() =>
                                                    navigator.clipboard.writeText(playUrl)
                                                }
                                                className="copy-url"
                                            >
                                                <LinkIcon />
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="sm"
                                                title="Download image"
                                                onClick={handleQRCodeDownload}
                                                className="copy-url"
                                            >
                                                <GetAppIcon />
                                            </Button>
                                        </div>

                                        <div className="game-url-section splash">
                                            <InputLabel>QR Code</InputLabel>
                                            <Button
                                                color="transparent"
                                                size="sm"
                                                onClick={handleQRCodeOpen}
                                            >
                                                <div id="qr-code">
                                                    <QRCode
                                                        fgColor="#F36F2B"
                                                        size={qrCodeSize}
                                                        value={playUrl}
                                                        style={{
                                                            width: "128px",
                                                            height: "auto",
                                                        }}
                                                    />
                                                </div>
                                            </Button>
                                        </div>
                                    </GridItem>

                                    {runningGame?.players && (
                                        <GridItem xs={12} marginY={2}>
                                            <Alert severity="info">
                                                <Typography>
                                                    Players : {runningGame.players.length}
                                                </Typography>
                                            </Alert>
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} marginY={2}>
                                        <Teams gameId={gameId} />
                                    </GridItem>
                                    <GridItem xs={12} marginY={2}>
                                        <Rounds gameId={gameId} />
                                    </GridItem>
                                </GridContainer>
                            )}

                            <GridContainer>
                                <GridItem xs={12} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextValidator
                                            label="Name *"
                                            onChange={(e) =>
                                                setGameLocalState({ name: e.target.value })
                                            }
                                            name="name"
                                            value={gameLocalState.name}
                                            fullWidth
                                            disabled={isGameStarted}
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    {gameModelsSelect}
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextValidator
                                            label="Client"
                                            value={gameLocalState.client}
                                            onChange={(e) =>
                                                setGameLocalState({ client: e.target.value })
                                            }
                                            fullWidth
                                            disabled={isGameStarted}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    <FormControl className={classes.formControl}>
                                        <TextValidator
                                            type="number"
                                            label="Number of teams *"
                                            onChange={(e) =>
                                                setGameLocalState({
                                                    numTeams: e.target.valueAsNumber,
                                                })
                                            }
                                            name="numTeams"
                                            value={gameLocalState.numTeams}
                                            validators={["required", "minNumber:2", "maxNumber:15"]}
                                            errorMessages={[
                                                "This field is required",
                                                "Minimum 2 teams allowed",
                                                "Maximum 15 teams allowed",
                                            ]}
                                            disabled={!!game}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    {bonusTasksSelect}
                                </GridItem>
                                <GridItem xs={12} md={6}>
                                    {languagesSelect}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button
                                type="submit"
                                color="primary"
                                size="lg"
                                startIcon={<SaveIcon />}
                                disabled={isGameStarted}
                            >
                                {game ? "Save" : "Create"}
                            </Button>
                        </CardFooter>
                    </ValidatorForm>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

import { getImageForRole } from "@/utils/utils";
import * as Constants from "@/utils/constants";
import { usePlayAudio } from "@/utils/hooks";
import i18n from "@/i18n";

import architect_95x95 from "@/assets/img/player/architect_95x95.svg";
import builder_95x95 from "@/assets/img/player/builder_95x95.svg";
import pyro_95x95 from "@/assets/img/player/pyro_95x95.svg";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import PlayerRounds from "@/layouts/player/components/PlayerRounds";
import TeamsWithRoles from "@/layouts/player/components/TeamPlayersRounds";
import { useGame } from "@/layouts/player/cache/game";
import {
    changePlayerStatus,
    changePlayerRole,
    useRunningGame,
    usePlayer,
    useNavigate,
} from "@/layouts/player/realtime";

const ic_architect = getImageForRole(Constants.PlayerRole.architect);
const ic_pyro = getImageForRole(Constants.PlayerRole.pyro);
const ic_builder = getImageForRole(Constants.PlayerRole.builder);

/**
 * @param {import("@/layouts/player/realtime").Player[]} players all players
 * @param {string} playerId current player ID
 * @param {string} teamId current player team ID
 */
export function getDisabledRoles(players, playerId, teamId) {
    let roleArchDisabled = false;
    let rolePyroDisabled = false;
    players?.forEach((player) => {
        // skip players from other teams
        if (teamId !== player.team) return;

        if (player.id !== playerId) {
            // check if Architect is already taken
            if (player.role === Constants.PlayerRole.architect) roleArchDisabled = true;
            // check if Pyro is already taken
            else if (player.role === Constants.PlayerRole.pyro) rolePyroDisabled = true;
        }
    });

    return { roleArchDisabled, rolePyroDisabled };
}

export default function ChooseRole() {
    const { t } = useTranslation();
    const { playAudio } = usePlayAudio();
    const navigate = useNavigate();

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const gameId = game.id;
    const { round: roundId, roundOrder } = runningGame;
    const { id: playerId, role, team } = player;

    const { roleArchDisabled, rolePyroDisabled } = getDisabledRoles(
        runningGame.players,
        playerId,
        team,
    );

    useEffectOnce(() => {
        changePlayerStatus(gameId, roundId, playerId, Constants.PlayerStatus.Waiting);
        return () => changePlayerStatus(gameId, roundId, playerId, Constants.PlayerStatus.Playing);
    });

    let currentRoundAsWord = "" + roundOrder;
    // for English do some language improvements
    if (i18n.currentLanguage === i18n.enLanguage) {
        if (roundOrder === 1) currentRoundAsWord = "1st";
        else if (roundOrder === 2) currentRoundAsWord = "2nd";
        else if (roundOrder === 3) currentRoundAsWord = "3rd";
        else currentRoundAsWord = `${roundOrder}th`;
    }

    const handleRoleChange = (e) => {
        e.preventDefault();
        playAudio();
        changePlayerRole(gameId, roundId, playerId, e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault();

        let pageTask;
        switch (role) {
            case Constants.PlayerRole.architect:
                pageTask = Constants.page_player_architect_start;
                break;
            case Constants.PlayerRole.builder:
                pageTask = Constants.page_player_builder_start;
                break;
            case Constants.PlayerRole.pyro:
                pageTask = Constants.page_player_pyro_start;
                break;
            default:
                throw new Error(`Invalid role ${role}`);
        }
        navigate(pageTask);
    };

    return (
        <>
            <div className="task-content">
                <PlayerRounds />

                <p className="welcome name-text">
                    {t("choose_a_role_screen_player_action", {
                        current_round: currentRoundAsWord,
                    })}
                </p>

                <ul className="game-roles">
                    <li key={Constants.PlayerRole.architect}>
                        <input
                            type="radio"
                            name="rGroup"
                            id={Constants.PlayerRole.architect}
                            value={Constants.PlayerRole.architect}
                            onChange={handleRoleChange}
                            disabled={roleArchDisabled}
                            checked={role === Constants.PlayerRole.architect}
                        />
                        <label className="radio1" htmlFor={Constants.PlayerRole.architect}>
                            <div className={roleArchDisabled ? "li-item-inactive" : "li-item"}>
                                <div className="img">
                                    <img src={architect_95x95} alt="" />
                                </div>
                                <div className="title">
                                    <img src={ic_architect} alt="" />
                                    {t("role_architect")}
                                </div>
                                <div className="content">{t("role_architect_description")}</div>
                            </div>
                        </label>
                    </li>
                    <li key={Constants.PlayerRole.pyro}>
                        <input
                            type="radio"
                            name="rGroup"
                            id={Constants.PlayerRole.pyro}
                            value={Constants.PlayerRole.pyro}
                            onChange={handleRoleChange}
                            disabled={rolePyroDisabled}
                            checked={role === Constants.PlayerRole.pyro}
                        />
                        <label className="radio1" htmlFor={Constants.PlayerRole.pyro}>
                            <div className={rolePyroDisabled ? "li-item-inactive" : "li-item"}>
                                <div className="img">
                                    <img src={pyro_95x95} alt="" />
                                </div>

                                <div className="title">
                                    <img src={ic_pyro} alt="" />
                                    {t("role_pyro")}
                                </div>
                                <div className="content">{t("role_pyro_description")}</div>
                            </div>
                        </label>
                    </li>
                    <li key={Constants.PlayerRole.builder}>
                        <input
                            type="radio"
                            name="rGroup"
                            id={Constants.PlayerRole.builder}
                            value={Constants.PlayerRole.builder}
                            onChange={handleRoleChange}
                            checked={role === Constants.PlayerRole.builder}
                        />
                        <label className="radio1" htmlFor={Constants.PlayerRole.builder}>
                            <div className="li-item">
                                <div className="img">
                                    <img src={builder_95x95} alt="" />
                                </div>
                                <div className="title">
                                    <img src={ic_builder} alt="" />
                                    {t("role_builder")}
                                </div>
                                <div className="content">{t("role_builder_description")}</div>
                            </div>
                        </label>
                    </li>
                </ul>

                <TeamsWithRoles />
            </div>

            <form onSubmit={submitHandler}>
                <IgniteButton disabled={!role} />
            </form>
        </>
    );
}

import { useTranslation } from "react-i18next";

import Loading from "@/layouts/player/components/Loading";
import RoleImg from "@/layouts/player/components/RoleImg";
import { usePlayerRounds } from "@/layouts/player/cache/playerRounds";
import { usePlayer, useRunningGame } from "@/layouts/player/realtime";

export default function PlayerRounds() {
    const { t } = useTranslation();

    const { data: runningGame } = useRunningGame();
    const { data: player } = usePlayer();

    // TODO: should be refetched or updated sometimes ?
    const { data: rounds } = usePlayerRounds(runningGame && player?.id);

    // nothing to render
    if (!runningGame || !player) return <Loading size={50} />;

    const currentRoundOrder = runningGame.roundOrder;
    const currentRole = player.role;

    return (
        <div className="rounds">
            <ul className="rounds-list">
                {rounds?.map(({ id, order, role }) => {
                    return (
                        <li
                            key={id}
                            className={
                                order === currentRoundOrder
                                    ? "current"
                                    : order < currentRoundOrder
                                    ? "past"
                                    : "future"
                            }
                        >
                            <div>
                                <p>{t("round", { round: order })}</p>
                                <hr />
                                <p className="role">
                                    <RoleImg
                                        role={order === currentRoundOrder ? currentRole : role}
                                    />
                                </p>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

import { useQuery } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import { fixId, noop } from "@/utils/utils";

import { useGame } from "@/layouts/player/cache/game";

/**
 * @typedef {object} Round
 * @property {string} id
 * @property {number} order
 */

/**
 * @typedef {Round & {role: string | undefined}} PlayerRound
 */

/**
 * Query for the Rounds.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Round[]>}
 */
export function useRounds() {
    const { data: game } = useGame();

    const isAllowed = !!game;
    return useQuery({
        queryKey: ["player", "Rounds"],
        queryFn: () => getRounds(game.id),
        enabled: isAllowed,
        // this is static so no need to be re-fetched
        staleTime: Infinity,
    });
}

/**
 * Query for the PlayerRounds - rounds with roles for the current player.
 * @param {string} [playerId]
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|PlayerRound[]>}
 */
export function usePlayerRounds(playerId) {
    const { data: game } = useGame();
    const { data: rounds } = useRounds();

    const isAllowed = !!game && !!rounds && !!playerId;
    return useQuery({
        queryKey: ["player", "PlayerRounds"],
        queryFn: () => getRoundsWithRoles(game?.id, playerId, rounds),
        enabled: isAllowed,
    });
}

/**
 * Get Rounds function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Round[]>}
 */
const getRounds = async (gameId) => {
    // get all rounds
    const { data: roundsData } = await playerApi.get(`/game_round_times/byGame/${gameId}`);

    return roundsData.map(fixId);
};

/**
 * Get RoundsWithRole function to be used by useQuery
 * @param {string} gameId
 * @param {string} playerId
 * @param {Round[]} rounds
 * @return {Promise<PlayerRound[]>}
 */
const getRoundsWithRoles = async (gameId, playerId, rounds) => {
    /**
     * @type {PlayerRound[]}
     */
    const playerRounds = [];

    const { data: playerRoundsData } = await playerApi.get(
        `/player_rounds/byGameAndPlayer/${gameId}/${playerId}`,
    );

    // create a map from them
    /**
     * @type {Map<string, string|undefined}
     */
    const playerRoundsMap = new Map();
    for (const playerRoundData of playerRoundsData) {
        playerRoundsMap.set(playerRoundData.round, playerRoundData.role);
    }

    for (const round of rounds) {
        const roundWithRole = {
            id: round.id,
            // add as order field
            order: round.round,
            // get player's role for this round
            role: playerRoundsMap.get(round.id),
        };

        playerRounds.push(roundWithRole);
    }

    return playerRounds;
};

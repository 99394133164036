import { Link } from "react-router-dom";
import Login from "@mui/icons-material/Login";
import logo from "@/assets/img/player/logo_ibuild.svg";

import "./index.css";
import { rootPathAdmin } from "@/utils/constants";

/**
 * The main/landing/index page - contains just a big logo
 */
export default function Logo() {
    return (
        <>
            <div className="homepage-logo">
                <img src={logo} alt="logo" />
            </div>
            <div className="homepage-links">
                <Link to={rootPathAdmin}>
                    <Login />
                </Link>
            </div>
        </>
    );
}

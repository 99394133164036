import PropTypes from "prop-types";

import "./styles.css";

export default function ImagePreview({ dataUri, isFullscreen }) {
    return (
        <div className={`image-preview ${isFullscreen ? "image-preview-fullscreen" : ""}`}>
            <img src={dataUri} alt="img" />
        </div>
    );
}

ImagePreview.propTypes = {
    dataUri: PropTypes.string,
    isFullscreen: PropTypes.bool,
};

import { useTranslation } from "react-i18next";

import background from "@/assets/img/player/background.svg";

import "@/layouts/player/components/styles.css";
import Header from "@/layouts/player/components/Header";
import LogoHanger from "@/layouts/player/components/LogoHanger";
import Logo from "@/layouts/player/components/Logo";

/**
 * @param {{
 * className: string,
 * titleKey: string,
 * subtitle: {},
 * children: import("react").ReactNode,
 * }} props
 */
export default function LayoutExt({ className, titleKey, subtitle, children }) {
    const { t } = useTranslation();

    return (
        <div
            className="page-player background-with-color"
            style={{
                backgroundImage: `url(${background})`,
            }}
        >
            <div className={`page-player-wrap ${className ?? ""}`}>
                <Header title={t(titleKey)} subtitle={subtitle} noSidebar />
                <div className="page-player-body">
                    <LogoHanger />
                    {children}
                    <Logo />
                </div>
            </div>
        </div>
    );
}

import { useEffect } from "react";
import { useSetState } from "react-use";

// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import Round from "./Round";

import { page_admin_game_models } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";

import { useGameModel, useGameModelEdit, useGameModelAdd } from "../../cache/gameModels";
import { useGameModelRounds } from "../../cache/gameModelRounds";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#F36F2B",
        fontWeight: "bold",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
});

export default function AddEditGameModel(props) {
    // note this is real current shown/"IN" game-model's ID
    const gameModelId = props.match.params.id;

    const classes = useStyles();
    const userId = useAuthUserId();

    // this is the local dynamic state for when creating game (or editing some of its props)
    const [gameModelLocalState, setGameModelLocalState] = useSetState({
        name: "",
        numRounds: 0,
    });

    const { data: gameModel } = useGameModel(gameModelId);
    const { data: gameModelRounds } = useGameModelRounds(gameModelId);
    const editGameModel = useGameModelEdit();
    const addGameModel = useGameModelAdd();

    useEffect(() => {
        // set game's data into the local game's state (the editable one)
        if (gameModel) setGameModelLocalState({ name: gameModel.name });
    }, [gameModel]);
    useEffect(() => {
        // set game's data into the local game's state (the editable one)
        if (gameModelRounds) setGameModelLocalState({ numRounds: gameModelRounds.length });
    }, [gameModelRounds]);

    const handleSubmit = async (e) => {
        // edit or create game-model
        e.preventDefault();

        if (gameModelId) {
            // edit
            await editGameModel({ ...gameModelLocalState, id: gameModelId });
        } else {
            // create
            await addGameModel({ ...gameModelLocalState, userId });
            props.history.push(page_admin_game_models);
        }
    };

    const createdByCurrentUser = gameModel?.user === userId;

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>
                            {gameModel ? "Edit" : "Create"} Game Model
                        </h4>
                    </CardHeader>
                    <CardBody>
                        <ValidatorForm onSubmit={handleSubmit}>
                            <GridContainer>
                                <GridItem xs={12} md={4}>
                                    <TextValidator
                                        label="Name *"
                                        onChange={(e) =>
                                            setGameModelLocalState({ name: e.target.value })
                                        }
                                        name="name"
                                        value={gameModelLocalState.name}
                                        validators={["required"]}
                                        errorMessages={["This field is required"]}
                                        disabled={gameModel && !createdByCurrentUser}
                                    />
                                </GridItem>
                                {createdByCurrentUser && (
                                    <GridItem xs={12} md={2}>
                                        <Button type="submit" color="primary">
                                            Save Name
                                        </Button>
                                    </GridItem>
                                )}
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    {!gameModel && (
                                        <TextValidator
                                            type="number"
                                            label="Number of rounds *"
                                            onChange={(e) =>
                                                setGameModelLocalState({
                                                    numRounds: e.target.valueAsNumber,
                                                })
                                            }
                                            name="numRounds"
                                            value={gameModelLocalState.numRounds}
                                            validators={["required", "minNumber:1", "maxNumber:5"]}
                                            errorMessages={[
                                                "This field is required",
                                                "Minimum 1 round allowed",
                                                "Maximum 5 rounds allowed",
                                            ]}
                                        />
                                    )}
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                {!gameModel && (
                                    <GridItem xs={12} md={6}>
                                        <Button type="submit" color="primary">
                                            Create
                                        </Button>
                                    </GridItem>
                                )}
                            </GridContainer>
                        </ValidatorForm>

                        <GridContainer>
                            <GridItem xs={12}>
                                {gameModelRounds?.map((gameModelRound) => (
                                    <Accordion key={gameModelRound.round}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Round {gameModelRound.round}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container className="root" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        spacing={10}
                                                    >
                                                        <Round
                                                            round={gameModelRound}
                                                            model={gameModelId}
                                                            disableEditing={!createdByCurrentUser}
                                                            isLatestRound={
                                                                gameModelRound.round ===
                                                                gameModelRounds.length
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                    <CardFooter />
                </Card>
            </GridItem>
        </GridContainer>
    );
}

import dateFnsFormat from "date-fns/format";

import ic_architect from "@/assets/img/roles/ic_architect.svg";
import ic_pyro from "@/assets/img/roles/ic_pyro.svg";
import ic_builder from "@/assets/img/roles/ic_builder.svg";
import ic_architect_green from "@/assets/img/roles/ic_architect_green.svg";
import ic_pyro_green from "@/assets/img/roles/ic_pyro_green.svg";
import ic_builder_green from "@/assets/img/roles/ic_builder_green.svg";

import * as Constants from "./constants";

const audioBubble = new Audio("/sounds/bubble-sound.mp3");
const audioCamera = new Audio("/sounds/camera-sound.mp3");

/**
 * @param {Date|number|string|null|undefined} date
 * @return {string}
 */
export const formatDateTime = (date) => {
    if (!date) return "";
    if (typeof date === "string") date = Date.parse(date);
    return dateFnsFormat(date, "yyyy-MM-dd hh:mm:ss");
};

/* Admin user token - used to authorization of all admin-user related actions */

/**
 * @return {import("@/service/authApi").AuthUser|undefined}
 */
export const getAdminAuthUser = () => {
    const tokenString = localStorage.getItem("admin_auth_user");
    return tokenString ? JSON.parse(tokenString) : undefined;
};

/**
 * Sets or removes the admin user token
 * @param {import("@/service/authApi").AuthUser} [authUser]
 */
export const setAdminAuthUser = (authUser) => {
    if (authUser) localStorage.setItem("admin_auth_user", JSON.stringify(authUser));
    else localStorage.removeItem("admin_auth_user");
};

/* Player token - used to authorization of all player related actions */

/**
 * Get the player token
 * @return {string|undefined}
 */
export const getPlayerToken = () => {
    return localStorage.getItem("player_token") ?? undefined;
};

/**
 * Sets or removes the player token
 * @param {string|undefined} token
 */
export const setPlayerToken = (token) => {
    if (token) localStorage.setItem("player_token", token);
    else localStorage.removeItem("player_token");
};

/**
 * Image token - used to authorization of the "external" after QR-scan image upload action.
   It's set from the URL params. Keep as static variable,
   it's one time usage, not meant for localStorage
 * @type {string|undefined}
 */
let imageToken;
/**
 * Get the image token (the one used in the standalone image-upload page)
 * @return {string|undefined}
 */
export const getImageToken = () => {
    return imageToken;
};

/**
 * Sets or removes the image token (the one used in the standalone image-upload page)
 * @param {string} token
 */
export const setImageToken = (token) => {
    imageToken = token;
};

/* utils */

/**
 * Get the image url for given role
 * @param {string} [role]
 * @param {boolean} [active]
 * @return {string}
 */
export const getImageForRole = (role, active) => {
    if (!role) return "";

    let imgRoleData;
    switch (role) {
        case Constants.PlayerRole.architect: {
            imgRoleData = active ? ic_architect_green : ic_architect;
            break;
        }
        case Constants.PlayerRole.pyro: {
            imgRoleData = active ? ic_pyro_green : ic_pyro;
            break;
        }
        case Constants.PlayerRole.builder: {
            imgRoleData = active ? ic_builder_green : ic_builder;
            break;
        }
        default:
            throw new Error(`Invalid role ${role}`);
    }
    return imgRoleData;
};

/**
 *
 * @param {{data: {data: ArrayBuffer}}} [image]
 * @return {string}
 */
export const getImageForImageData = (image) => {
    let imgData = "";
    if (image?.data) {
        const base64Flag = "data:image/png;base64,";
        const imageStr = arrayBufferToBase64(image.data.data);

        imgData = base64Flag + imageStr;
    }
    return imgData;
};

/**
 *
 * @param {string} dataUrl
 * @param {string} fileName
 * @return {File}
 */
export const dataURLtoFile = (dataUrl, fileName) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
};

/**
 *
 * @param {ArrayBuffer} buffer
 * @return {string}
 */
export const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
};

export const playAudio = (forCamera = false) =>
    forCamera ? audioCamera.play() : audioBubble.play();

const reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
const reHasRegExpChar = RegExp(reRegExpChar.source);
/**
 * Escapes the `RegExp` special characters "^", "$", "\", ".", "*", "+",
 * "?", "(", ")", "[", "]", "{", "}", and "|" in `string`.
 *
 * @param {string} [str=''] The string to escape.
 * @returns {string} Returns the escaped string.
 */
export const escapeRegExp = (str) =>
    str && reHasRegExpChar.test(str) ? str.replace(reRegExpChar, "\\$&") : str;

/**
 * Check the input is of type string
 * @param {any} value
 * @return {value is string}
 */
export const isString = (value) => typeof value === "string";

/**
 * Get a random item from an array
 * @param {Array<any>} arr
 */
export const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)];

/**
 * Replace the '_id' property with 'id' and return a NEW (not mutated object).
 * @param {any} obj
 * @return {any} new "fixed" object
 */
export const fixId = (obj) => {
    if (obj) {
        obj = { ...obj };
        obj.id = obj._id;
        delete obj._id;
    }
    return obj;
};

export const noop = () => {};

/**
 * A "constant"/"stable" empty array instance.
 * @type {Array<any>}
 */
export const EMPTY_ARRAY = [];

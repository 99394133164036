import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { EMPTY_ARRAY, fixId } from "@/utils/utils";
import avatars from "@/utils/avatars";

/**
 * @typedef {object} RoundRole
 * @property {string} round (round ID)
 * @property {string} role
 */

/**
 * @typedef {object} PlayerRounds
 * @property {string} player   (Player ID)
 * @property {string} nickname
 * @property {string} [avatar]
 * @property {RoundRole[]} rounds
 */

/**
 * @typedef {object} Team
 * @property {string} id
 * @property {string} code
 * @property {string} game   (Game ID)
 * @property {PlayerRounds[]} players
 */

/**
 * @typedef {object} TeamDataForDelete
 * @property {string} id
 * @property {string} gameId
 */
/**
 * @typedef {object} TeamDataForEdit
 * @property {string} id
 * @property {string} code
 */

/**
 * Query for a Teams in a game.
 * @param {string} gameId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Team[]>}
 */
export function useTeamsRoundsRoles(gameId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "Teams", gameId],
        queryFn: () => getTeams(gameId),
        enabled: enabled && !!gameId,
    });
}

/**
 * Delete a Team
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, TeamDataForDelete, unknown>}
 */
export function useTeamDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteTeam,
        onSuccess: (_data, inTeamData) => {
            // update the ["admin", "Teams", gameId] key (e.g. the list with Teams)
            queryClient.setQueryData(["admin", "Teams", inTeamData.gameId], (oldTeams) => {
                if (!oldTeams) return oldTeams;
                return oldTeams.filter((team) => team.id !== inTeamData.id);
            });

            // update the ["admin", "Team", teamId] key (e.g. the concrete Team)
            queryClient.removeQueries({ queryKey: ["admin", "Team", inTeamData.id] });
        },
        meta: { entity: "Team", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a Team - actually just change its code (e.g. name)
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, TeamDataForEdit, unknown>}
 */
export function useTeamEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editTeam,
        onSuccess: (team) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "Teams", team.game], (oldTeams) => {
                if (!oldTeams) return [];

                return oldTeams.map((oldTeam) => {
                    // just update the team code
                    if (oldTeam.id === team.id)
                        return {
                            ...oldTeam,
                            code: team.code,
                        };
                    return oldTeam;
                });
            });
        },
        meta: { entity: "Team", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get Teams function to be used by useQuery
 * @param {string} gameId
 * @return {Promise<Team[]>}
 */
const getTeams = async (gameId) => {
    const { data: teamsData } = await adminApi.get(`/teams/byGame/${gameId}`);

    const teams = [];
    for (const teamData of teamsData) {
        const team = fixId(teamData);
        team.players = await getTeamRoundsRoles(team.id);
        teams.push(team);
    }
    return teams;
};

/**
 * Delete a Team.
 * @param {TeamDataForDelete} inTeamData
 */
const deleteTeam = async (inTeamData) => {
    await adminApi.delete(`/teams/${inTeamData.id}`);
};

/**
 * Edit/Update a Team function to be used by useQuery
 * @param {TeamDataForEdit} inTeamData
 * @return {Promise<Omit<Team, "players">>}
 */
const editTeam = async (inTeamData) => {
    // update the team's name/code
    const { data: teamData } = await adminApi.put(`/teams/${inTeamData.id}`, {
        code: inTeamData.code,
    });
    return fixId(teamData);
};

/**
 * Get teams' rounds roles (e.g. the roles for each player in each round)
 * @param {string} teamId
 * @return {Promise<PlayerRounds[]>}
 */
const getTeamRoundsRoles = async (teamId) => {
    const { data: playersRoundsData } = await adminApi.get(`/player_rounds/byTeam/${teamId}`);
    // [
    //    {
    //      player: "651e9e711cda0610d8e2f3d9",
    //      round : "651e9e5d1cda0610d8e2f3b4",
    //      role : "pyro",
    //      team : "651e9e5d1cda0610d8e2f3af",
    //      game: "651e9e5d1cda0610d8e2f3ad",
    //     },
    //    ....
    // ]

    /**
     * @type {Map<string, { round: string, role: string }[]>}
     */
    const map = new Map();
    playersRoundsData.forEach(({ player, round, role }) => {
        let rounds = map.get(player);
        if (!rounds) {
            rounds = [];
            map.set(player, rounds);
        }
        rounds.push({ round, role });
    });

    const { data: playersData } = await adminApi.get(`/players/byTeam/${teamId}`);

    /**
     * @type {PlayerRounds[]}
     */
    const players = [];

    for (const playerData of playersData) {
        const playerId = playerData._id;
        const playerRounds = {
            player: playerId,
            nickname: playerData.nickname,
            avatar:
                playerData.avatar ||
                (playerData.avatarOrder !== undefined && avatars[playerData.avatarOrder]),
            rounds: map.get(playerId) || EMPTY_ARRAY,
        };
        players.push(playerRounds);
    }

    return players;
};

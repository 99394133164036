import { useQuery } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { getImageForImageData } from "@/utils/utils";

/**
 * @typedef {string} GameModelRoundImage
 */

/**
 * Get the image for a given id for a game model round
 * @param {string} [imageId]
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|GameModelRoundImage>}
 */
export function useGameModelRoundImage(imageId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "GameModelRoundImage", imageId],
        queryFn: () => getGameModelRoundImage(imageId),
        enabled: enabled && !!imageId,
        staleTime: Infinity,
    });
}

/**
 * Get the image for a given id for a game model round function to be used by useQuery
 * @param {string} imageId
 * @return {Promise<GameModelRoundImage>}
 */
const getGameModelRoundImage = async (imageId) => {
    const { data: imageData } = await adminApi.get(`/images/${imageId}`);

    return getImageForImageData(imageData.img);
};

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Table from "../../../../components/Table/Table";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/Button/Button";
import DeletePopup from "../../../../components/Modal/DeletePopup";

import { useLanguages, useLanguageDelete } from "../../cache/languages";

import { rootPathAdmin, page_admin_languages_add } from "@/utils/constants";
import { EMPTY_ARRAY } from "@/utils/utils";

export default function Languages() {
    const { data: languages = EMPTY_ARRAY } = useLanguages();
    const deleteLanguage = useLanguageDelete();

    const [deleteDialogFunction, setDeleteDialogFunction] = useState();

    const openDelete = useCallback((languageId) => {
        setDeleteDialogFunction(() => async () => {
            await deleteLanguage(languageId);
            closeDelete();
        });
    }, []);
    const closeDelete = useCallback(() => setDeleteDialogFunction(undefined), []);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader>
                            <Link to={page_admin_languages_add}>
                                <Button color="primary">Create Language</Button>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Lang", ""]}
                                tableData={languages.map(({ id, language, lang }) => [
                                    <Link to={`${rootPathAdmin}/languages/${id}/edit`}>
                                        {language}
                                    </Link>,
                                    lang,

                                    <Button color="danger" onClick={() => openDelete(id)}>
                                        Delete
                                    </Button>,
                                ])}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <DeletePopup
                handleSubmit={deleteDialogFunction}
                handleClose={closeDelete}
                title="Delete Language"
                message="Are you sure you want to delete this Language?"
                open={!!deleteDialogFunction}
            />
        </div>
    );
}

import { useTranslation } from "react-i18next";

import task_builder from "@/assets/img/player/task_builder.svg";

import { page_player_builder_take_photo, page_player_choose_role } from "@/utils/constants";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import BackButton from "@/layouts/player/components/Button/BackButton";
import { useNavigate } from "@/layouts/player/realtime";

export default function Builder1() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const submitHandler = async (e) => {
        e.preventDefault();
        // go to the next page
        navigate(page_player_builder_take_photo);
    };

    const backHandler = () => {
        navigate(page_player_choose_role);
    };

    return (
        <>
            <div className="task-content">
                <img src={task_builder} alt="" />
                <ol className="role-description-text">
                    <li>{t("builder_task_text_1")}</li>
                    <li>{t("builder_task_text_2")}</li>
                    <li>{t("builder_task_text_3")}</li>
                </ol>
            </div>

            <BackButton onClick={backHandler} label={t("lobby1_waiting_room_back_button")} />

            <form onSubmit={submitHandler}>
                <IgniteButton />
            </form>
        </>
    );
}

import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import "./styles.css";

import { log, error } from "@/logger";

/**
 * @typedef {"Info"|"Success"|"Warning"|"Error"} NotificationType
 */

/**
 * @typedef {"create"|"add"|"delete"|"update"|"duplicate"|"save"|"load"|"upload"|"do"} NotificationAction
 */

/**
 * @typedef {"BonusTask"|"Game"|"Team"|"Round"|"GameModel"|"GameModelRound"|"Language"|"Photo"} NotificationEntity
 */

/**
 * @typedef {object} NotificationOptions
 * @property {NotificationType} [type]
 * @property {NotificationAction} action
 * @property {NotificationEntity} entity
 * @property {string} [subMessage]
 */

/**
 * @type {Map<NotificationAction, [string,string]>}
 */
const verbs = new Map();
verbs.set("create", ["created", "creating"]);
verbs.set("add", ["added", "adding"]);
verbs.set("delete", ["deleted", "deleting"]);
verbs.set("update", ["updated", "updating"]);
verbs.set("duplicate", ["duplicated", "duplicating"]);
verbs.set("save", ["saved", "saving"]);
verbs.set("load", ["loaded", "loading"]);
verbs.set("upload", ["uploaded", "uploading"]);
verbs.set("do", ["done", "doing"]);

/**
 * @type {Map<NotificationEntity, string>}
 */
const entities = new Map();
entities.set("BonusTask", "Bonus Task");
entities.set("GameModel", "Game Model");

/**
 * Show a notification
 * @param {NotificationOptions} param1
 * @param {Error} [errorObj]
 */
export function showNotification({ type = "Info", action, entity, subMessage }, errorObj) {
    const entityStr = entities.get(entity) || entity;
    const message =
        type !== "Error"
            ? `${entityStr} ${verbs.get(action)?.[0]} successfully`
            : `Failed ${verbs.get(action)?.[1]} ${entityStr}`;

    // log
    if (type === "Error") error(message, errorObj);
    else log(message);

    // show it
    showNotificationMessage(message, type, undefined, subMessage);
}

/**
 * Show a notification message
 * @param {import("react").ReactNode} message
 * @param {NotificationType} [type]
 * @param {import("react-toastify").ToastOptions} [options]
 * @param {string} [subMessage]
 */
export function showNotificationMessage(message, type = "Info", options, subMessage) {
    if (subMessage) {
        message = (
            <>
                <div className="Toastify__toast-message">{message}</div>
                <div className="Toastify__toast-submessage">{subMessage}</div>
            </>
        );
    }

    if (type === "Error") toast.error(message, options);
    else if (type === "Warning") toast.warn(message, options);
    else if (type === "Success") toast.success(message, options);
    else toast.info(message, options);
}

/**
 *
 * @returns Wrap the notification library container,
 * so that it would be easier to be switched any time (changes only in this file)
 */
export const NotificationContainer = () => (
    <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        // theme="dark"
        theme="colored"
    />
);

import { useState } from "react";
import { useEffectOnce } from "react-use";
import { useTranslation } from "react-i18next";

import please_wait from "@/assets/img/player/please_wait.svg";

import * as Constants from "@/utils/constants";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import BackButton from "@/layouts/player/components/Button/BackButton";
import BonusTasks from "@/layouts/player/components/BonusTasks";
import { useBonusTasks } from "@/layouts/player/cache/bonusTasks";
import { useGame } from "@/layouts/player/cache/game";
import {
    changePlayerStatus,
    useRunningGame,
    usePlayer,
    useNavigate,
} from "@/layouts/player/realtime";

export default function TaskFinished() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const { data: bonusTasks } = useBonusTasks(player.id);

    const [showBonusTasks, setShowBonusTasks] = useState(false);

    // when ready (task finished then mark the player be in the 'lobby')
    useEffectOnce(() => {
        changePlayerStatus(game.id, runningGame.round, player.id, Constants.PlayerStatus.Finished);

        return () =>
            changePlayerStatus(
                game.id,
                runningGame.round,
                player.id,
                Constants.PlayerStatus.Playing,
            );
    });

    const backHandler = () => {
        const role = player.role;
        let pageTask;
        switch (role) {
            case Constants.PlayerRole.architect:
                pageTask = Constants.page_player_architect_task;
                break;
            case Constants.PlayerRole.pyro:
                pageTask = Constants.page_player_pyro_task;
                break;
            case Constants.PlayerRole.builder:
                pageTask = Constants.page_player_builder_upload_photo;
                break;
            default:
                throw new Error(`Invalid role ${role}`);
        }
        navigate(pageTask);
    };

    return (
        <>
            <div className="task-content">
                <div className="content-img">
                    <img src={please_wait} alt="" />
                    <p className="role-words">{t("lobby2_waiting_room_player_action")}</p>
                </div>
                <label className="role-choose-1">{t("lobby2_waiting_room_you_choose")}</label>

                <BackButton onClick={backHandler} label={t("lobby2_waiting_room_back_button")} />

                {/* show only if there are nay bonus tasks */}
                {!!bonusTasks?.length && !showBonusTasks && (
                    <p className="role-choose-2">{t("lobby2_waiting_room_info")}</p>
                )}
            </div>

            {/* show only if there are nay bonus tasks */}
            {!!bonusTasks?.length &&
                (showBonusTasks ? (
                    <BonusTasks />
                ) : (
                    <IgniteButton onClick={() => setShowBonusTasks(true)} />
                ))}
        </>
    );
}

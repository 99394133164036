import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} GameModelRound
 * @property {string} id
 * @property {string} model (GameModel ID)
 * @property {number} round
 * @property {number} roundTime
 * @property {{id: string, image: string}[]} [formulas]
 * @property {string} [imgArch]
 * @property {string} [imgPyro]
 * @property {string} [imgFinal]
 */

/**
 * @typedef {object} GameModelRoundDataForEdit
 * @property {string} id
 * @property {string} model (GameModel ID)
 * @property {number} roundTime
 * @property {{imgArch?: Blob, imgPyro?: Blob, imgFinal?: Blob, imgFormulaXXX?: Blob}} images
 */

/**
 * @typedef {object} GameModelRoundDataForDelete
 * @property {string} id
 * @property {string} model (GameModel ID)
 */

/**
 * Get all GameModelRound for a game model
 * @param {string} gameModelId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|GameModelRound[]>}
 */
export function useGameModelRounds(gameModelId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "GameModelRound", gameModelId],
        queryFn: () => getGameModelRounds(gameModelId),
        enabled: enabled && !!gameModelId,
    });
}

/**
 * Delete a GameModelRound
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameModelRoundDataForDelete, unknown>}
 */
export function useGameModelRoundDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteGameModelRound,
        onSuccess: (_data, inGameModelRoundData) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(
                ["admin", "GameModelRound", inGameModelRoundData.model],
                (oldGameModelRounds) => {
                    if (!oldGameModelRounds) return oldGameModelRounds;

                    return oldGameModelRounds.filter(({ id }) => id !== inGameModelRoundData.id);
                },
            );
        },
        meta: { entity: "GameModelRound", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a GameModelRound
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameModelRoundDataForEdit, unknown>}
 */
export function useGameModelRoundEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editGameModelRound,
        onSuccess: (gameModelRound) => {
            // on successful mutation update the query cache internally
            queryClient.invalidateQueries({
                queryKey: ["admin", "GameModelRound", gameModelRound.model],
            });
        },
        meta: { entity: "GameModelRound", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get GameModelRounds function to be used by useQuery
 * @param {string} gameModelId
 * @return {Promise<GameModelRound[]>}
 */
const getGameModelRounds = async (gameModelId) => {
    const res = [];

    const { data: gameModelRoundsData } = await adminApi.get(
        `/game_model_rounds/byGameModel/${gameModelId}`,
    );
    for (const item of gameModelRoundsData) {
        res.push(fixId(item));
    }

    return res;
};

/**
 * Delete a GameModelRound
 * @param {GameModelRoundDataForDelete} inGameModelRoundData
 */
const deleteGameModelRound = async (inGameModelRoundData) => {
    await adminApi.delete(`/game_model_rounds/${inGameModelRoundData.id}`);
};

/**
 * Edit/Update a GameModelRound function to be used by useQuery.
 * Actually can change only the 'roundTime', and the images - imgArch/imgPyro/imgFinal/imgFormulaXXX
 * @param {GameModelRoundDataForEdit} inGameModelRoundData
 * @return {Promise<GameModelRound>}
 */
const editGameModelRound = async (inGameModelRoundData) => {
    const { id, roundTime, images } = inGameModelRoundData;

    // this will be the data sent to server
    const updateGameModelRoundData = { roundTime };

    // save images roles as formulas
    for (const [name, blobOrImageId] of Object.entries(images)) {
        if (blobOrImageId instanceof Blob) {
            const formData = new FormData();
            formData.append("file", blobOrImageId);

            // same the role image and get its ID
            const { data: imageData } = await adminApi.post(`/images`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            updateGameModelRoundData[name] = imageData._id;
        } else {
            // this is the case when same old image ID is retained
            updateGameModelRoundData[name] = blobOrImageId;
        }
    }

    // same the GameModelRound
    const { data: gameModelRoundData } = await adminApi.put(
        `/game_model_rounds/${id}`,
        updateGameModelRoundData,
    );

    return fixId(gameModelRoundData);
};

import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";

import { page_player_congrats, page_player_choose_role } from "@/utils/constants";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import Loading from "@/layouts/player/components/Loading";
import { GameState, useGame } from "@/layouts/player/cache/game";
import { useRoundImages } from "@/layouts/player/cache/roundImages";
import { usePlayer, useRunningGame, useNavigate } from "@/layouts/player/realtime";

export default function Check() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();
    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const previousRoundOrder = Math.max(
        1,
        game.state === GameState.Ended ? runningGame.roundOrder : runningGame.roundOrder - 1,
    );

    // get the "previous" round's images, not current round's ones
    const { data: roundImages, isLoading } = useRoundImages(player.team, previousRoundOrder);

    const submitHandler = (e) => {
        e.preventDefault();

        const page =
            game.state === GameState.Ended ? page_player_congrats : page_player_choose_role;
        navigate(page);
    };

    return (
        <>
            <div className="task-content">
                <p className="role-description-text">{t("builder_check_text_1")}</p>
                <p className="role-description-text">{t("builder_check_text_2")}</p>

                <p className="role-description-text">
                    {t("round", { round: previousRoundOrder })}:
                </p>

                <div className="img-final-task">
                    <div className="img-cont">
                        {isLoading ? <Loading /> : <img src={roundImages?.imgFinalSrc} alt="" />}
                    </div>
                    {runningGame.roundOrder !== previousRoundOrder && (
                        <div className="task-description-warning">
                            <div className="img-warning">
                                <WarningIcon />
                            </div>
                            <div>
                                <p>{t("builder_check_warning")}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <form onSubmit={submitHandler}>
                <IgniteButton />
            </form>
        </>
    );
}

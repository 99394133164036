import { io } from "socket.io-client";

import { apiUrl } from "@/config";
import { log } from "@/logger";

// setup the client socket client
export const socketClient = io(apiUrl, {
    // default is polling which auto upgrades to websocket if possible (by client and server)
    // transports: ["websocket", "polling"]
});
// client-side
socketClient.on("connect", () => {
    log(`Socket connected: ${socketClient.id}`);
});

socketClient.on("disconnect", (reason) => {
    log(`Socket disconnected: ${socketClient.id}, reason: ${reason}`);
});
// intercept any events - IN and OUT
socketClient.onAny((event) => {
    log(`Received event: ${event}`);
});
socketClient.onAnyOutgoing((event) => {
    log(`Emitted event: ${event}`);
});

import { useQuery } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} Quote
 * @property {string} id
 * @property {string} quote
 */

/**
 * Query for a quote.
 * @param {string} quoteId
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Quote>}
 */
export function useQuote(quoteId) {
    const isAllowed = !!quoteId;
    return useQuery({
        queryKey: ["player", "Quote", quoteId],
        queryFn: () => getQuote(quoteId),
        enabled: isAllowed,
    });
}

/**
 * Get quote function to be used by useQuery
 * @param {string} quoteId
 * @return {Promise<Quote>}
 */
const getQuote = async (quoteId) => {
    const { data: quoteData } = await playerApi.get(`/quotes/${quoteId}`);
    return fixId(quoteData);
};

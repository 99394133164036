import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/icons-material/Menu";

import Button from "../../../components/Button/Button";

import styles from "@/assets/jss/ibuild-admin-react/components/headerStyle";

import NavbarProfileLinks from "./NavbarProfileLinks";
import { useSidebarOpen } from "@/utils/hooks";

const useStyles = makeStyles(styles);

export default function Header(props) {
    const { color, routes } = props;

    const location = useLocation();

    const classes = useStyles();

    const [_, toggleSidebarOpen] = useSidebarOpen();

    // create navbar title based on route name
    const getTitle = () => {
        let name;
        routes.some((route) => {
            if (location.pathname === route.slug) {
                name = route.name;
                return true;
            }
            return false;
        });

        return name;
    };

    const appBarClasses = classNames({
        [` ${classes[color]}`]: color,
    });
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>
                    <Button color="transparent" className={classes.title}>
                        {getTitle()}
                    </Button>
                </div>
                <Hidden mdDown implementation="css">
                    <NavbarProfileLinks />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => toggleSidebarOpen()}
                        size="large"
                    >
                        <Menu />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    routes: PropTypes.arrayOf(PropTypes.object),
};

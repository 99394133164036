import Popup from "reactjs-popup";
import Timer from "react-compound-timerv2";

export default function MessagePopup(props) {
    const { open, checkpoints, initialTime, text } = props;

    return (
        <Popup open={open} modal closeOnEscape={false} closeOnDocumentClick={false}>
            {() => (
                <div className="popup">
                    <div className="popup-content">
                        <p className="sorry-text">{text}</p>

                        <Timer
                            initialTime={initialTime}
                            direction="backward"
                            startImmediately
                            timeToUpdate={0}
                            checkpoints={checkpoints}
                        >
                            {() => <Timer.Seconds />}
                        </Timer>
                    </div>
                </div>
            )}
        </Popup>
    );
}

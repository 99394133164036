import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";

import task_architect from "@/assets/img/player/task_architect.svg";

import { page_player_task_finished } from "@/utils/constants";

import Loading from "@/layouts/player/components/Loading";
import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import { useRoundImages } from "@/layouts/player/cache/roundImages";
import { useNavigate, useRunningGame, usePlayer } from "@/layouts/player/realtime";

export default function Architect2() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const { data: roundImages, isLoading } = useRoundImages(player.team, runningGame.roundOrder);

    const submitHandler = async (e) => {
        e.preventDefault();
        navigate(page_player_task_finished);
    };

    return (
        <>
            <div className="marginTop" />

            <div className="task-content ">
                <img src={task_architect} alt="" />
                <p className="role-description-text">{t("arch_task_player_action")}</p>
                <div className="img-arch-task">
                    <div className="img-cont">
                        {isLoading ? <Loading /> : <img src={roundImages?.imgArchSrc} alt="" />}
                    </div>
                    <div className="task-description-warning">
                        <div className="img-warning">
                            <WarningIcon />
                        </div>
                        <div>
                            <p>{t("arch_warning_text_1")}</p>
                            <p>{t("arch_warning_text_2")}</p>
                            <p>{t("arch_warning_text_3")}</p>
                        </div>
                    </div>
                </div>
            </div>

            <form onSubmit={submitHandler}>
                <IgniteButton />
            </form>
        </>
    );
}

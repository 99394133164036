import { useRef, useEffect } from "react";
// @ts-ignore - "react-compound-timerv2" is locally forked
import Timer from "react-compound-timerv2";
import ScheduleIcon from "@mui/icons-material/Schedule";

import { useRunningGame } from "@/layouts/player/realtime";
import Loading from "./Loading";

/** @param {number}  value*/
const formatTime = (value) => String(value).padStart(2, "0");

/**
 * @param {{
 * checkpoints: import("@/forked/react-compound-timer/src").Checkpoints
 * }} props
 */
export default function RoundTimer({ checkpoints = [] }) {
    const { data: runningGame } = useRunningGame();
    /**
     * @type {import("react").Ref<import("@/forked/react-compound-timer/src").TimerControls|null>}
     */
    const timerRef = useRef(null);

    useEffect(() => {
        if (runningGame?.roundTime) {
            timerRef.current?.setTime(runningGame.roundTime);
            timerRef.current?.start();
        }
    }, [runningGame?.roundTime]);

    if (!runningGame?.roundTime) return <Loading size={30} />;

    return (
        <>
            <ScheduleIcon />
            &nbsp;
            <Timer
                initialTime={runningGame.roundTime}
                direction="backward"
                startImmediately
                timeToUpdate={0}
                checkpoints={checkpoints}
                ref={timerRef}
            >
                {() => (
                    <>
                        <Timer.Minutes formatValue={formatTime} />
                        :
                        <Timer.Seconds formatValue={formatTime} />
                    </>
                )}
            </Timer>
        </>
    );
}

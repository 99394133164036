import { useCallback, useMemo } from "react";
import { useEffectOnce, useSetState } from "react-use";

import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import InputLabel from "@mui/material/InputLabel";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import GetAppIcon from "@mui/icons-material/GetApp";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Button from "../../../../components/Button/Button";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import CardFooter from "../../../../components/Card/CardFooter";
import CustomSelect from "../../../../components/CustomMaterialUIControls/CustomSelect";

import "./ViewGamePhotos.css";

import { EMPTY_ARRAY, getImageForImageData } from "@/utils/utils";

import {
    useBonusTasksForGame,
    useTeamsForGame,
    usePlayersForGame,
    useRoundsForGame,
    useImageTeamsForGame,
    useImageBonusTasksForGame,
} from "../../cache/photos";
import { subscribeGameHistory } from "@/layouts/admin/realtime";

const useStyles = makeStyles({
    cardTitleWhite: {
        color: "#F36F2B",
        fontWeight: "bold",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    formControlSelect: {
        minWidth: 180,
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset",
    },
    root: {
        width: "100%",
    },
});

const VALUE_ALL = "all";

/**
 * @param {string} photoId
 */
const openInNewTab = (/* string */ photoId) => {
    const photoEl = document.getElementById(photoId);
    if (!photoEl) return;
    const photoWin = window.open();
    photoWin?.document.write(photoEl.outerHTML);
};

/**
 * @param {string} photoId
 */
const downloadPhoto = (photoId) => {
    /**
     * @type {HTMLImageElement|null}
     */
    const photoEl = document.getElementById(photoId);
    if (!photoEl) return;
    const photoName = photoEl.dataset.name || "download";
    const canvas = document.createElement("canvas");
    canvas.width = photoEl.naturalWidth || 400;
    canvas.height = photoEl.naturalHeight || 400;

    const context = canvas.getContext("2d");
    if (!context) return;
    context.drawImage(photoEl, 0, 0);
    canvas.toBlob((blob) => {
        const link = document.createElement("a");
        link.download = photoName;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
    }, "image/png");
};

/**
 *
 * @param {string[]} photos
 */
const downloadPhotos = (photos) => {
    photos.forEach(downloadPhoto);
};

export default function ViewGamePhotos(props) {
    // note this is real current shown/"IN" game's ID
    const gameId = props.match.params.id;

    const classes = useStyles();

    // teams per game
    const { data: teams = EMPTY_ARRAY } = useTeamsForGame(gameId);
    // rounds per game
    const { data: rounds = EMPTY_ARRAY } = useRoundsForGame(gameId);
    // bonusTasks per game
    const { data: bonusTasks = EMPTY_ARRAY } = useBonusTasksForGame(gameId);
    // players per game
    const { data: players = EMPTY_ARRAY } = usePlayersForGame(gameId);

    // get uploaded team images (actually only by builders)
    const { data: imageTeams = EMPTY_ARRAY } = useImageTeamsForGame(gameId);

    // get uploaded bonus tasks images
    const { data: imageBonusTasks = EMPTY_ARRAY } = useImageBonusTasksForGame(gameId);

    // listen to realtime Game "history"/"photo" events
    useEffectOnce(() => subscribeGameHistory(gameId));

    // this is the local dynamic state
    const [filters, setFilters] = useSetState({
        team: VALUE_ALL,
        round: VALUE_ALL,
        bonusTask: VALUE_ALL,
        player: VALUE_ALL,
    });

    const filteredImageTeams = useMemo(() => {
        let filtered = imageTeams;

        // filter by team
        if (filters.team !== VALUE_ALL)
            filtered = filtered.filter(({ team }) => filters.team === team.id);

        // filter by round
        if (filters.round !== VALUE_ALL)
            filtered = filtered.map((teamRounds) => {
                const { rounds } = teamRounds;
                return {
                    ...teamRounds,
                    rounds: rounds.filter(({ round }) => filters.round === round),
                };
            });

        return filtered;
    }, [imageTeams, filters.team, filters.round]);

    const filteredImageBonusTasks = useMemo(() => {
        let filtered = imageBonusTasks;

        // filter by player
        if (filters.player !== VALUE_ALL)
            filtered = filtered.filter(({ id }) => filters.player === id);

        // filter by bonus task
        if (filters.bonusTask !== VALUE_ALL)
            filtered = filtered.map((playerImageBonusTasks) => {
                const { bonusTasks } = playerImageBonusTasks;
                return {
                    ...playerImageBonusTasks,
                    bonusTasks: bonusTasks.filter(
                        ({ bonusTask }) => filters.bonusTask === bonusTask.id,
                    ),
                };
            });
        return filtered;
    }, [imageBonusTasks, filters.player, filters.bonusTask]);

    const handleDownloadImageTeams = useCallback(() => {
        /**
         * @type {string[]}
         */
        const downloads = [];
        filteredImageTeams.forEach(({ rounds }) => {
            rounds.forEach(({ photos }) => {
                photos.forEach((photo) => downloads.push(photo.name));
            });
        });
        downloadPhotos(downloads);
    }, [filteredImageTeams]);

    const handleDownloadImageBonusTasks = useCallback(() => {
        /**
         * @type {string[]}
         */
        const downloads = [];
        filteredImageBonusTasks.forEach(({ bonusTasks, nickname }) => {
            bonusTasks.forEach(({ bonusTask }) => {
                downloads.push(createImageBonusTaskPhotoId(nickname, bonusTask));
            });
        });
        downloadPhotos(downloads);
    }, [filteredImageBonusTasks]);

    return (
        <div className="photo-management">
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Game Photo Gallery</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <div className={classes.root}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>
                                                Photos uploaded by teams' builders
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GridContainer>
                                                <GridItem xs={12} md={6}>
                                                    <FormControl
                                                        className={classes.formControlSelect}
                                                    >
                                                        <InputLabel shrink htmlFor="filter_team">
                                                            Filter By Team
                                                        </InputLabel>
                                                        <CustomSelect
                                                            label="Filter By Team"
                                                            inputProps={{
                                                                id: "filter_team",
                                                            }}
                                                            name="filter_team"
                                                            value={filters.team}
                                                            onChange={(e) =>
                                                                setFilters({
                                                                    team: e.target.value,
                                                                })
                                                            }
                                                            fullWidth
                                                        >
                                                            <MenuItem value={VALUE_ALL}>
                                                                All
                                                            </MenuItem>
                                                            {teams.map(({ id, code }) => (
                                                                <MenuItem key={id} value={id}>
                                                                    {code}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomSelect>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={6}>
                                                    <FormControl
                                                        className={classes.formControlSelect}
                                                    >
                                                        <InputLabel shrink htmlFor="filter_round">
                                                            Filter By Round
                                                        </InputLabel>
                                                        <CustomSelect
                                                            label="Filter By Round"
                                                            inputProps={{
                                                                id: "filter_round",
                                                            }}
                                                            name="filter_round"
                                                            value={filters.round}
                                                            onChange={(e) =>
                                                                setFilters({
                                                                    round: e.target.value,
                                                                })
                                                            }
                                                            fullWidth
                                                        >
                                                            <MenuItem value={VALUE_ALL}>
                                                                All
                                                            </MenuItem>
                                                            {rounds.map(({ id, round }) => (
                                                                <MenuItem key={id} value={round}>
                                                                    {round}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomSelect>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={6}>
                                                    <Button
                                                        color="primary"
                                                        size="lg"
                                                        startIcon={<GetAppIcon />}
                                                        onClick={handleDownloadImageTeams}
                                                    >
                                                        Download Team Photos
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>

                                            <GridItem xs={12}>
                                                <ul className="teams-list">
                                                    {filteredImageTeams.map((item) => (
                                                        <li key={item.team.id}>
                                                            <ImageTeams {...item} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </GridItem>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>Bonus Tasks Photos</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <GridContainer>
                                                <GridItem xs={12} md={6}>
                                                    <FormControl
                                                        className={classes.formControlSelect}
                                                    >
                                                        <InputLabel shrink htmlFor="filter_team">
                                                            Filter By Bonus Task
                                                        </InputLabel>
                                                        <CustomSelect
                                                            label="Filter By Bonus Task"
                                                            inputProps={{
                                                                id: "filter_bonus_task",
                                                            }}
                                                            name="filter_bonus_task"
                                                            value={filters.bonusTask}
                                                            onChange={(e) =>
                                                                setFilters({
                                                                    bonusTask: e.target.value,
                                                                })
                                                            }
                                                            fullWidth
                                                        >
                                                            <MenuItem value={VALUE_ALL}>
                                                                All
                                                            </MenuItem>
                                                            {bonusTasks.map(({ id, name }) => (
                                                                <MenuItem key={id} value={id}>
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomSelect>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={6}>
                                                    <FormControl
                                                        className={classes.formControlSelect}
                                                    >
                                                        <InputLabel shrink htmlFor="filter_team">
                                                            Filter By Player
                                                        </InputLabel>
                                                        <CustomSelect
                                                            label="Filter By Player"
                                                            inputProps={{
                                                                id: "filter_player",
                                                            }}
                                                            name="filter_player"
                                                            value={filters.player}
                                                            onChange={(e) =>
                                                                setFilters({
                                                                    player: e.target.value,
                                                                })
                                                            }
                                                            fullWidth
                                                        >
                                                            <MenuItem value={VALUE_ALL}>
                                                                All
                                                            </MenuItem>
                                                            {players.map(({ id, nickname }) => (
                                                                <MenuItem key={id} value={id}>
                                                                    {nickname}
                                                                </MenuItem>
                                                            ))}
                                                        </CustomSelect>
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem xs={12} md={6}>
                                                    <Button
                                                        color="primary"
                                                        size="lg"
                                                        startIcon={<GetAppIcon />}
                                                        onClick={handleDownloadImageBonusTasks}
                                                    >
                                                        Download Bonus-Tasks Photos
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>

                                            <GridItem xs={12}>
                                                <ul className="teams-list">
                                                    <li key="teams-list-item">
                                                        <div className="team-details open">
                                                            <ul className="teams-rounds-list">
                                                                {filteredImageBonusTasks.map(
                                                                    (item) => (
                                                                        <li key={item.id}>
                                                                            <ImageBonusTasks
                                                                                {...item}
                                                                            />
                                                                        </li>
                                                                    ),
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </GridItem>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </GridContainer>
                        </CardBody>
                        <CardFooter />
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}
export class ViewGameHistory_ {
    getFilteredPhotos = () => {
        let { filter_team, filter_round } = this.props;
        const { uploaded_photos } = this.props;

        if (filter_team === undefined) {
            filter_team = "all";
        }
        if (filter_round === undefined) {
            filter_round = "all";
        }

        const filtered_uploaded_photos =
            filter_team === "all"
                ? uploaded_photos // all
                : uploaded_photos.filter((item) => item.team._id === filter_team); // by team

        let data_uploadedPhotos = filtered_uploaded_photos;

        data_uploadedPhotos =
            filter_round === "all"
                ? data_uploadedPhotos // all OR by team
                : filtered_uploaded_photos.map((item) => {
                      const obj = {
                          team: item.team,
                          rounds: item.rounds.filter((el) => el.round === filter_round),
                      };

                      return obj;
                  }); // all OR by team OR by round
        return { filteredUploadedPhotos: data_uploadedPhotos, filter_team, filter_round };
    };
}

/**
 * @param {import ("../../cache/photos").ImageBonusTasks} props
 */
function ImageBonusTasks(props) {
    const { bonusTasks, nickname } = props;

    // if not images then don't render anything
    if (!bonusTasks.length) return undefined;

    return (
        <>
            <div className="round-name">{nickname}</div>
            <div>
                <ul className="teams-rounds-players-list">
                    {bonusTasks.map(({ photo, bonusTask }) => {
                        const photoSrc = getImageForImageData(photo);
                        const photoId = createImageBonusTaskPhotoId(nickname, bonusTask);
                        const photoName = photo.name;
                        const photoLabel = `${nickname}: ${bonusTask.name}`;
                        return (
                            <li key={bonusTask.id}>
                                <div className="bonus-task-data">
                                    <div>{bonusTask.name}</div>
                                    <div className="uploaded-model-image">
                                        <div className="image-upload-preview">
                                            <img
                                                id={photoId}
                                                src={photoSrc}
                                                alt="img"
                                                width="200px"
                                                data-name={photoName}
                                            />
                                        </div>
                                        <label>{photoLabel}</label>
                                        <div className="photo-actions">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                title="Download image"
                                                onClick={() => downloadPhoto(photoId)}
                                                className="copy-url"
                                            >
                                                <GetAppIcon />
                                            </Button>

                                            <Button
                                                color="primary"
                                                size="sm"
                                                title="Open in new tab"
                                                onClick={() => openInNewTab(photoId)}
                                                className="copy-url"
                                            >
                                                <OpenInBrowserIcon />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

/**
 * @param {import ("../../cache/photos").ImageTeams} props
 */
function ImageTeams(props) {
    const { team, rounds } = props;
    return (
        <>
            <div className="team-data">
                <GridContainer>
                    <GridItem xs={12}>
                        <label>Team {team.code}</label>
                    </GridItem>
                </GridContainer>
            </div>

            <div className="team-details open">
                <ul className="teams-rounds-list">
                    {rounds.map(({ photos, round }) => {
                        return (
                            <li key={round}>
                                <div className="round-name">Round {round}</div>
                                <div>
                                    <ul className="teams-rounds-players-list">
                                        {photos.map((photo) => {
                                            const photoSrc = getImageForImageData(photo);
                                            const photoName = photo.name;

                                            return (
                                                <li key={photoName}>
                                                    <div className="team-photo-data">
                                                        <div className="uploaded-model-image">
                                                            <div className="image-upload-preview">
                                                                <img
                                                                    id={photoName}
                                                                    src={photoSrc}
                                                                    alt="img"
                                                                    width="200px"
                                                                    data-name={photoName}
                                                                />
                                                            </div>
                                                            <label>{photoName}</label>
                                                            <div className="photo-actions">
                                                                <Button
                                                                    color="primary"
                                                                    size="sm"
                                                                    title="Download image"
                                                                    onClick={() =>
                                                                        downloadPhoto(photoName)
                                                                    }
                                                                    className="copy-url"
                                                                >
                                                                    <GetAppIcon />
                                                                </Button>

                                                                <Button
                                                                    color="primary"
                                                                    size="sm"
                                                                    title="Open in new tab"
                                                                    onClick={() =>
                                                                        openInNewTab(photoName)
                                                                    }
                                                                    className="copy-url"
                                                                >
                                                                    <OpenInBrowserIcon />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

/**
 * Create a element ID for the <img> tag
 * @param {string} nickname
 * @param {{name: string}} bonusTask
 * @return {string}
 */
const createImageBonusTaskPhotoId = (nickname, bonusTask) => `${nickname}_${bonusTask.name}`;

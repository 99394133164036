import { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";

import GridItem from "@/components/Grid/GridItem";
import GridContainer from "@/components/Grid/GridContainer";
import Table from "@/components/Table/Table";
import Card from "@/components/Card/Card";
import CardHeader from "@/components/Card/CardHeader";
import CardBody from "@/components/Card/CardBody";
import Button from "@/components/Button/Button";
import DeletePopup from "@/components/Modal/DeletePopup";

import "./AddEditGame.css";

import { EMPTY_ARRAY, formatDateTime } from "@/utils/utils";
import { rootPathAdmin, page_admin_games_add } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";

import { useGames, useGameDelete } from "@/layouts/admin/cache/games";
import { subscribeGames } from "@/layouts/admin/realtime";

export default function GamesFn() {
    /**
     * @type {string}
     */
    // @ts-ignore - it's auth for sure
    const userId = useAuthUserId();

    /**
     * @type {{data: import("@/layouts/admin/cache/games").Game[]}}
     */
    // @ts-ignore - it defaults to the generic EMPTY_ARRAY
    const { data: games = EMPTY_ARRAY } = useGames(userId);
    const deleteGame = useGameDelete();

    useEffect(() => {
        return subscribeGames(games.map(({ id }) => id));
    }, [games]);

    const [deleteDialogFunction, setDeleteDialogFunction] = useState();

    const openDelete = useCallback((id) => {
        // to store a function using useState() we need to wrap it in anonymous function
        // as useState() already accepts a "callback" version (the useState((prevState) => {}) )
        setDeleteDialogFunction(() => async () => {
            await deleteGame(id);
            closeDelete();
        });
    }, []);

    const closeDelete = useCallback(() => {
        setDeleteDialogFunction(undefined);
    }, []);

    return (
        <div>
            <GridContainer>
                <GridItem>
                    <Card>
                        <CardHeader>
                            <Link to={page_admin_games_add}>
                                <Button color="primary">Create Game</Button>
                            </Link>
                        </CardHeader>
                        <CardBody>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                    "Name",
                                    "Game Model",
                                    "Client",
                                    "createdBy",
                                    "started",
                                    "ended",
                                    "",
                                ]}
                                tableData={games.map(
                                    ({
                                        id,
                                        name,
                                        client,
                                        dateStarted,
                                        dateEnded,
                                        gameModelName,
                                        userFullName,
                                    }) => [
                                        <Link to={`${rootPathAdmin}/games/${id}/edit`}>
                                            {name}
                                        </Link>,
                                        gameModelName,
                                        client,
                                        userFullName,
                                        formatDateTime(dateStarted),
                                        formatDateTime(dateEnded),
                                        <Button
                                            color="danger"
                                            disabled={!!dateStarted && !dateEnded}
                                            startIcon={<DeleteIcon />}
                                            onClick={() => openDelete(id)}
                                        >
                                            Delete
                                        </Button>,
                                    ],
                                )}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <DeletePopup
                title="Delete Game"
                message="Are you sure you want to delete this Game?"
                open={!!deleteDialogFunction}
                handleSubmit={deleteDialogFunction}
                handleClose={closeDelete}
            />
        </div>
    );
}

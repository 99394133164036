import Loading from "@/layouts/player/components/Loading";
import RoleImg from "@/layouts/player/components/RoleImg";
import { useRunningGame, usePlayer } from "@/layouts/player/realtime";

export default function TeamPlayersRounds() {
    const { data: runningGame } = useRunningGame();

    const { data: player } = usePlayer();

    // nothing to render
    if (!runningGame || !player) return <Loading size={50} />;

    return (
        <ul className="players-list">
            {runningGame.players.map(({ id, team, nickname, avatar, role }) => {
                if (player.team !== team) return false;

                return (
                    <li key={id}>
                        <div className="player-data">
                            <div className="left">
                                <img className="avatar" src={avatar} alt="" />
                                <p className="name">{nickname}</p>
                            </div>
                            <div className="right">
                                <RoleImg role={role} />
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

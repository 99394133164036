import { Link } from "react-router-dom";
import { useSetState } from "react-use";

// @ts-ignore - suppress the "Cannot find module ... " error, it's ok
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import FormControl from "@mui/material/FormControl";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Card from "../../../../components/Card/Card";
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import Button from "../../../../components/Button/Button";
import CardFooter from "../../../../components/Card/CardFooter";

import { rootPathAdmin, page_admin_register } from "@/utils/constants";
import { showNotificationMessage } from "@/notifications";
import { signin } from "@/service/authApi";

export default function Signin({ history }) {
    const [localState, setLocalState] = useSetState({
        username: "",
        password: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signin(localState);

            // go to "/admin"
            history.push(rootPathAdmin);
        } catch (error) {
            showNotificationMessage(error.message, "Error");
        }
    };

    return (
        <ValidatorForm onSubmit={handleSubmit}>
            <GridContainer>
                <GridItem xs={12} sm={8}>
                    <Card>
                        <CardHeader color="info">
                            <h4>Login account</h4>
                        </CardHeader>

                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12}>
                                    <FormControl>
                                        <TextValidator
                                            name="username"
                                            label="Username *"
                                            value={localState.username}
                                            onChange={(e) =>
                                                setLocalState({ username: e.target.value })
                                            }
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} md={8}>
                                    <FormControl>
                                        <TextValidator
                                            type="password"
                                            name="password"
                                            label="Password *"
                                            value={localState.password}
                                            onChange={(e) =>
                                                setLocalState({ password: e.target.value })
                                            }
                                            validators={["required"]}
                                            errorMessages={["This field is required"]}
                                        />
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            {/* TODO: temporary disable "Create/register admins" */}
                            {/* <GridContainer>
                                <GridItem xs={12} md={8}>
                                    <FormControl>
                                        <Link to={page_admin_register}>Create your account</Link>
                                    </FormControl>
                                </GridItem>
                            </GridContainer> */}
                        </CardBody>

                        <CardFooter>
                            <Button color="primary" size="lg" type="submit">
                                Signin
                            </Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        </ValidatorForm>
    );
}

import { useQuery, useMutation } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { queryClient } from "@/cache";

/**
 * @typedef {object} StatsGameControl
 * @property {string} game     (Game ID)
 */

/**
 * @typedef {object} StatsGames
 * @property {number} games          (all games count)
 * @property {number} gamesStarted   (started games count, this may include some ended also)
 * @property {number} gamesEnded     (ended games count)
 */

/**
 * Query for the StatsGameControls list.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|StatsGameControl[]>}
 */
export function useStatsRunningGames() {
    return useQuery({
        queryKey: ["admin", "Stats", "GameControl"],
        queryFn: getStatsGameControls,
    });
}

/**
 * Query for the count of GamesModels.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|number>}
 */
export function useStatsGameModels() {
    return useQuery({
        queryKey: ["admin", "Stats", "GameModels"],
        queryFn: getStatsGameModels,
    });
}

/**
 * Query for the count of Games.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|StatsGames>}
 */
export function useStatsGames() {
    return useQuery({
        queryKey: ["admin", "Stats", "Games"],
        queryFn: getStatsGames,
    });
}

/**
 * Query for the count of Players.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|number>}
 */
export function useStatsPlayers() {
    return useQuery({
        queryKey: ["admin", "Stats", "Players"],
        queryFn: getStatsPlayers,
    });
}

/**
 * Refetch current Stats caches
 */
export function refetchStats() {
    queryClient.refetchQueries({
        queryKey: ["admin", "Stats"],
    });
}

/**
 * Get count of GameModels function to be used by useQuery
 * @return {Promise<number>}
 */
const getStatsGameModels = async () => {
    const { data: stats } = await adminApi.get(`/stats/game_models`);
    return stats.count;
};

/**
 * Get count of Players function to be used by useQuery
 * @return {Promise<number>}
 */
const getStatsPlayers = async () => {
    const { data: stats } = await adminApi.get(`/stats/players`);
    return stats.count;
};

/**
 * Get StatsGameControls function to be used by useQuery
 * @return {Promise<StatsGameControl[]>}
 */
const getStatsGameControls = async () => {
    const { data } = await adminApi.get(`/control`);

    return data;
};

/**
 * Get StatsGames function to be used by useQuery
 * @return {Promise<StatsGames>}
 */
const getStatsGames = async () => {
    const { data: stats } = await adminApi.get(`/stats/games`);
    return {
        games: stats.count,
        gamesStarted: stats.countStarted,
        gamesEnded: stats.countEnded,
    };
};

import { useQuery } from "@tanstack/react-query";

import playerApi from "@/service/playerApi";
import i18n from "@/i18n";
import { fixId, noop } from "@/utils/utils";

import { useGame } from "./game";
import { useLanguage } from "@/utils/hooks";

/**
 * @typedef {object} Language
 * @property {string} id
 * @property {string} lang      (like "fr")
 * @property {string} language  (like "French")
 * @property {{data: {data: ArrayBuffer}}} flag      (data buffer)
 */

/**
 * Query for the Languages list.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Language[]>}
 */
export function useLanguages() {
    const { validateLanguage } = useLanguage();

    const { data: game } = useGame();

    const isAllowed = !!game;
    return useQuery({
        queryKey: ["player", "Languages"],
        queryFn: () => getLanguages(game.languages, validateLanguage),
        enabled: isAllowed,
        // this is static so no need to be re-fetched
        staleTime: Infinity,
    });
}

/**
 * Get Languages function to be used by useQuery
 * @param {string[]|undefined} langIds
 * @param {() => void} validateLanguage
 * @return {Promise<Language[]>}
 */
const getLanguages = async (langIds, validateLanguage) => {
    const languages = [];

    if (langIds) {
        for (const langId of langIds) {
            let { data: langData } = await playerApi.get(`/languages/${langId}`);

            i18n.addLanguage(langData.lang, langData.dictionary);

            langData = fixId(langData);
            // no need to store the 'dictionary' it's for i18n needs
            delete langData.dictionary;

            languages.push(langData);
        }
    }

    validateLanguage();

    return languages;
};

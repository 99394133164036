import avatar1 from "../assets/img/avatars/1.svg";
import avatar2 from "../assets/img/avatars/2.svg";
import avatar3 from "../assets/img/avatars/3.svg";
import avatar4 from "../assets/img/avatars/4.svg";
import avatar5 from "../assets/img/avatars/5.svg";
import avatar6 from "../assets/img/avatars/6.svg";
import avatar7 from "../assets/img/avatars/7.svg";
import avatar8 from "../assets/img/avatars/8.svg";
import avatar9 from "../assets/img/avatars/9.svg";
import avatar10 from "../assets/img/avatars/10.svg";
import avatar11 from "../assets/img/avatars/11.svg";
import avatar12 from "../assets/img/avatars/12.svg";
import avatar13 from "../assets/img/avatars/13.svg";
import avatar14 from "../assets/img/avatars/14.svg";
import avatar15 from "../assets/img/avatars/15.svg";
import avatar16 from "../assets/img/avatars/16.svg";
import avatar17 from "../assets/img/avatars/17.svg";
import avatar18 from "../assets/img/avatars/18.svg";
import avatar19 from "../assets/img/avatars/19.svg";
import avatar20 from "../assets/img/avatars/20.svg";

const avatars = [
    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    avatar7,
    avatar8,
    avatar9,
    avatar10,
    avatar11,
    avatar12,
    avatar13,
    avatar14,
    avatar15,
    avatar16,
    avatar17,
    avatar18,
    avatar19,
    avatar20,
];

export default avatars;

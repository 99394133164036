import { Link } from "react-router-dom";

import { EMPTY_ARRAY, formatDateTime } from "@/utils/utils";
import { rootPathAdmin } from "@/utils/constants";
import { useAuthUserId } from "@/service/authApi";

import GridItem from "../../../../components/Grid/GridItem";
import GridContainer from "../../../../components/Grid/GridContainer";
import Table from "../../../../components/Table/Table";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";

import { useGames } from "../../cache/games";

export default function GamesPhotos() {
    const userId = useAuthUserId();
    // @ts-ignore userId is known to be a valid string in this case
    const { data: games = EMPTY_ARRAY } = useGames(userId);

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={[
                                "Name",
                                "Game Model",
                                "Client",
                                "createdBy",
                                "started",
                                "ended",
                                "",
                            ]}
                            tableData={games.map((game) => [
                                <Link to={`${rootPathAdmin}/photo-management/${game.id}/view`}>
                                    {game.name}
                                </Link>,
                                game.gameModelName,
                                game.client,
                                game.userFullName,
                                formatDateTime(game.date_started),
                                formatDateTime(game.date_ended),
                            ])}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
}

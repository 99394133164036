import { createRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useEffectOnce } from "react-use";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@mui/styles";

import styles from "@/assets/jss/ibuild-admin-react/layouts/adminStyle";
import bgImage from "@/assets/img/admin/sidebar.jpg";
import logo from "@/assets/img/logo.png";

import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";

import { rootPathAdmin, page_admin_signin, page_admin_dashboard } from "@/utils/constants";
import routes from "./routes";
import { useAuthUserId } from "@/service/authApi";

export { rootPathAdmin };

const useStyles = makeStyles(styles);

export default function Admin() {
    const userId = useAuthUserId();

    const classes = useStyles();

    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = createRef();

    // initialize and destroy the PerfectScrollbar plugin
    useEffectOnce(() => {
        /**
         * @type {PerfectScrollbar|undefined}
         */
        let ps;
        // apply the PerfectScrollbar only for IE
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
            document.body.style.overflow = "hidden";
        }

        return () => ps?.destroy();
    });

    return (
        <div className={classes.wrapper}>
            {userId && (
                <Sidebar
                    routes={routes.filter(({ isSidebar }) => !!isSidebar)}
                    logoText=""
                    logo={logo}
                    image={bgImage}
                    color="orange"
                />
            )}
            <div className={classes.mainPanel} ref={mainPanel}>
                {userId && <Navbar routes={routes} />}

                <div className={classes.content}>
                    <div className={classes.container}>
                        <Switch>
                            {routes
                                .filter(({ isNotAuth = false }) => !userId === isNotAuth)
                                .map(({ slug, component: Component }) => (
                                    <Route
                                        key={slug}
                                        path={slug}
                                        render={(routerProps) => (
                                            <div className="splash">
                                                <Component {...routerProps} />
                                            </div>
                                        )}
                                    />
                                ))}
                            <Redirect
                                from={rootPathAdmin}
                                to={userId ? page_admin_dashboard : page_admin_signin}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
}

// // A wrapper for <Route> that redirects to the login
// // screen if you're not yet authenticated.
// function AuthRoute({ children, ...rest }) {
//     const userId = useAuthUserId();
//
//     return (
//         <Route
//             {...rest}
//             render={({ location }) =>
//                 userId ? (
//                     children
//                 ) : (
//                     <Redirect
//                         to={{
//                             pathname: `${rootPath}/signin`,
//                             state: { from: location },
//                         }}
//                     />
//                 )
//             }
//         />
//     );
// }

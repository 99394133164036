import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { fixId } from "@/utils/utils";

/**
 * @typedef {object} GameModel
 * @property {string} id
 * @property {string} name
 * @property {string} user   (the user ID)
 * @property {string} userFullName  (the user full name)
 */

/**
 * @typedef {object} GameModelDataForDuplicate
 * @property {string} id
 * @property {string} userId
 */

/**
 * @typedef {object} GameModelDataForEdit
 * @property {string} id
 * @property {string} name
 */

/**
 * @typedef {object} GameModelDataForAdd
 * @property {string} userId
 * @property {number} numRounds
 */

/**
 * Query for the GameModels list.
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|GameModel[]>}
 */
export function useGameModels(enabled = true) {
    return useQuery({
        queryKey: ["admin", "GameModel"],
        queryFn: getGameModels,
        enabled,
    });
}

/**
 * Get a GameModel
 * @param {string} gameModelId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|GameModel>}
 */
export function useGameModel(gameModelId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "GameModel", gameModelId],
        queryFn: () => getGameModel(gameModelId),
        enabled: enabled && !!gameModelId,
    });
}

/**
 * Delete a GameModel
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, string, unknown>}
 */
export function useGameModelDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteGameModel,
        onSuccess: (_data, gameModelId) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "GameModel"], (oldGameModels) => {
                if (!oldGameModels) return oldGameModels;

                return oldGameModels.filter(({ id }) => id !== gameModelId);
            });
        },
        meta: { entity: "GameModel", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Duplicate a GameModel
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameModelDataForDuplicate, unknown>}
 */
export function useGameModelDuplicate() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: duplicateGameModel,
        onSuccess: (gameModel) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "GameModel"], (oldGameModels) => {
                if (!oldGameModels) oldGameModels = [];

                return [gameModel, ...oldGameModels];
            });
        },
        meta: { entity: "GameModel", action: "duplicate" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Create/add a GameModel
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameModelDataForAdd, unknown>}
 */
export function useGameModelAdd() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addGameModel,
        onSuccess: (gameModel) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "GameModel"], (oldGameModels) => {
                if (!oldGameModels) oldGameModels = [];

                return [gameModel, ...oldGameModels];
            });
        },
        meta: { entity: "GameModel", action: "create" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a GameModel
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, GameModelDataForEdit, unknown>}
 */
export function useGameModelEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editGameModel,
        onSuccess: (gameModel) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "GameModel"], (oldGameModels) => {
                if (!oldGameModels) oldGameModels = [];

                let found = false;
                const newGameModels = oldGameModels.map((oldGameModel) => {
                    if (oldGameModel.id === gameModel.id) {
                        found = true;
                        // merge - let the new overwrite the existing
                        return { ...oldGameModel, ...gameModel };
                    }
                    return oldGameModel;
                });
                // just in case a GameModel that is not present is edited then add it
                if (!found) newGameModels.push(gameModel);

                return newGameModels;
            });
        },
        meta: { entity: "GameModel", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get GameModels function to be used by useQuery
 * @return {Promise<GameModel[]>}
 */
const getGameModels = async () => {
    const res = [];

    const { data: gameModelsData } = await adminApi.get("/game_models");

    for (const item of gameModelsData) {
        // attach the full user name
        const { data: userData } = await adminApi.get(`/users/${item.user}`);
        item.userFullName = `${userData.firstName} ${userData.lastName}`;

        res.push(fixId(item));
    }

    return res;
};

/**
 * Get GameModel function to be used by useQuery
 * @param {string} gameModelId
 * @return {Promise<GameModel>}
 */
const getGameModel = async (gameModelId) => {
    const { data: gameModel } = await adminApi.get(`/game_models/${gameModelId}`);

    return fixId(gameModel);
};

/**
 * Delete a GameModel - it will delete also the internal GameModelRounds and Images
 * @param {string} gameModelId
 */
const deleteGameModel = async (gameModelId) => {
    // will delete also the internal GameModelRounds and Images
    await adminApi.delete(`/game_models/${gameModelId}`);
};

/**
 * Duplicate a GameModel - will duplicate the internal GameModelRounds and Images
 * @param {GameModelDataForDuplicate} inGameModelData
 */
const duplicateGameModel = async (inGameModelData) => {
    let { data: gameModelData } = await adminApi.post(`/game_models/${inGameModelData.id}/copy`, {
        userId: inGameModelData.userId,
    });

    gameModelData = fixId(gameModelData);

    // attach the full user name
    const { data: userData } = await adminApi.get(`/users/${inGameModelData.userId}`);
    gameModelData.userFullName = `${userData.firstName} ${userData.lastName}`;

    return gameModelData;
};

/**
 * Add/Create a GameModel function to be used by useQuery
 * @param {GameModelDataForAdd} inGameModelData
 */
const addGameModel = async (inGameModelData) => {
    // create the game model
    let { data: gameModelData } = await adminApi.post(`/game_models`, inGameModelData);

    gameModelData = fixId(gameModelData);

    // attach the full user name
    const { data: userData } = await adminApi.get(`/users/${inGameModelData.userId}`);
    gameModelData.userFullName = `${userData.firstName} ${userData.lastName}`;

    // create the game model rounds
    for (let round = 1; round <= inGameModelData.numRounds; round++) {
        await adminApi.post(`/game_model_rounds`, {
            round: round,
            model: gameModelData.id,
        });
    }

    return gameModelData;
};

/**
 * Edit/Update a GameModel function to be used by useQuery.
 * Actually only the name is changed here.
 * @param {GameModelDataForEdit} inGameModelData
 */
const editGameModel = async (inGameModelData) => {
    const { data: gameModelData } = await adminApi.put(
        `/game_models/${inGameModelData.id}`,
        inGameModelData,
    );
    return fixId(gameModelData);
};

import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// configure the MUI theme
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
// this is the MUI way to normalize css (like importing normalize.css)
import CssBaseline from "@mui/material/CssBaseline";

import theme from "./theme";

import "./assets/css/ibuild.css";

import { CacheProvider } from "@/cache";

import Logo from "@/layouts/logo";
import Admin, { rootPathAdmin } from "@/layouts/admin";
import Player, { rootPathPlayer } from "@/layouts/player";
import { NotificationContainer } from "@/notifications";

// and styling to the console
// import "./utils/consoleStyle";

function App() {
    return (
        <CacheProvider>
            {/* injectFirst will make the MUI inject it's CSS-in-JS classes before ours CSS */}
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />

                    {/* The notification container */}
                    <NotificationContainer />

                    {/* Routing */}
                    <Router>
                        <Switch>
                            {/* admin route */}
                            <Route path={rootPathAdmin} component={Admin} />

                            {/* player route */}
                            <Route path={rootPathPlayer} component={Player} />

                            {/* logo route */}
                            <Route exact path="/" component={Logo} />

                            {/* Fallback - go to "/" and show the Logo */}
                            <Route path="*" render={() => <Redirect to="/" />} />
                        </Switch>
                    </Router>
                </ThemeProvider>
            </StyledEngineProvider>
        </CacheProvider>
    );
}

// console.style(
//     console.colors.blue("console.style") + " is so " + console.colors.yellow("great") + "!",
//     console.colors.blue("rf"),
// );

export default App;

import { useState } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Hidden from "@mui/material/Hidden";
import Poppers from "@mui/material/Popper";
import Person from "@mui/icons-material/Person";
// import Divider from "@mui/material/Divider";
// import Settings from "@mui/icons-material/Settings";

import Button from "../../../components/Button/Button";
import styles from "@/assets/jss/ibuild-admin-react/components/headerLinksStyle";

import { page_admin_signin } from "@/utils/constants";

import { logout } from "@/service/authApi";

const useStyles = makeStyles(styles);

export default function NavbarProfileLinks() {
    const classes = useStyles();
    const [openProfile, setOpenProfile] = useState(null);
    const history = useHistory();

    const handleClickProfile = (event) => {
        if (openProfile && openProfile.contains(event.target)) {
            setOpenProfile(null);
        } else {
            setOpenProfile(event.currentTarget);
        }
    };
    const handleCloseProfile = () => {
        setOpenProfile(null);
    };

    const handleSignout = async () => {
        setOpenProfile(null);

        await logout();

        history.push(page_admin_signin);
    };
    return (
        <div>
            <div className={classes.manager}>
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "secondary"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-owns={openProfile ? "profile-menu-list-grow" : null}
                    aria-haspopup="true"
                    onClick={handleClickProfile}
                    className={classes.buttonLink}
                >
                    <Person className={classes.icons} />

                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={Boolean(openProfile)}
                    anchorEl={openProfile}
                    transition
                    disablePortal
                    className={`${classNames({ [classes.popperClose]: !openProfile })} ${
                        classes.popperNav
                    }`}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="profile-menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseProfile}>
                                    <MenuList role="menu">
                                        {/* <Link to={page_admin_settings}>
                                            <MenuItem
                                                onClick={handleCloseProfile}
                                                className={classes.dropdownItem}
                                            >
                                                Settings
                                            </MenuItem>
                                        </Link>
                                        <Divider light /> */}
                                        <MenuItem
                                            onClick={handleSignout}
                                            className={classes.dropdownItem}
                                        >
                                            Logout
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Poppers>
            </div>
        </div>
    );
}

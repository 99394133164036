import { useTranslation } from "react-i18next";
import { useSetState } from "react-use";

import { Collapse } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import { getImageForImageData } from "@/utils/utils";
import { usePlayAudio, useSidebarOpen, useLanguage } from "@/utils/hooks";

import ToggleSwitch from "@/layouts/player/components/ToggleSwitch";
import PlayerRounds from "@/layouts/player/components/PlayerRounds";
import TeamsWithRoles from "@/layouts/player/components/TeamPlayersRounds";
import { useLanguages } from "@/layouts/player/cache/languages";
import { usePlayer } from "@/layouts/player/realtime";

import "./styles.css";

export default function Sidebar() {
    const { t } = useTranslation();

    const [sidebarOpen, toggleSidebarOpen] = useSidebarOpen();

    const [localState, setLocalState] = useSetState({
        openedTeamPlayers: true,
        openedLanguages: false,
    });

    const { data: player } = usePlayer();
    const { data: languages } = useLanguages();

    const { isPlayAudio, setPlayAudio } = usePlayAudio();
    const { language, setLanguage } = useLanguage();

    return (
        <aside className={`sidebar ${sidebarOpen ? "open" : ""}`}>
            <button
                type="button"
                className="sidebar-close-button"
                onClick={() => toggleSidebarOpen()}
            >
                <ArrowBackIcon />
            </button>

            <ul className="sidebar-items">
                {player?.avatar && player?.nickname && (
                    <li>
                        <div className="current-player-data">
                            <div className="image">
                                <img src={player.avatar} className="player-avatar" alt="" />
                            </div>
                            <p className="nickname">{player.nickname}</p>
                        </div>
                    </li>
                )}

                <li>
                    <PlayerRounds />
                </li>

                {player?.teamCode && (
                    <li>
                        <div className="team-code-header">
                            <p className="team-code">{t("teamcode")}</p>
                            <p className="team-code-data">{player.teamCode}</p>
                        </div>
                    </li>
                )}

                <li>
                    <div className="sidebar-player-card">
                        <div className="team-players-header">
                            <p className="team-members">{t("sidebar_team_members")}</p>
                            <p className="team-members-open-close-icon">
                                <button
                                    type="button"
                                    onClick={() =>
                                        setLocalState({
                                            openedTeamPlayers: !localState.openedTeamPlayers,
                                        })
                                    }
                                >
                                    {localState.openedTeamPlayers ? (
                                        <ArrowDropUpIcon />
                                    ) : (
                                        <ArrowDropDown />
                                    )}
                                </button>
                            </p>
                        </div>
                        <Collapse in={localState.openedTeamPlayers}>
                            <TeamsWithRoles />
                        </Collapse>
                    </div>
                </li>

                {languages && (
                    <li>
                        <div className="sidebar-languages-card">
                            <div className="languages-header">
                                <p className="languages">{t("sidebar_language")}</p>
                                <p className="languages-open-close-icon">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            setLocalState({
                                                openedLanguages: !localState.openedLanguages,
                                            })
                                        }
                                    >
                                        {localState.openedLanguages ? (
                                            <ArrowDropUpIcon />
                                        ) : (
                                            <ArrowDropDown />
                                        )}
                                    </button>
                                </p>
                            </div>

                            <Collapse in={localState.openedLanguages}>
                                <ul className="languages-list">
                                    {languages.map((item) => (
                                        <li key={item.id}>
                                            <input
                                                type="radio"
                                                name="rGroupLanguage"
                                                value={item.lang}
                                                id={item.lang}
                                                onChange={(event) =>
                                                    setLanguage(event.target.value)
                                                }
                                                checked={language === item.lang && true}
                                            />
                                            <label className="radio1" htmlFor={item.lang}>
                                                <div id={item.id} className="language-data">
                                                    <p>{item.language}</p>
                                                    <p>
                                                        <img
                                                            src={getImageForImageData(item.flag)}
                                                            alt="flag"
                                                        />
                                                    </p>
                                                </div>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </Collapse>
                        </div>
                    </li>
                )}

                <li>
                    <div className="sidebar-sound-switcher">
                        <div className="sound-switcher-header">
                            <p className="sound-switcher">{t("sidebar_sounds")}</p>
                            <div className="sound-switcher-button">
                                <ToggleSwitch
                                    value={isPlayAudio}
                                    onChange={() => setPlayAudio(!isPlayAudio)}
                                />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div className="version">Version: {import.meta.env.VITE_VERSION}</div>
        </aside>
    );
}

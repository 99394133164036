import axios from "axios";

import { apiUrl } from "@/config";
import { getPlayerToken, getImageToken } from "@/utils/utils";

const httpApi = axios.create({
    baseURL: `${apiUrl}/api`,
});

// add the auth token to each request
httpApi.interceptors.request.use((config) => {
    // get the token from the localStorage
    const token = getImageToken() || getPlayerToken();

    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
});

export default httpApi;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import defaultLanguage from "./utils/language";

const enLang = "EN";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next - https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,

        // initial language to use
        lng: enLang,

        // language to use if translations in user language are not available
        fallbackLng: enLang,

        // pre-loaded resources, e.g. languages
        resources: {
            [enLang]: {
                translation: defaultLanguage,
            },
        },

        react: {
            transSupportBasicHtmlNodes: true,
        },

        interpolation: {
            // not needed for react as it escapes by default
            escapeValue: false,
        },
    });

const i18nOut = {
    /**
     * Change current language
     * @param {string} lang
     */
    changeLanguage(lang) {
        i18n.changeLanguage(lang.toUpperCase());
    },

    /**
     * Add a language bundle
     * @param {string} lang
     * @param {object} dictionary
     */
    addLanguage(lang, dictionary) {
        i18n.addResourceBundle(lang.toUpperCase(), "translation", dictionary);
    },

    /**
     * Check if language is valid, e.g. among the added bundles
     * @param {string} lang
     * @return {boolean}
     */
    isValidLanguage(lang) {
        return i18n.hasResourceBundle(lang.toUpperCase(), "translation");
    },

    /**
     * Return the currently set and used language
     * @return {string}
     */
    get currentLanguage() {
        return i18n.language;
    },

    /**
     * Default english language
     */
    enLanguage: enLang,
};

export default i18nOut;

// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

import styles from "@/assets/jss/ibuild-admin-react/components/buttonStyle.js";

const useStyles = makeStyles(styles);

export default function RegularButton(props) {
    const {
        color,
        round,
        children,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        className,
        muiClasses,
        ...rest
    } = props;

    const classes = useStyles();

    const btnClasses = classNames({
        [classes.button]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [className]: className,
    });
    return (
        <Button {...rest} classes={muiClasses} className={btnClasses}>
            {children}
        </Button>
    );
}

RegularButton.propTypes = {
    type: PropTypes.oneOf(["submit", "button", "reset"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
    ]),
    size: PropTypes.oneOf(["sm", "lg"]),
    startIcon: PropTypes.node,
    onClick: PropTypes.func,
    title: PropTypes.string,
    simple: PropTypes.bool,
    round: PropTypes.bool,
    disabled: PropTypes.bool,
    block: PropTypes.bool,
    link: PropTypes.bool,
    justIcon: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
    // use this to pass the classes props from Material-UI
    muiClasses: PropTypes.object,
    children: PropTypes.node,
};

import { useState } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

import { useGame } from "@/layouts/player/cache/game";
import { useBonusTasks, useBonusTaskDone } from "@/layouts/player/cache/bonusTasks";
import { usePlayer, useOnUploadBonusTaskPhoto } from "@/layouts/player/realtime";

import BonusTaskDrawer from "./BonusTaskDrawer";
import "./styles.css";

/**
 * @typedef {import("../../cache/bonusTasks").BonusTask} BonusTask
 */

export default function BonusTasks() {
    /**
     * @type {{data: import("../../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();
    const { data: bonusTasks, refetch: refetchBonusTasks } = useBonusTasks(player.id);
    const setBonusTaskDone = useBonusTaskDone();
    const [bonusTaskActive, setBonusTaskActive] = useState(
        /** @type {BonusTask|undefined} */ undefined,
    );

    useOnUploadBonusTaskPhoto(game.id, player.id, bonusTaskActive?.id, () => {
        // close the active bonus task
        setBonusTaskActive(undefined);
        // get them again to see the done one
        refetchBonusTasks();
    });

    // if not bonus tasks then don't render anything
    if (!bonusTasks) return null;

    /**
     * @param {string} imageDataUri
     */
    const onBonusTaskAction = (imageDataUri) => {
        setBonusTaskActive(undefined);

        setBonusTaskDone({
            gameId: game.id,
            playerId: player.id,
            nickname: player.nickname,
            bonusTaskId: bonusTaskActive.id,
            imageDataUri,
        });
    };

    return (
        <>
            <ul className="bonus-tasks-list">
                {bonusTasks.map((bonusTask) => (
                    <li
                        key={bonusTask.id}
                        onClick={() => setBonusTaskActive(bonusTask)}
                        className={bonusTask.done ? "green-text" : undefined}
                    >
                        <p className="task-name">{bonusTask.name}</p>
                        {bonusTask.action && <PhotoCameraIcon />}
                    </li>
                ))}
            </ul>

            <BonusTaskDrawer
                bonusTask={bonusTaskActive}
                onAction={onBonusTaskAction}
                onClose={() => setBonusTaskActive(undefined)}
            />
        </>
    );
}

import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// core components
import styles from "@/assets/jss/ibuild-admin-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor } = props;
    return (
        <div className={classes.tableResponsive}>
            <Table className={classes.table}>
                {tableHead !== undefined ? (
                    <TableHead className={classes[`${tableHeaderColor}TableHeader`]}>
                        <TableRow className={classes.tableHeadRow}>
                            {tableHead.map((prop, key) => (
                                <TableCell
                                    className={`${classes.tableCell} ${classes.tableHeadCell}`}
                                    key={key}
                                >
                                    <b> {prop}</b>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : null}
                <TableBody>
                    {tableData.map((prop, key) => (
                        <TableRow key={key} className={classes.tableBodyRow}>
                            {prop.map((prop, key) => (
                                <TableCell className={classes.tableCell} key={key}>
                                    {prop}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray",
};

CustomTable.propTypes = {
    tableHeaderColor: PropTypes.oneOf(["warning", "primary", "danger", "success", "info", "gray"]),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    ),
};

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import adminApi from "@/service/adminApi";
import { dataURLtoFile, getImageForImageData, fixId } from "@/utils/utils";

/**
 * @typedef {object} BonusTask
 * @property {string} id
 * @property {string} name
 * @property {boolean} action
 * @property {string} [description]
 * @property {string} user  (the user ID)
 * @property {string} [image]  (the image ID)
 * @property {string} userFullName   (the user full name)
 * @property {string} [imageData]  (the image base64 data)
 */

/**
 * @typedef {object} BonusTaskData
 * @property {string} name
 * @property {boolean} action
 * @property {string} [description]
 * @property {string} [imageData]  (the image base64 data)
 */

/**
 * @typedef {BonusTaskData & {userId: string}} BonusTaskDataForAdd
 */

/**
 * @typedef {BonusTaskData & {id: string}} BonusTaskDataForEdit
 */

/**
 * Query for the BonusTasks list.
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|BonusTask[]>}
 */
export function useBonusTasks(enabled = true) {
    return useQuery({
        queryKey: ["admin", "BonusTask"],
        queryFn: getBonusTasks,
        enabled,
    });
}

/**
 * Get a BonusTask
 * @param {string} bonusTaskId
 * @param {boolean} enabled
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|BonusTask>}
 */
export function useBonusTask(bonusTaskId, enabled = true) {
    return useQuery({
        queryKey: ["admin", "BonusTask", bonusTaskId],
        queryFn: () => getBonusTask(bonusTaskId),
        enabled: enabled && !!bonusTaskId,
    });
}

/**
 * Delete a BonusTask
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, string, unknown>}
 */
export function useBonusTaskDelete() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: deleteBonusTask,
        onSuccess: (_data, bonusTaskId) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "BonusTask"], (oldBonusTasks) => {
                if (!oldBonusTasks) return oldBonusTasks;

                return oldBonusTasks.filter((bonusTask) => bonusTask.id !== bonusTaskId);
            });
        },
        // both the meta and the "context" (returned from 'onMutate')
        // are available on the global MutationCache callbacks.
        // if The 'onMutate' returned context could be used if accessing the 'variables' object is needed
        // onMutate: (variables) => ({ entity: "BonusTask", action: "delete"}),
        meta: { entity: "BonusTask", action: "delete" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Create/add a BonusTask
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, BonusTaskDataForAdd, unknown>}
 */
export function useBonusTaskAdd() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: addBonusTask,
        onSuccess: () => {
            // on successful mutation invalidate the query cache internally
            queryClient.invalidateQueries({ queryKey: ["admin", "BonusTask"] });
        },
        meta: { entity: "BonusTask", action: "create" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Edit a BonusTask
 * @return {import("@tanstack/react-query").UseMutateAsyncFunction<unknown, unknown, BonusTaskDataForEdit, unknown>}
 */
export function useBonusTaskEdit() {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: editBonusTask,
        onSuccess: (bonusTask) => {
            // on successful mutation update the query cache internally
            queryClient.setQueryData(["admin", "BonusTask"], (oldBonusTasks) => {
                if (!oldBonusTasks) return [bonusTask];

                let found = false;
                const newBonusTasks = oldBonusTasks.map((oldBonusTask) => {
                    if (oldBonusTask.id === bonusTask.id) {
                        found = true;
                        // merge - let the new overwrite the existing
                        return { ...oldBonusTask, ...bonusTask };
                    }
                    return oldBonusTask;
                });
                // just in case a BonusTask that is not present is edited then add it
                if (!found) newBonusTasks.push(bonusTask);

                return newBonusTasks;
            });
        },
        meta: { entity: "BonusTask", action: "update" },
    });

    // if needed can return the whole mutation, like loading, and error state
    return mutation.mutateAsync;
}

/**
 * Get BonusTasks function to be used by useQuery
 * @return {Promise<BonusTask[]>}
 */
const getBonusTasks = async () => {
    const res = [];

    const { data: bonusTasksData } = await adminApi.get(`/bonus_tasks`);

    for (const item of bonusTasksData) {
        const { data: userData } = await adminApi.get(`/users/${item.user}`);
        item.userFullName = `${userData.firstName} ${userData.lastName}`;

        res.push(fixId(item));
    }

    return res;
};

/**
 * Get BonusTask function to be used by useQuery
 * @param {string} bonusTaskId
 * @return {Promise<BonusTask>}
 */
const getBonusTask = async (bonusTaskId) => {
    const { data: bonusTaskData } = await adminApi.get(`/bonus_tasks/${bonusTaskId}`);

    // convert the image as ID to base64-image-data
    if (bonusTaskData.image) {
        const { data: imgData } = await adminApi.get(`/images/${bonusTaskData.image}`);
        bonusTaskData.imageData = getImageForImageData(imgData.img);
    }

    return fixId(bonusTaskData);
};

/**
 * Add/Create a BonusTask function to be used by useQuery
 * @param {BonusTaskDataForAdd} inBonusTaskData
 * @return {Promise<BonusTask>}
 */
const addBonusTask = async (inBonusTaskData) => {
    // update the new image and concert the image base64 data to the new image ID
    let image;
    if (inBonusTaskData.imageData) {
        const file = dataURLtoFile(inBonusTaskData.imageData, "bonus_task_image");
        const formData = new FormData();
        formData.append("file", file);

        const { data: imageData } = await adminApi.post("/images", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        image = imageData._id;
    }

    const { data: bonusTaskData } = await adminApi.post(`/bonus_tasks`, {
        userId: inBonusTaskData.userId,
        name: inBonusTaskData.name,
        description: inBonusTaskData.description,
        action: inBonusTaskData.action,
        image,
    });
    return fixId(bonusTaskData);
};

/**
 * Edit/Update a BonusTask function to be used by useQuery
 * @param {BonusTaskDataForEdit} inBonusTaskData
 * @return {Promise<BonusTask>}
 */
const editBonusTask = async (inBonusTaskData) => {
    // update the new image and concert the image base64 data to the new image ID
    let image;
    if (inBonusTaskData.imageData) {
        const file = dataURLtoFile(inBonusTaskData.imageData, "bonus_task_image");
        const formData = new FormData();
        formData.append("file", file);

        const { data: imageData } = await adminApi.post("/images", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        image = imageData._id;
    }

    // update the bonus task
    const { data: bonusTaskData } = await adminApi.put(`/bonus_tasks/${inBonusTaskData.id}`, {
        name: inBonusTaskData.name,
        description: inBonusTaskData.description,
        action: inBonusTaskData.action,
        image,
    });
    return fixId(bonusTaskData);
};

/**
 * Delete a BonusTask
 * @param {string} bonusTaskId
 */
const deleteBonusTask = async (bonusTaskId) => {
    await adminApi.delete(`/bonus_tasks/${bonusTaskId}`);
};

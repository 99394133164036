export const PlayerRole = /** @type {const} */ ({
    architect: "architect",
    pyro: "pyro",
    builder: "builder",
});

/**
 * Player role set-by options
 */
export const PlayerRoleSetBy = /** @type {const} */ ({
    // player admin selects the role himself
    player: "player",
    // when admin sets the role for a player
    admin: "admin",
    // when all other player have selected their roles, and this player has no choice
    auto: "auto",
});

/**
 * Player's status for each individual round.
 * He starts with Deciding, then goes to Playing and when finished/ready goes to Lobby
 */
export const PlayerStatus = /** @type {const} */ ({
    Waiting: "waiting",
    Playing: "playing", // when player starts a task/round (with selected role)
    Finished: "finished", // set when player finishes a task/round
});

export const rootPathAdmin = "/admin";
export const rootPathPlayer = "/player";

export const page_admin_signin = `${rootPathAdmin}/signin`;
export const page_admin_register = `${rootPathAdmin}/register`;

export const page_admin_dashboard = `${rootPathAdmin}/dashboard`;
// export const page_admin_settings = `${rootPathAdmin}/settings`;
export const page_admin_game_models = `${rootPathAdmin}/game-models`;
export const page_admin_game_models_add = `${page_admin_game_models}/add`;
export const page_admin_game_models_edit = `${page_admin_game_models}/:id/edit`;
export const page_admin_games = `${rootPathAdmin}/games`;
export const page_admin_games_add = `${page_admin_games}/add`;
export const page_admin_games_edit = `${page_admin_games}/:id/edit`;
export const page_admin_languages = `${rootPathAdmin}/languages`;
export const page_admin_languages_add = `${page_admin_languages}/add`;
export const page_admin_languages_edit = `${page_admin_languages}/:id/edit`;
export const page_admin_bonus_tasks = `${rootPathAdmin}/bonus-tasks`;
export const page_admin_bonus_tasks_add = `${page_admin_bonus_tasks}/add`;
export const page_admin_bonus_tasks_edit = `${page_admin_bonus_tasks}/:id/edit`;
export const page_admin_photo_management = `${rootPathAdmin}/photo-management`;
export const page_admin_photo_management_view = `${page_admin_photo_management}/:id/view`;

export const page_player_join = `${rootPathPlayer}/join`;
export const page_player_join_team = `${rootPathPlayer}/join-team`;
export const page_player_choose_role = `${rootPathPlayer}/choose-role`;
export const page_player_architect_start = `${rootPathPlayer}/${PlayerRole.architect}/start`;
export const page_player_architect_task = `${rootPathPlayer}/${PlayerRole.architect}/task`;
export const page_player_pyro_start = `${rootPathPlayer}/${PlayerRole.pyro}/start`;
export const page_player_pyro_task = `${rootPathPlayer}/${PlayerRole.pyro}/task`;
export const page_player_builder_start = `${rootPathPlayer}/${PlayerRole.builder}/start`;
export const page_player_builder_take_photo = `${rootPathPlayer}/${PlayerRole.builder}/take-photo`;
export const page_player_builder_upload_photo = `${rootPathPlayer}/${PlayerRole.builder}/upload-photo`;
export const page_player_task_finished = `${rootPathPlayer}/task-finished`;
export const page_player_check = `${rootPathPlayer}/check`;
export const page_player_congrats = `${rootPathPlayer}/congrats`;

// the external/standalone pages for just taking and uploading an image to some URL
//  ".../player/builder/upload-photo/:data" and ".../player/bonus-task/upload-photo/:data"
export const page_player_ext_builder_upload_photo = `${rootPathPlayer}/ext/${PlayerRole.builder}/upload-photo`;
export const page_player_ext_bonus_task_upload_photo = `${rootPathPlayer}/ext/bonus-task/upload-photo`;

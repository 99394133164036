import { useTranslation } from "react-i18next";

import { page_player_task_finished } from "@/utils/constants";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import Loading from "@/layouts/player/components/Loading";
import { useRoundImages } from "@/layouts/player/cache/roundImages";
import { useNavigate, useRunningGame, usePlayer } from "@/layouts/player/realtime";

export default function Pyro2() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * @type {{data: import("../realtime").RunningGame}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: runningGame } = useRunningGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const { data: roundImages, isLoading } = useRoundImages(player.team, runningGame.roundOrder);

    const submitHandler = (e) => {
        e.preventDefault();
        // go to the next page
        navigate(page_player_task_finished);
    };

    return (
        <>
            <div className="task-content">
                <p className="role-description-text-bold">{t("pyro_task_player_action_1")}</p>
                <div className="img-cont">
                    {isLoading ? <Loading /> : <img src={roundImages?.imgPyroSrc} alt="" />}
                </div>
                <p className="role-description-text-bold">{t("pyro_task_player_action_2")}</p>
                <div className="img-cont">
                    {isLoading ? <Loading /> : <img src={roundImages?.imgPyroFormulaSrc} alt="" />}
                </div>
                <p className="role-description-text">{t("pyro_task_text")}</p>
            </div>

            <form onSubmit={submitHandler}>
                <IgniteButton />
            </form>
        </>
    );
}

import { useToggle, useEffectOnce } from "react-use";
import { useParams } from "react-router-dom";

import { setImageToken } from "@/utils/utils";

import LayoutExt from "@/layouts/player/components/LayoutExt";
import UploadPhoto from "@/layouts/player/components/UploadPhoto";
import PhotoSubmitted from "@/layouts/player/components/PhotoSubmitted";

import { useBonusTaskDone } from "@/layouts/player/cache/bonusTasks";

export default function BonusTaskUploadPhotoExt() {
    const [submitted, setSubmitted] = useToggle(false);
    let { data } = useParams();
    data = JSON.parse(atob(data));
    const { imageToken, ...uploadPhotoProps } = data;

    // store so the API call to be authorized
    useEffectOnce(() => setImageToken(imageToken));

    const setBonusTaskDone = useBonusTaskDone();

    /**
     * @param {string} imageDataUri
     */
    const onSubmit = async (imageDataUri) => {
        await setBonusTaskDone({ ...uploadPhotoProps, imageDataUri });
        setSubmitted();
    };

    return (
        <LayoutExt
            subtitle={uploadPhotoProps}
            className="bonus-task-action"
            titleKey="submit_photo_title"
        >
            {submitted ? <PhotoSubmitted /> : <UploadPhoto onSubmit={onSubmit} />}
        </LayoutExt>
    );
}

import { useEffect, useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getI18n } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce, useTimeoutFn } from "react-use";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

import { log } from "@/logger";
import { queryClient } from "@/cache";
import { socketClient } from "@/service/realtimeApi";
import { setPlayerToken, getPlayerToken } from "@/utils/utils";
import { showNotification, showNotificationMessage } from "@/notifications";
import { usePlayAudio } from "@/utils/hooks";
import avatars from "@/utils/avatars";
import {
    page_player_join,
    page_player_join_team,
    page_player_choose_role,
    page_player_check,
} from "@/utils/constants";

import { useGame, GameState } from "@/layouts/player/cache/game";
import { createRoutePathForGame } from "@/layouts/player/routes";

/*
 * All these Game control methods apply to when the running Game
 */

/**
 * @typedef {typeof import("../../utils/constants").PlayerRole} PlayerRole
 */
/**
 * @typedef {PlayerRole[keyof PlayerRole]} PlayerRoleValue
 */

/**
 * @typedef {typeof import("../../utils/constants").PlayerStatus} PlayerStatus
 */
/**
 * @typedef {PlayerStatus[keyof PlayerStatus]} PlayerStatusValue
 */

/**
 * @typedef {object} Player
 * @property {string} id     (Player ID)
 * @property {string} nickname
 * @property {string} [avatar]
 * @property {string} [team] (joined Team ID)
 * @property {string} [teamCode] (joined Team code)
 * @property {string} [quote] (Quote ID)
 * @property {PlayerRoleValue} [role] (current role)
 * @property {PlayerStatusValue} [status] (current status)
 */

/**
 * @typedef {object} RunningGame
 * @property {string} game       (Game ID)
 * @property {string} round      (Round ID)
 * @property {number} roundOrder (Round order 1,2,3...)
 * @property {number} roundTime  (remaining time in the Round, could be real round's time when normal play)
 * @property {Player[]} players  (connected players - as in server's DB and memory)
 */

/**
 * Realtime event listener
 * @param {string} ev
 * @param {RunningGame?} [runningGame]
 * @param {any?} [args] in "player:connectPlayer", "player:connectTeam"- args[0] is of type Player,
 *                      in "player:connectPlayer" - args[1] is the auth-player-token (string)
 */
const onRealtimeEvent = (ev, runningGame, ...args) => {
    switch (ev) {
        case "player:validatePlayer":
        case "player:statusPlayer":
        case "player:connectPlayer":
        case "player:connectTeam":
            const [player, token] = args;
            log(`Received Player: ${JSON.stringify(player)}`);
            // update just the Player
            if (player) queryClient.setQueryData(["player", "Player"], player);
            else {
                queryClient.removeQueries({ queryKey: ["player", "Player"] });
                // clear old/invalid auth token
                setPlayerToken(undefined);
            }

            //  could come on "player:connectPlayer"
            if (token) {
                // store the auth token here,
                // it can be undefined in case of when validation from
                setPlayerToken(token);
            }
            return;

        case "game:changeRound":
            // reset player's role for current round
            queryClient.setQueryData(
                ["player", "Player"],
                (/** @type {Player|undefined}*/ player) => {
                    if (!player) return;
                    return { ...player, role: undefined };
                },
            );
            break;

        case "game:changePlayerRole":
            // set payer's role for current round from the 'runningGame'
            queryClient.setQueryData(
                ["player", "Player"],
                (/** @type {Player|undefined}*/ player) => {
                    if (!player) return;
                    const role = runningGame?.players.find(
                        (aPlayer) => aPlayer.id === player.id,
                    )?.role;
                    if (role) return { ...player, role };
                },
            );
            break;

        case "game:startGame":
        case "game:endGame":
            // refetch the Game
            queryClient.refetchQueries({
                queryKey: ["player", "Game"],
                type: "active",
            });
            break;
        default:
        // nothing
    }

    if (runningGame) {
        log(`+++ Received RunningGame: ${JSON.stringify(runningGame)}`);
        // patch here the player.avatar if only player.avatarOrder is passed
        runningGame.players.forEach((player) => {
            if (!player.avatar && player.avatarOrder !== undefined)
                player.avatar = avatars[player.avatarOrder];
        });
        // update the RunningGame
        queryClient.setQueryData(["player", "RunningGame"], runningGame);
    } else {
        log(`--- Received RunningGame: null`);
        // if runningGame is undefined then remove the cached data
        queryClient.removeQueries({ queryKey: ["player", "RunningGame"] });
    }
};

/**
 * Realtime error listener
 * @param {string} ev
 * @param {string} errorCode
 */
const onRealtimeError = (ev, errorCode) => {
    let error;
    switch (ev) {
        case "player:connectPlayer":
            //     break;
            // case "player:connectTeam":
            // error = <Trans i18nKey={`popup_message_sorry_${errorCode}`} />;
            error = getI18n().t(`popup_message_sorry_${errorCode}`);
            break;
        default:
            error = `Failed event ${ev} - Error: ${errorCode}`;
    }

    // show error notification
    if (error)
        showNotificationMessage(error, "Error", {
            icon: <SentimentDissatisfiedIcon />,
            autoClose: 1000,
        });
};

/**
 * The realtime events for which will listen in a running game - listen for them on subscribeGame()
 * @type {readonly string[]}
 */
const realtimeEvents = [
    "game:statusGame",
    "game:startGame",
    "game:endGame",
    "game:changePlayerRole",
    "game:changeRound",
    "game:changeRoundTime",
    "player:statusPlayer",
    "player:connectPlayer",
    "player:connectTeam",
];

/**
 * Query for a RunningGame data.
 * It should be valid only when there's valid Game that is running, and will be
 * set/updated on some of the realtime events.
 * There's no need to "request" it explicitly.
 * @param {boolean} [refetchOnWindowFocus]
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|RunningGame>}
 */
export function useRunningGame(refetchOnWindowFocus = false) {
    const getStatus = (always = false) => {
        if (always || refetchOnWindowFocus) {
            const runningGame = queryClient.getQueryData(["player", "RunningGame"]);
            if (runningGame) {
                socketClient.emit("player:statusGame", runningGame.game);
                log("Request latest status for the RunningGame");
            }
        }
        // always return false, so that the 'queryFn' to NOT be re-executed
        return false;
    };
    const result = useQuery({
        queryKey: ["player", "RunningGame"],
        queryFn: () => null,
        staleTime: Infinity,
        refetchOnReconnect: () => getStatus(),
        refetchOnWindowFocus: () => getStatus(),
    });

    return {
        ...result,
        refetch() {
            getStatus(true);
        },
    };
}

/**
 * Hook for the current player.
 * It will be set when player joins a Game (and also when subscribe if there's player's token).
 * There's no need to "request" it explicitly.
 * @return {import("@tanstack/react-query").UseBaseQueryResult<undefined|Player>}
 */
export function usePlayer() {
    return useQuery({
        queryKey: ["player", "Player"],
        queryFn: () => null, // must return null , not undefined
        staleTime: Infinity, // must be with forever staleTime, otherwise it will be refetched,
        // when a new subscription comes (e.g. new usage of usePlayer() and then it will be set to null)
    });
}

/**
 * Subscribe (start listening) to the realtime game events
 * @param {string} gameId
 * @return {() => void} a disconnect/cleanup function (like meant to be used in useEffect() hook)
 */
export function subscribeGame(gameId) {
    log("Subscribe to game");

    // for normal events
    const listeners = realtimeEvents.map((event) => {
        const listener = onRealtimeEvent.bind(null, event);
        socketClient.on(event, listener);
        return listener;
    });

    // for error event
    socketClient.on("error", onRealtimeError);

    // "player:connectGame" should provoke returning the current game's status (e.g."game:statusGame")
    socketClient.emit("player:connectGame", gameId);

    return () => {
        // player doesn't need to send something like "player:disconnectGame" as he connect only to one
        // the server will "disconnect" him on socket disconnection level
        realtimeEvents.forEach((event, index) => socketClient.off(event, listeners[index]));
        socketClient.off("error", onRealtimeError);
    };
}

/**
 * Create new player in a game
 * @param {string} gameId
 * @param {string} nickname
 */
export function joinPlayer(gameId, nickname) {
    socketClient.emit("player:connectPlayer", gameId, nickname.trim());
}

/**
 * Join player in a team
 * @param {string} gameId
 * @param {string} playerId
 * @param {string} teamCode
 */
export function joinTeam(gameId, playerId, teamCode) {
    socketClient.emit("player:connectTeam", gameId, playerId, teamCode.trim());
}

/**
 * Change the player's status in running/current round in a running game.
 * @param {string} gameId the running game ID
 * @param {string} roundId the current running round ID in the running game
 * @param {string} playerId the player ID whose role to change
 * @param {string} status the new player's status (Constants.PlayerStatus.waiting, Constants.PlayerStatus.playing, Constants.PlayerStatus.finished)
 */
export function changePlayerStatus(gameId, roundId, playerId, status) {
    socketClient.emit("player:changePlayerStatus", gameId, roundId, playerId, status);
}

/**
 * Change the player's role in running/current round in a running game.
 * @param {string} gameId the running game ID
 * @param {string} roundId the current running round ID in the running game
 * @param {string} playerId the player ID whose role to change
 * @param {string} role the new role (Constants.Role.architect, Constants.Role.pyro, Constants.Role.builder)
 */
export function changePlayerRole(gameId, roundId, playerId, role) {
    socketClient.emit("player:changePlayerRole", gameId, roundId, playerId, role);
}

/**
 * Hook for listening to upload photo for a bonus-task
 * @param {string} gameId the running game ID
 * @param {string} playerId the player ID whose role to change
 * @param {string} bonusTaskId the bonus-task ID
 * @param {() => void} listener
 */
export function useOnUploadBonusTaskPhoto(gameId, playerId, bonusTaskId, listener) {
    // Subscribe to the "player:uploadBonusTaskPhoto"
    useEffect(() => {
        /**
         * @param {string} aPlayerId
         * @param {string} aBonusTaskId
         */
        const wrappedListener = (aPlayerId, aBonusTaskId) => {
            if (playerId === aPlayerId && bonusTaskId === aBonusTaskId) {
                showNotification({ entity: "Photo", action: "upload" });
                listener();
            }
        };
        socketClient.on("player:uploadBonusTaskPhoto", wrappedListener);
        return () => void socketClient.off("player:uploadBonusTaskPhoto", wrappedListener);
    }, [gameId, playerId, bonusTaskId, listener]);
}

/**
 * Hook for listening to upload photo for a finished builder task
 * @param {string} gameId the running game ID
 * @param {string} teamId the player's team ID
 * @param {string} playerId the player ID
 * @param {number} roundOrder the current running round order in the running game`
 * @param {() => void} listener
 */
export function useOnUploadTeamPhoto(gameId, teamId, playerId, roundOrder, listener) {
    // Subscribe to the "player:uploadTeamPhoto"
    useEffect(() => {
        /**
         * @param {string} aPlayerId
         * @param {string} aTeamId
         * @param {number} aRoundOrder
         */
        const wrappedListener = (aTeamId, aPlayerId, aRoundOrder) => {
            if (teamId === aTeamId && playerId === aPlayerId && roundOrder === aRoundOrder) {
                showNotification({ entity: "Photo", action: "upload" });
                listener();
            }
        };
        socketClient.on("player:uploadTeamPhoto", wrappedListener);
        return () => void socketClient.off("player:uploadTeamPhoto", wrappedListener);
    }, [gameId, teamId, playerId, roundOrder, listener]);
}

/**
 * Hook for easier navigation between pages (as they are game related)
 * @return {(page: string) => void} the navigating method (it's "stable" memoized function)
 */
export function useNavigate() {
    const { playAudio } = usePlayAudio();
    const history = useHistory();

    /**
     * @type {{data: import("./cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {(page:string) => void}
     */
    const navigate = useCallback(
        (page) => {
            playAudio();
            page = createRoutePathForGame(page, game.id);
            log(`--> Navigate to ${page}`);
            history.push(page);
        },
        [game.id, history, playAudio], // these are "stable" references, but anyway to make eslint happy
    );

    return navigate;
}

/**
 * Hook for validating the current stored player's token.
 * It returns the validating state , which is true initially,
 * and becomes false after validation finishes (no matter if token is valid or not).
 * It validates it by checking if the there's "stored" player in server for this token (the playerID is in it).
 * If player is valid, it's Player instance is re-stored (the goal is he to continue from where he's left).
 * If player is invalid then the token is removed - this will allow create a new player in client.
 * @return {boolean}
 */
export function usePlayerValidating() {
    const playerToken = getPlayerToken();
    const [isValidating, setValidating] = useState(!!playerToken);

    /**
     * @type {{data: import("./cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    const [_, cancelTimeout] = useTimeoutFn(() => {
        setValidating(false);
    }, 5000);

    useEffectOnce(() => {
        if (!playerToken) {
            return;
        }
        const eventValidate = "player:validatePlayer";
        // send "player:validatePlayer" and listen to a "response"
        const listener = (...args) => {
            onRealtimeEvent(eventValidate, ...args);
            setValidating(false);
            cancelTimeout();
        };
        socketClient.on(eventValidate, listener);
        socketClient.emit(eventValidate, game.id, playerToken);
        return () => void socketClient.off(eventValidate, listener);
    });

    return isValidating;
}

/**
 * @type {string|undefined}
 */
let playerRound;

/**
 * Routing/navigation through the game.
 * NOTE: not perfect, but does its purpose for now. What is happening
 *    1. The routing/navigation of a normal game flow is encapsulated here.
 *    2. This also works when F5-refreshing a middle page - user will be navigated on the start/join page,
 *       and if he's in the middle of a game when the running round comes it will show always the choose-role page,
 *       which is safe enough.
 * NOTE: there's no check/validation/protection if user is using the back-button while playing the game.
 * It's no damage he could do, just probably mess-up his own game. Can be tested.
 * There's no super solution.
 *    1. Can store user's state in local-storage and on such navigation it to be checked
 *    2. Even could not use browser-history routing at all, and all to be one constant page URL.
 *
 *@return {boolean}
 */
export function useGameRouting() {
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * @type {{data: import("./cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    const {
        data: runningGame,
        isLoading: isRunningGameLoading,
        refetch: refetchRunningGame,
    } = useRunningGame(true);

    const { data: player, isLoading: isPlayerLoading } = usePlayer();

    // on each change of the screen page request/refetch the status of the running game,
    // in order to get correct RunningGame status
    // this is for the NOTE in player/index.jsx
    useEffect(() => void refetchRunningGame(), [location.pathname]);

    useEffect(() => {
        log(
            `??? GameStatus effect - runningGameLoading= ${isRunningGameLoading}, playerLoading=${isPlayerLoading}`,
        );
    }, [isRunningGameLoading, isPlayerLoading]);

    // react to round changes and game end - show proper page
    useEffect(() => {
        log(
            `--- GameStatus effect - gameState=${game.state}, runningGameRound=${
                runningGame?.roundOrder
            } (${runningGame?.round}), player=${!!player}, playerTeam=${
                player?.team
            }, playerRound=${playerRound}`,
        );

        let page;
        if (!runningGame?.round || !player) page = page_player_join;
        else if (!player.team) page = page_player_join_team;
        else {
            if (!playerRound) {
                // if game is ended but a middle route is refreshed then show the "start" page
                // it will show game's status
                if (game.state === GameState.Ended) page = page_player_join;
                else {
                    // this when the player is still not in any round (e.g. real play)
                    playerRound = runningGame.round;
                    page = page_player_choose_role;
                }
            } else if (playerRound !== runningGame.round || game.state === GameState.Ended) {
                // if round changes or game ends then show the "check" page
                playerRound = runningGame.round;
                page = page_player_check;
            }
        }

        // if no change needed then do nothing
        if (!page) {
            log("--- GameStatus effect - skip navigate");
            return;
        }

        log("--- GameStatus effect - navigate to", page);
        navigate(page);
    }, [!player, player?.team, game.state, runningGame?.round]);

    return isPlayerLoading || isRunningGameLoading;
}

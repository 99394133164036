import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { apiUrl } from "@/config";
import { fixId, setAdminAuthUser, getAdminAuthUser } from "@/utils/utils";
import { queryClient } from "@/cache";

/**
 * @typedef {object} AuthUser
 * @property {string} id
 * @property {string} username
 * @property {string} token
 */

const httpApi = axios.create({
    baseURL: `${apiUrl}/api/users`,
});

/**
 * Register
 * @param {{
 * firstName: string,
 * lastName : string,
 * email : string,
 * username : string,
 * password : string,
 *}} registerData
 */
export async function register(registerData) {
    try {
        const { data } = await httpApi.post(`/register`, registerData);
        login(data);
    } catch (error) {
        throw new Error(error?.response?.data?.message || "Failed to register");
    }
}

/**
 * Login
 * @param {{username: string, password: string}} signinData
 */
export async function signin(signinData) {
    try {
        const { data } = await httpApi.post(`/signin`, signinData);
        login(data);
    } catch (error) {
        throw new Error(error?.response?.data?.message || "Failed to sing-in");
    }
}

/**
 * Logout
 */
export async function logout() {
    // remove token from localStorage
    setAdminAuthUser(undefined);
    // remove from the cache/store
    queryClient.setQueryData(["admin", "AuthUser"], null);
}

/**
 * Login
 * @param {AuthUser} userData
 */
function login(userData) {
    const authUser = fixId(userData);
    // save as token to localStorage
    setAdminAuthUser(authUser);
    // set in the cache/store
    queryClient.setQueryData(["admin", "AuthUser"], authUser);
}

/* Export a simple React.Context based auth store */

/**
 * Custom hook exporting the currently authorized/signed-in user ID (as in the store/cache)
 * Here the react-query cache is used as simple store.
 * Benefits - it's reactive, changes it from anywhere will affect the hook clients
 * (no need for Redux or Zustand (stores that can be used in hooks and outside))
 * @return {string|undefined}
 */
export function useAuthUserId() {
    /**
     * @type {{data: AuthUser | undefined}}
     */
    const { data: user } = useQuery({
        queryKey: ["admin", "AuthUser"],
        // the queryFn should not return undefined but null in cases when "no data"
        queryFn: () => null,
        staleTime: Infinity,
        gcTime: Infinity,
        initialData: getAdminAuthUser() || null,
    });
    return user?.id;
}
// set the initial data here
queryClient.setQueryData(["admin", "AuthUser"], getAdminAuthUser() || null);

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { usePlayAudio } from "@/utils/hooks";

import IgniteButton from "@/layouts/player/components/Button/IgniteButton";
import { useGame } from "@/layouts/player/cache/game";
import { joinTeam, usePlayer } from "@/layouts/player/realtime";

export default function JoinTeam() {
    const { t } = useTranslation();
    const [teamCode, setTeamCode] = useState("");
    const { playAudio } = usePlayAudio();

    /**
     * @type {{data: import("../cache/game").Game}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: game } = useGame();

    /**
     * @type {{data: import("../realtime").Player}}
     */
    // @ts-ignore - it MUST be valid already
    const { data: player } = usePlayer();

    const submitHandler = async (e) => {
        e.preventDefault();
        playAudio();
        joinTeam(game.id, player.id, teamCode);
    };

    return (
        <div className="task-content">
            <form onSubmit={submitHandler}>
                <input
                    autoFocus
                    type="text"
                    placeholder={t("enter_teamcode") || undefined}
                    value={teamCode.toUpperCase()}
                    onChange={(e) => setTeamCode(e.target.value.toUpperCase())}
                    className="input-field"
                />
                <IgniteButton disabled={!teamCode} />
            </form>
        </div>
    );
}
